import { differenceInDays } from "date-fns";
import { IProposalList } from "../../../shared/services/api/proposal/ProposalService";

/**
 * Verifica se a proposta pode abrir um sinistro.
 * @param {IProposalList | null} rows - Dados da proposta
 * @param {number} permissionLevel - Nível de permissão do usuário
 * @param {boolean} isSuperAdmin - Se o usuário é super admin
 * @returns {boolean} - `true` se pode abrir sinistro, `false` caso contrário
 */

const DEBUG_MODE = true; // 🔹 Mude para `true` para ativar os logs de depuração

export const canSinisterRule = (
    rows: IProposalList | null,
    permissionLevel: number,
    isSuperAdmin: boolean
): boolean => {
    if (!rows) {
        if (DEBUG_MODE) console.log("❌ [Bloqueado] Nenhuma proposta disponível.");
        return false;
    }

    const status = rows.status;

    // ✅ Lista de status que permitem abrir sinistro
    const ALLOWED_STATUSES = ["active", "suspended", "expired", "canceled"];
    const isAllowedStatus = ALLOWED_STATUSES.includes(status);

    // ✅ Verifica se há sinistro ativo
    const hasActiveSinister = rows.sinister?.some((sinister) =>
        [
            "open",
            "in_progress",
            "debts_confession",
            "monthly_compensation",
            "lmi_exhaustion",
            "pending",
            "according",
        ].includes(sinister.status)
    );

    // ✅ Verifica se foi cancelada há menos de 60 dias
    const isCanceledRecently =
        status === "canceled" &&
        !!rows.canceledConclude &&
        differenceInDays(new Date(), new Date(rows.canceledConclude)) <= 60;

    // ✅ Verifica se a proposta suspensa tem menos de 60 dias (usando `canceledOpen`)
    const isSuspendedRecently =
        status === "suspended" &&
        !!rows.canceledOpen &&
        differenceInDays(new Date(), new Date(rows.canceledOpen)) <= 60;

    // ✅ Verifica se a proposta expirada tem menos de 30 dias
    const isExpiredRecently =
        status === "expired" && !!rows.expiresAt && differenceInDays(new Date(), new Date(rows.expiresAt)) <= 30;

    // ✅ Debug melhorado com "Não se Aplica" quando a regra não faz sentido para o status
    if (DEBUG_MODE) {
        console.log("🔎 [DEBUG] Regras de Sinistro:");
        console.log(`➡️ Status da Proposta: ${status} ${isAllowedStatus ? "✅ - Permitido" : "❌ - Bloqueio"}`);
        console.log(`➡️ Tem Sinistro Ativo? ${hasActiveSinister ? "❌ Sim - Bloqueio" : "✅ Não - Permitido"}`);
        console.log(
            `➡️ Foi Cancelada há menos de 60 dias? ${
                status === "canceled"
                    ? isCanceledRecently
                        ? "✅ Sim - Permitido"
                        : "❌ Não - Bloqueio"
                    : "🔹 Não se aplica"
            }`
        );
        console.log(
            `➡️ Suspensa há menos de 60 dias? ${
                status === "suspended"
                    ? isSuspendedRecently
                        ? "✅ Sim - Permitido"
                        : "❌ Não - Bloqueio"
                    : "🔹 Não se aplica"
            }`
        );
        console.log(
            `➡️ Expirada há menos de 30 dias? ${
                status === "expired"
                    ? isExpiredRecently
                        ? "✅ Sim - Permitido"
                        : "❌ Não - Bloqueio"
                    : "🔹 Não se aplica"
            }`
        );
    }

    // ✅ Aplicação das regras
    if (hasActiveSinister) {
        if (DEBUG_MODE) console.log("❌ [Bloqueado] Sinistro ativo detectado, não é possível abrir novo sinistro.");
        return false;
    }

    if (isAllowedStatus && (status === "active" || isSuspendedRecently || isExpiredRecently || isCanceledRecently)) {
        if (DEBUG_MODE) console.log("✅ [Liberado] Status permitido, pode abrir sinistro.");
        return true;
    }

    return false;
};
