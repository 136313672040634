import { Box, Button, CardContent, Grid, Icon, InputAdornment, MenuItem, Modal, Typography } from "@mui/material";
import { IVFormErros, useVForm, VForm, VTextField } from "../../../shared/forms";
import { VMaskTextField } from "../../../shared/forms/VMaskTextField";
import { useToast } from "../../../shared/hooks";
import * as yup from "yup";
import { globalRemoveMaskReal2 } from "../../../shared/utils";
import { useState } from "react";
import { ComissionServices } from "../../../shared/services/api/comission/ComissionServices";
import { ToastContent } from "../../../shared/components";
import { IInvoiceData } from "./types";

interface ModalRegsiterFileProps {
	visible: boolean;
	onClose: () => void;
	rowsInstallment: IInvoiceData;
	onSuccess: () => void;
}

const style = {
	position: "relative",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "40%", // Reduzido de 50% para 40%
	height: "auto",
	maxWidth: "75%",
	maxHeight: "85%",
	bgcolor: "background.paper",
	border: "1px solid #605e5e",
	boxShadow: 24,
	borderRadius: "6px",
	display: "flex",
	flexDirection: "column",
	backgroundColor: "#FFF",
	padding: 3,
};

interface IPlanValues {
	type: string;
	description: string;
	data: string;
	value: number;
	totAmount: number;
	totValue: number;
	totCount: number;
	planId: string;
}

export const ModalRegister: React.FC<ModalRegsiterFileProps> = ({ visible, onClose, rowsInstallment, onSuccess }) => {
	const { formRef } = useVForm();
	const toastHandler = useToast();
	const [isLoading, setIsLoading] = useState(false);

	const formValidationSchema: yup.SchemaOf<IPlanValues> = yup.object().shape({
		type: yup.string().required("Campo Obrigatório"),
		description: yup.string().required("Campo Obrigatório"),
		data: yup.string().required("Campo Obrigatório"),
		value: yup.number().required("Campo Obrigatório"),
		totAmount: yup.number().required("Campo Obrigatório"),
		totValue: yup.number().required("Campo Obrigatório").moreThan(0, "O valor deve ser maior que zero"),
		totCount: yup.number().required("Campo Obrigatório"),
		planId: yup.string().required("Campo Obrigatório"),
	});

	const handleSave = (row: IPlanValues) => {
		const cleanedData = {
			...row,
			comissionId: rowsInstallment.id,
			totValue: Number(globalRemoveMaskReal2(row.totValue.toString())),
			data: new Date().toISOString(),
			totAmount: 0.0,
			planId: row.type,
			totCount: 1,
			value: 0,
		};
		formValidationSchema
			.validate(cleanedData, { abortEarly: false })
			.then((validatedData) => {
				setIsLoading(true);
				ComissionServices.addValues(validatedData).then((result) => {
					setIsLoading(false);
					if (result instanceof Error) {
						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={"Erro"} content={result.message} />,
						});
					} else {
						toastHandler.present({
							type: "success",
							messageOrContent: "Lançamento efetuado com sucesso!",
							position: "top-right",
						});
						onSuccess();
						onClose();
					}
				});
			})
			.catch((errors: yup.ValidationError) => {
				const validationErros: IVFormErros = {};
				errors.inner.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				console.log("Erros de validação:", validationErros);
				formRef.current?.setErrors(validationErros);
			});
	};

	return (
		<>
			<Modal open={visible} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Box sx={style}>
					<Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"}>
						<Typography variant="h5" paddingY={2} textAlign={"center"} color={"primary"}>
							Lançamento de valores
						</Typography>
						<Typography variant="body2" textAlign={"center"}>
							Fatura / Ciclo : {rowsInstallment.reference}
						</Typography>
					</Box>
					<CardContent>
						<VForm id="formIdSave" ref={formRef} onSubmit={handleSave} onPointerEnterCapture={""} onPointerLeaveCapture={""} placeholder="">
							<Grid container direction="column" padding={1} spacing={2}>
								<Grid container item direction="row" spacing={2}>
									<Grid item xs={12}>
										<VTextField fullWidth label="Descrição" name="description" />
									</Grid>
								</Grid>

								<Grid container item direction="row" spacing={2}>
									<Grid item xs={4}>
										<VTextField fullWidth label="Tipo de lançamento" name="type" select>
											<MenuItem value="discount">Estorno</MenuItem>
											<MenuItem value="bonus">Bonificação</MenuItem>
											<MenuItem value="prize">Premiação</MenuItem>
										</VTextField>
									</Grid>

									<Grid item xs={4}>
										<VMaskTextField
											fullWidth
											label="Valor"
											name="totValue"
											mask="real"
											InputProps={{
												startAdornment: <InputAdornment position="start">R$</InputAdornment>,
											}}
										/>
									</Grid>
								</Grid>
							</Grid>

							<Grid container justifyContent="center" padding={2} gap={2}>
								<Button color="inherit" disableElevation variant={"contained"} startIcon={<Icon>arrow_back_Ico</Icon>} onClick={onClose}>
									Voltar
								</Button>
								<Button color="primary" variant="contained" startIcon={<Icon>checkIcon</Icon>} onClick={() => formRef.current?.submitForm()}>
									Confirmar
								</Button>
							</Grid>
						</VForm>
					</CardContent>
				</Box>
			</Modal>
		</>
	);
};
