import { AutoModeOutlined, Send } from "@mui/icons-material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
    Box,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { useAuthContext } from "../../../../../context";
import ModalUpdateStatusProposal from "../Modals/ModalUpdateStatusProposal";
import { useProfile } from "../../../../../context/ProfileContext";
import { FinancialService } from "../../../../../shared/services/api/financial/FinancialService";
import { ToastContent } from "../../../../../shared/components";
import { useToast } from "../../../../../shared/hooks";

interface ProposalActionsProps {
    rows: any;
    onUpdate: () => void;
}

const ProposalActions: React.FC<ProposalActionsProps> = ({
    rows,
    onUpdate,
}) => {
    const { loggedUser } = useAuthContext();
    const { isSuperAdmin } = useProfile();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUpdateStatus, setIsUpdateStatus] = useState(false);
    const toastHandler = useToast();

    // Abre o menu
    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    // Fecha o menu
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    // Fecha o modal e notifica o componente pai para atualizar os dados
    const handleCloseModalUpdateStatus = () => {
        setIsModalOpen(false);
        onUpdate(); // ✅ Chama a função do componente pai para atualização
    };

    // Abre o modal e fecha o menu antes
    const handleOpenModal = () => {
        handleMenuClose();
        setTimeout(() => {
            setIsModalOpen(true);
        }, 100);
    };

    const handleResendInstallment = (id: string, email: string) => {
        FinancialService.sendMailSetup(id, { email }).then((result) => {
            if (result instanceof Error) {
                toastHandler.present({
                    type: "error",
                    position: "top-right",
                    messageOrContent: (
                        <ToastContent
                            title={"Erro"}
                            content={"Erro ao enviar email"}
                        />
                    ),
                });
            } else {
                toastHandler.present({
                    type: "success",
                    position: "top-right",
                    messageOrContent: (
                        <ToastContent
                            title={"Sucesso"}
                            content={"Email enviado com sucesso !!!"}
                        />
                    ),
                });
            }
        });
    };

    return (
        <>
            <Box>
                <IconButton
                    onClick={handleMenuOpen}
                    size="small"
                    aria-controls={anchorEl ? "proposal-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={!!anchorEl}
                >
                    <MoreVertIcon />
                </IconButton>
            </Box>

            <Menu
                anchorEl={anchorEl}
                id="proposal-menu"
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                {loggedUser?.role === "admin" && isSuperAdmin && (
                    <MenuItem onClick={handleOpenModal}>
                        <ListItemIcon>
                            <AutoModeOutlined fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Alterar Status</ListItemText>
                    </MenuItem>
                )}
                {loggedUser?.role === "admin" && (
                    <MenuItem onClick={() => handleResendInstallment(rows.id, rows.email)}>
                        <ListItemIcon>
                            <Send fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Reenviar Link de Biometria</ListItemText>
                    </MenuItem>
                )}
            </Menu>

            {isModalOpen && (
                <ModalUpdateStatusProposal
                    rows={rows}
                    isClose={handleCloseModalUpdateStatus}
                    isOpenModal={isModalOpen}
                    isUpdate={isUpdateStatus}
                />
            )}
        </>
    );
};

export default ProposalActions;
