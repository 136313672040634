import { Autocomplete, Box, CircularProgress, TextField, Typography } from "@mui/material";
import { useField } from "@unform/core";
import { useEffect, useMemo, useState, useRef } from "react";
import { PlanServices } from "../../../../shared/services/api/plan/PlanServices";
import { useDebounce } from "../../../../shared/hooks";

type TAutoCompletePlanOption = {
	id: string;
	description: string;
	label: string;
	lmi?: string;
	residentialFee?: string;
	commercialFee?: string;
	personalized?: string;
	bonusAmount?: string;
	coverage?: string;
	setupTransfer?: string;
	proposalMin?: string;
	flagAnalysis?: string;
	flagRenovation?: string;
	flagRateRent?: string;
	flagRateCharge?: string;
	comissionType?: string;
	comissionValue?: string;
	comissionProposalMin?: string;
	coverageAcessories?: string;
};

interface IAutoCompletePlanProps {
	isExternalLoading?: boolean;
	labelSelect?: string;
	namecampo: string;
	clientId?: string;
	onClick: (value: any) => void;
	defaultValue?: string; // Adicionando suporte ao valor inicial
}

export const AutoCompletePlan: React.FC<IAutoCompletePlanProps> = ({
	isExternalLoading = false,
	labelSelect = "",
	clientId,
	onClick,
	defaultValue, // Agora podemos receber um valor inicial
}) => {
	const { fieldName, registerField, error, clearError } = useField("planId");
	const { debounce } = useDebounce();
	const selectedIdRef = useRef<string | undefined>(defaultValue);
	const [selectedId, setSelectedId] = useState<string | undefined>(defaultValue ?? "");
	const [opcoes, setOpcoes] = useState<TAutoCompletePlanOption[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [busca, setBusca] = useState("");

	// Registra o campo no unform
	useEffect(() => {
		registerField({
			name: fieldName,
			getValue: () => selectedIdRef.current,
			setValue: (_, newSelectedId) => {
				selectedIdRef.current = newSelectedId;
				setSelectedId(newSelectedId);
			},
		});
	}, [registerField, fieldName]);

	// Atualiza o estado se o defaultValue mudar (por exemplo, ao abrir para edição)
	useEffect(() => {
		if (defaultValue) {
			selectedIdRef.current = defaultValue;
			setSelectedId(defaultValue);
		}
	}, [defaultValue]);

	// Faz a busca de planos
	useEffect(() => {
		setIsLoading(true);
		debounce(() => {
			PlanServices.getAllNotExists(clientId, busca).then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					// Tratar erro, se necessário
				} else {
					setOpcoes(
						result.data.map((plano) => ({
							description: plano.description,
							id: plano.id,
							label: plano.description,
							lmi: plano.lmi,
							residentialFee: plano.residentialFee,
							commercialFee: plano.commercialFee,
							personalized: plano.personalized,
							bonusAmount: plano.bonusAmount,
							coverage: plano.coverage,
							setupTransfer: plano.setupTransfer,
							proposalMin: plano.proposalMin,
							flagAnalysis: plano.flagAnalysis,
							flagRenovation: plano.flagRenovation,
							flagRateRent: plano.flagRateRent,
							flagRateCharge: plano.flagRateCharge,
							comissionType: plano.comissionType,
							comissionValue: plano.comissionValue,
							comissionProposalMin: plano.comissionProposalMin,
							coverageAcessories: plano.coverageAcessories,
						}))
					);
				}
			});
		});
	}, [busca, clientId, debounce]);

	// Localiza a opção selecionada (para o Autocomplete)
	const autoCompleteSelectedOption = useMemo(() => {
		if (!selectedId) return null;
		const selectedOption = opcoes.find((opcao) => opcao.id === selectedId);
		return selectedOption || null;
	}, [selectedId, opcoes]);

	return (
		<Autocomplete
			openText="Abrir"
			closeText="Fechar"
			noOptionsText="Nenhuma opção"
			loadingText="Carregando..."
			disablePortal
			loading={isLoading}
			disabled={isExternalLoading}
			options={opcoes}
			value={autoCompleteSelectedOption}
			getOptionLabel={(option) => option.label}
			onInputChange={(_, newValue) => setBusca(newValue ?? "")}
			onChange={(_, newValue) => {
				const newId = newValue?.id;
				selectedIdRef.current = newId;
				setSelectedId(newId);
				setBusca("");
				clearError();
				onClick(newValue);
			}}
			popupIcon={isLoading && !isExternalLoading ? <CircularProgress size={28} /> : undefined}
			renderOption={(props, option: TAutoCompletePlanOption) => (
				<li {...props}>
					<Box display="flex" flexDirection="column" width="100%">
						<Typography variant="body1" fontWeight={"500"}>
							{option.label}
						</Typography>

						<Box display="flex" flexDirection="row" justifyContent={"space-between"} width="100%" borderBottom={1} pb={1} borderColor={"#e0e0e0"}>
							<Typography variant="body2" color="textSecondary">
								LMI:{" "}
								<Typography variant="caption" fontWeight={"bold"}>
									{option.lmi ?? "-"}x
								</Typography>
							</Typography>
							<Typography variant="body2" color="textSecondary">
								Taxa:{" "}
								<Typography variant="caption" fontWeight={"bold"}>
									{option.residentialFee ?? "-"}
								</Typography>
							</Typography>

							<Typography variant="body2" color="textSecondary">
								Personalizada:{" "}
								<Typography variant="caption" fontWeight={"bold"}>
									{option.personalized === "true" ? "Sim" : "Não"}
								</Typography>
							</Typography>
						</Box>
					</Box>
				</li>
			)}
			renderInput={(params) => <TextField {...params} label={labelSelect} error={!!error} helperText={error} size="small" />}
		/>
	);
};
