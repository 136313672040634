import type { ReactNode } from "react";

interface FieldExplanation {
	[key: string]: {
		title: string;
		description: ReactNode;
	};
}

export const fieldExplanations: FieldExplanation = {
	usedFor: {
		title: "Utilizado para",
		description: "Define o propósito do plano: SA (Somente Análises), SR (Somente Renovação), ou AR (Análise e Renovação).",
	},
	chargeType: {
		title: "Modelo de Cobrança",
		description: "Especifica se a cobrança é baseada apenas no aluguel ou inclui encargos adicionais.",
	},
	coverage: {
		title: "Cobertura",
		description: "Indica o múltiplo do valor do aluguel usado para calcular a multa.",
	},
	lmi: {
		title: "LMI (Limite Máximo de Indenização)",
		description: "Representa o número máximo de aluguéis que serão cobertos em caso de sinistro.",
	},
	residentialFee: {
		title: "Taxa Residencial",
		description: "Percentual aplicado para cálculo da taxa em contratos residenciais.",
	},
	commercialFee: {
		title: "Taxa Comercial",
		description: "Percentual aplicado para cálculo da taxa em contratos comerciais.",
	},
	setupTransfer: {
		title: "Repasse da Taxa de Setup",
		description: "Percentual da taxa de setup que será repassado. Não pode exceder 100%.",
	},
	bonusAmount: {
		title: "Premiação",
		description: "Valor  de premiação aplicado ao plano.",
	},
	comissionProposalMin: {
		title: "Quantidade Mínima de Garantias",
		description: "Número mínimo de garantias necessárias para qualificar-se para a premiação.",
	},
	comissionType: {
		title: "Tipo de Bonificação",
		description: "Especifica se a bonificação é calculada como um percentual ou um valor fixo.",
	},
	comissionValue: {
		title: "Valor/Índice da Bonificação",
		description: "Valor ou percentual da bonificação, dependendo do tipo selecionado.",
	},
};
