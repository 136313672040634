import { Box, Button, Icon, Tooltip } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";

interface IToolbarProps {
	textSearchGrid?: string;
	showInputSearch?: boolean;
	onChangeTextSearch?: (newText: string) => void;
	onclearTextSearch?: (textEmpty: string) => void;
	textButtonSearch?: string;
	showButtonSearch?: boolean;
	showButtonNew?: boolean;
	onClickButtonSearch?: () => void;
	onclickButtonRefresh?: () => void;
	onclickNew?: () => void;
}

export const ToolbarReports: React.FC<IToolbarProps> = ({
	textSearchGrid: textSearch = "",
	onChangeTextSearch,
	showInputSearch = true,
	showButtonSearch = false,
	onClickButtonSearch,
	onclickButtonRefresh,
	onclickNew,
	showButtonNew = true,
}) => {
	const [busca, setBusca] = useState("");
	const [inputTextValue, setInputTextValue] = useState("");

	const handleChangeTextbusca = (value: string) => {
		setInputTextValue(value);
	};

	useEffect(() => {
		if (onChangeTextSearch) {
			onChangeTextSearch(busca);
		}
	}, [busca]);

	return (
		<Box gap={1} paddingY={3} display="flex" alignItems="space-beetwen">
			{/* {showInputSearch && (
                <Grid item xs={12} sm={12} md={6} paddingRight={1}>
                    <TextField
                        fullWidth
                        label='Pesquisar'
                        name="pesquisar"
                        size="small"
                        value={busca}
                        onChange={(e) => setBusca(e.target.value)}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" sx={{ gap: 1 }}>
                                    <IconButton aria-label="Limpar" edge="end" onClick={() => setBusca('')}>
                                        <Clear />
                                    </IconButton>
                                    <IconButton aria-label="Pesquisar" edge="end" onClick={onClickButtonSearch} color="primary">
                                        <Search />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
            )} */}

			<Tooltip title="Atualizar">
				<Button onClick={onclickButtonRefresh} variant="contained" sx={{ gap: 1 }}>
					<Icon>refresh_icon</Icon> Atualizar
				</Button>
			</Tooltip>

			{showButtonNew && (
				<Tooltip title="Novo Relatório">
					<Button onClick={onclickNew} variant="outlined" sx={{ gap: 1 }}>
						<Icon>playlist_add_icon</Icon> Novo Relatório
					</Button>
				</Tooltip>
			)}
		</Box>
	);
};
