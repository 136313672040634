import { Grid, LinearProgress, MenuItem, Paper, Table, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FerramentaDetalhe, Loading } from '../../../shared/components';
import { PermissionBox } from '../../../shared/components/permissionBox/PermissionBox';
import { ToolbarDetailPermission } from '../../../shared/components/toolbarDetailPermission/ToolbarDetailPermission';
import { useVForm, VForm, VTextField } from '../../../shared/forms';
import { useMenuPermissions } from '../../../shared/hooks/useMenuPermissions';
import { usePermissions } from '../../../shared/hooks/usePermissions';
import { LayoutBaseDePagina } from '../../../shared/layouts';
import { PermissionServices } from '../../../shared/services/api/permissions/PermissionService';
import { checkByProfileGuard } from '../../../shared/guards/ProfileGuard';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';
import { useAuthContext } from '../../../context';
import { useToast } from '../../../shared/hooks';
import { clientTypeService, IListagemClientType } from '../../../shared/services/api/clientType/clientTypeService';
import { isEmpty } from 'lodash';

export const PermissionsDetailsPresentation: React.FC = () => {
  const { id = 'novo' } = useParams<'id'>();
  const [permission, setPermission] = useState({} as any);
  const navigate = useNavigate();
  const { formRef, save } = useVForm()
  const isEdit = useMemo(() => {
    return id !== 'novo'
  }, [id])
  const {
    dashboard,
    clients,
    analysis,
    charts,
    financial,
    comission,
    generalConditions,
    permissions,
    reports,
    sinister,
    sinisterValues,
    users,
    warranties,
    menu,
    handlePermissionsData
  } = usePermissions();
  const toastHandler = useToast()
  const { handleRefreshToken } = useAuthContext()
  const { handleMenuData } = useMenuPermissions();
  const [clientTypes, setClientTypes] = useState<IListagemClientType[]>([])
  const objectPermission = { clientId: null, level: null };
  const [permissionIdlevel, setPermissionIdlevel] = useState(objectPermission);

  const handleRequestOptions = () => {
    const permissionData = handlePermissionsData();
    const menuPermissionsData = handleMenuData();
    const data = Object.assign(permissionData, menuPermissionsData);
    const dataFormatted = JSON.stringify(data);
    return dataFormatted;
  }

  const submit = async () => {
    if (id === 'novo') {
      const formData = formRef.current?.getData();
      const permissionsOptionsData = handleRequestOptions();
      const data = {
        description: formData?.description,
        level: 0,
        permissions: permissionsOptionsData,
        clientId: 0,
        clientTypeId: formData?.clientTypeId,
      }

      return PermissionServices.create(data);
    } else {
      const formData = formRef.current?.getData();
      const permissionsOptionsData = handleRequestOptions();
      const data = {
        description: formData?.description,
        permissions: permissionsOptionsData,
        clientTypeId: formData?.clientTypeId,
        level: permissionIdlevel?.level,
        clientId: permissionIdlevel?.clientId,
      }
      await PermissionServices.updateById(id, data)
      await handleRefreshToken()
    }
  }

  const handleSubmitAndStay = async () => {
    if (isEdit) {
      try {
        await submit();
        toastHandler.present({ type: 'success', messageOrContent: 'Permissão atualizada com sucesso!', position: 'top-right' })
      } catch (error: any) {
        toastHandler.present({ type: 'error', messageOrContent: `Erro ao atualizar permissão: ${error.message}`, position: 'top-right' })
      }
    } else {
      try {
        await submit()
        toastHandler.present({ type: 'success', messageOrContent: 'Permissão criada com sucesso!', position: 'top-right' })
      } catch (error: any) {
        toastHandler.present({ type: 'error', messageOrContent: `Erro ao criar permissão: ${error.message}`, position: 'top-right' })
      }
    }
  }

  const handleSubmitAndNavigateBack = async () => {
    if (isEdit) {
      try {
        await submit();
        toastHandler.present({ type: 'success', messageOrContent: 'Permissão atualizada com sucesso!', position: 'top-right' })

        navigate('/permissoes');
      } catch (error: any) {
        toastHandler.present({ type: 'error', messageOrContent: `Erro ao atualizar permissão: ${error.message}`, position: 'top-right' })
      }
    } else {
      try {
        await submit()
        toastHandler.present({ type: 'success', messageOrContent: 'Permissão criada com sucesso!', position: 'top-right' })

        navigate('/permissoes');
      } catch (error: any) {
        toastHandler.present({ type: 'error', messageOrContent: `Erro ao criar permissão: ${error.message}`, position: 'top-right' })
      }
    }
  }

  /*    useEffect(() => {
        clientTypeService.getAll()
          .then((result) => {
            setClientTypes(result.data);
          }).catch((error: Error) => {
          toastHandler.present({ type: 'error', messageOrContent: `Erro ao consumir dados iniciais: ${error.message}`, position: 'top-right' });
        })
      }, [])*/

  useEffect(() => {
    if (id !== 'novo') {
      PermissionServices.getById(id).then(async (result: any) => {
        formRef.current?.setFieldValue('description', result.description);
        formRef.current?.setFieldValue('clientId', result.clientId);
        formRef.current?.setFieldValue('status', 'active');
        setPermissionIdlevel({
          clientId: result.clientId || "0",
          level: result.level,
        });
        setPermission(result.permissions);

        try {
          const types = await clientTypeService.getAll();
          setClientTypes(types.data);
          formRef.current?.setFieldValue('clientTypeId', result.clientTypeId);
        } catch (error: any) {
          toastHandler.present({ type: 'error', messageOrContent: `Erro ao consumir dados iniciais: ${error.message}`, position: 'top-right' });
        }
      });
    } else {
      clientTypeService.getAll().then((response) => {
        setClientTypes(response.data);
      }).catch((error: any) => {
        toastHandler.present({ type: 'error', messageOrContent: `Erro ao consumir dados iniciais: ${error.message}`, position: 'top-right' });
      });
      formRef.current?.setFieldValue('description', '');
      formRef.current?.setFieldValue('clientTypeId', '');
      formRef.current?.setFieldValue('status', '');
      setPermissionIdlevel(objectPermission);
    }
  }, [id]);

  return (
    <LayoutBaseDePagina
      titulo={'Permissões'}
      subtitulo={id === 'novo' ? 'Cadastro de nova permissão' : 'Alteração de Permissão'}
      barraDeFerramentas={
        <FerramentaDetalhe
          showButtonNew={id !== 'novo' && checkByProfileGuard(PermissionRolesEnum.PermissionsOptions, PermissionActionsEnum.Add)}
          showBotaoSalvarFechar={checkByProfileGuard(PermissionRolesEnum.PermissionsOptions, PermissionActionsEnum.Update)}
          showBotaoSalvar={checkByProfileGuard(PermissionRolesEnum.PermissionsOptions, PermissionActionsEnum.Update)}
          showBotaoApagar={false}
          clicarNovo={() => navigate('/permissoes/detalhe/novo')}
          clicarVoltar={() => navigate('/permissoes')}
          clicarSalvar={handleSubmitAndStay}
          clicarSalvarFechar={handleSubmitAndNavigateBack}
        />
      }
    >
      
        <><VForm 
         id="formIdSave"
         placeholder="Your placeholder"
         onPointerEnterCapture={() => console.log("Pointer entered")}
         onPointerLeaveCapture={() => console.log("Pointer left")}
        ref={formRef} onSubmit={save}>
          <Box margin={1} display="flex" flexDirection="column" component={Paper} variant="outlined" padding={2}>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={11} >
                <Typography variant="h6" color={'primary'}>Informações Gerais</Typography>
              </Grid>
            </Grid>
            <Grid container item direction="row" spacing={2}>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label='Descrição'
                  name="description"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label='Nível'
                  name="clientTypeId"
                  select
                >
                  {clientTypes?.map((type) => {
                    return (
                      <MenuItem value={type.id}>{`${type.description} (${type.level})`}</MenuItem>
                    )
                  })}
                </VTextField>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <VTextField
                  fullWidth
                  label='Status'
                  name="status"
                  select
                >
                  <MenuItem value={'active'}>Ativo</MenuItem>
                  <MenuItem value={'inactive'}>Inativo</MenuItem>
                </VTextField>
              </Grid>
            </Grid>
          </Box>
        </VForm>

          <TableContainer component={Paper} variant="outlined" sx={{ marginLeft: 1, marginTop: 1, marginRight: 2, width: 'auto', padding: 4 }}>
            <Table aria-label="a dense table" >
              <TableHead>
                <TableRow>
                  <TableCell colSpan={7}>
                    <ToolbarDetailPermission onClickGroup={handleSubmitAndStay} />
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <PermissionBox type={'menu'} permissions={permission} display={menu ? '' : 'none'} />

            <PermissionBox type={'dashboard'} permissions={permission} display={dashboard ? '' : 'none'} />

            <PermissionBox type={'clients'} permissions={permission} display={clients ? '' : 'none'} />

            <PermissionBox type={'users'} permissions={permission} display={users ? '' : 'none'} />

            <PermissionBox type={'analysis'} permissions={permission} display={analysis ? '' : 'none'} />

            <PermissionBox type={'warranties'} permissions={permission} display={warranties ? '' : 'none'} />

            <PermissionBox type={'sinister'} permissions={permission} display={sinister ? '' : 'none'} />

            <PermissionBox type={'sinisterValues'} permissions={permission} display={sinisterValues ? '' : 'none'} />

            <PermissionBox type={'reports'} permissions={permission} display={reports ? '' : 'none'} />

            <PermissionBox type={'financial'} permissions={permission} display={financial ? '' : 'none'} />

            <PermissionBox type={'comission'} permissions={permission} display={comission ? '' : 'none'} />

            <PermissionBox type={'generalConditions'} permissions={permission} display={generalConditions ? '' : 'none'} />

            <PermissionBox type={'charts'} permissions={permission} display={charts ? '' : 'none'} />

            <PermissionBox type={'permissions'} permissions={permission} display={permissions ? '' : 'none'} />

          </TableContainer>
        </>
    </LayoutBaseDePagina>
  );
};
