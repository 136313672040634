import * as yup from "yup";

export interface IFormData {
	planId: string;
	clientId: string;
	description?: string;
	lmi: string;
	residentialFee: string;
	commercialFee: string;
	bonusAmount: string;
	flagAnalysis: string;
	flagRenovation: string;
	flagRateRent: string;
	flagRateCharge: string;
	coverage: string;
	setupTransfer: string;
	comissionType: string;
	comissionValue: string;
	comissionProposalMin: string;
	usedFor?: string;
	chargeType?: string;
}

export const formValidationSchemaCreate: yup.SchemaOf<IFormData> = yup.object().shape({
	planId: yup.string().required("Campo obrigatório"),
	clientId: yup.string().required("Campo obrigatório"),
	lmi: yup.string().required("Campo obrigatório"),
	residentialFee: yup.string().required("Campo obrigatório"),
	commercialFee: yup.string().required("Campo obrigatório"),
	bonusAmount: yup.string().required("Campo obrigatório"),
	flagAnalysis: yup.string().required("Campo obrigatório"),
	flagRenovation: yup.string().required("Campo obrigatório"),
	flagRateRent: yup.string().required("Campo obrigatório"),
	flagRateCharge: yup.string().required("Campo obrigatório"),
	coverage: yup.string().required("Campo obrigatório"),
	setupTransfer: yup.string().required("Campo obrigatório"),
	comissionType: yup.string().required("Campo obrigatório"),
	comissionValue: yup.string().required("Campo obrigatório"),
	comissionProposalMin: yup.string().required("Campo obrigatório"),
	usedFor: yup.string().notRequired(),
	chargeType: yup.string().notRequired(),
	description: yup.string().notRequired(),
});
