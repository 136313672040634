import { useEffect, useRef, useState } from "react";
import { ToastContent } from "../../../../shared/components";
import { useToast } from "../../../../shared/hooks";
import { clientService } from "../../../../shared/services/api/client/clientService";
import {
    IDetalheUsuario,
    userService,
} from "../../../../shared/services/api/user/userService";
import { returnMessageError } from "../../../../shared/utils/returnMessageError";

interface ProposalUserDataProps {
    rows: any;
}

interface ProposalUserDataResult {
    user: {
        name?: string;
        email?: string;
        updatedAt?: string;
    };
}

export const useProposalUserData = ({
    rows,
}: ProposalUserDataProps): ProposalUserDataResult => {
    const [user, setUser] = useState<IDetalheUsuario>();
    const [isLoading, setIsLoading] = useState(false);
    const toastHandler = useToast();
    const previousOrigemRef = useRef<string | undefined>();

    const origem = rows?.createdFrom || "";

    const showToastError = (titleMessage: string, contentMessage: string) => {
        toastHandler.present({
            type: "error",
            position: "top-right",
            messageOrContent: (
                <ToastContent title={titleMessage} content={contentMessage} />
            ),
        });
    };

    const handleError = (err: any) => {
        const { titleMessage, contentMessage } = returnMessageError(err);
        showToastError(titleMessage, contentMessage);
    };

    useEffect(() => {
        if (origem && origem !== previousOrigemRef.current) {
            setIsLoading(true);
            previousOrigemRef.current = origem;

            if (origem === "web_app") {
                if (!rows?.userId) {
                    setIsLoading(false);
                    return;
                }

                userService
                    .getByID(`${rows.userId}`)
                    .then((response: any) => {
                        if (response instanceof Error) {
                            showToastError(
                                "Alerta!!",
                                "Erro: " + response?.message
                            );
                        } else {
                            setUser(response);
                        }
                    })
                    .catch((err: any) => handleError(err))
                    .finally(() => setIsLoading(false));
            } else if (origem === "web_service") {
                if (!rows?.clientId) {
                    setIsLoading(false);
                    return;
                }

                clientService
                    .getPartners(rows.clientId)
                    .then((response: any) => {
                        if (response instanceof Error) {
                            showToastError(
                                "Alerta!!",
                                "Erro: " + response?.message
                            );
                        } else {
                            setUser(response?.data[0]);
                        }
                    })
                    .catch((err: any) => handleError(err))
                    .finally(() => setIsLoading(false));
            }
        }
    }, [origem, rows?.userId, rows?.clientId]);

    return {
        user: {
            name: user?.name,
            email: user?.email,
            updatedAt: rows?.updatedAt || rows?.createdAt,
        },
    };
};
