import {
	Box,
	Chip,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
	Modal,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@mui/material";
import React from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import DescriptionIcon from "@mui/icons-material/Description";
import GetAppIcon from "@mui/icons-material/GetApp";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ModalFile from "./ModalFIle";
import TableInvoiceDetail from "./TableInvoiceDetail";
import { AddTaskOutlined } from "@mui/icons-material";
import { ToastContent } from "../../../shared/components";
import { LoadingComp } from "../../../shared/components/loading/Loading";
import { useDebounce, useToast } from "../../../shared/hooks";
import { ComissionServices } from "../../../shared/services/api/comission/ComissionServices";
import { globalBackgroundComission, globalColorText, globalFormatCurrencyBRL, globalMaskDocument, globalTranslateComission } from "../../../shared/utils";
import { formatDateFnsRemoveTimezone } from "../../../shared/utils/format-date";
import { ModalRegister } from "./ModalRegister";
import TableResume from "./TableResume";
import { IInvoiceData } from "./types";
import { ModalReportComission } from "./ModaReportsComission";
import { IReportCreate, reportsServices } from "../../../shared/services/api/reports/reportsServices";

// Definição das props
interface ITableInvoiceProps {
	rows: IInvoiceData[];
	page: number;
	rowsPerPage: number;
	totalCount: number;
	onChangePage: (newPage: number) => void;
	onChangeRowsPerPage: (newRowsPerPage: number) => void;
	onRefreshTable: () => void;
}

const styleModal = {
	position: "absolute" as const,
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "50%",
	maxHeight: "80vh",
	bgcolor: "background.paper",
	border: "1px solid #605e5e",
	boxShadow: 24,
	borderRadius: "6px",
	p: 3,
};

const TableInvoice: React.FC<ITableInvoiceProps> = ({ rows, page, rowsPerPage, totalCount, onChangePage, onChangeRowsPerPage, onRefreshTable }) => {
	// Menu de ações
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [selectedInvoiceId, setSelectedInvoiceId] = React.useState<number | null>(null);

	// Busca a fatura selecionada apenas uma vez antes do JSX
	const selectedInvoice = rows.find((invoice) => invoice.id === selectedInvoiceId);
	const isLaunchDisabled = !selectedInvoice || selectedInvoice.status !== "open";

	// Modais
	const [openDetailModal, setOpenDetailModal] = React.useState(false);
	const [openFileModal, setOpenFileModal] = React.useState(false);
	const [openRegisterModal, setOpenRegisterModal] = React.useState(false);
	const [OpenReportModal, setOpenReportModal] = React.useState(false);

	// Exemplo de loading local (se quiser usar)
	const [isLoading, setIsLoading] = React.useState(false);

	const toastHandler = useToast();

	const [rowsResume, setRowsResume] = React.useState<any[]>([]);
	const [rowsDetail, setRowsDetail] = React.useState<any[]>([]);

	/** Abre menu de ações de uma fatura específica */
	const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, invoiceId: number) => {
		setAnchorEl(event.currentTarget);
		setSelectedInvoiceId(invoiceId);
	};

	/** Fecha o menu de ações */
	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	/** Exemplos de ações: Download PDF / CSV */
	const downloadPDF = (invoiceId: number | null) => {
		try {
			ComissionServices.getComissionPdf(invoiceId?.toString() ?? "")
				.then((response) => {
					// Handle the response for PDF download
				})
				.catch((error) => {
					console.error(error);
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title="Erro" content="Erro ao baixar arquivo" />,
					});
				});
		} catch (error) {
			console.error(error);
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title="Erro" content="Erro ao baixar arquivo" />,
			});
		}
	};

	const downloadCSV = (invoiceId: number | null) => {
		console.log(`Baixando CSV para a fatura ID: ${invoiceId}`);
		setOpenReportModal(true);
	};

	const handleAddInstallment = () => {
		setOpenRegisterModal(true);
	};

	const handleFetchDetail = (id: string) => {
		setIsLoading(true);
		// **LIMPA OS DADOS ANTES DE FAZER A REQUISIÇÃO**
		setRowsResume([]);
		setRowsDetail([]);
		// Primeira requisição: Resumo da Comissão
		ComissionServices.getComissionByID(id)
			.then((response) => {
				if (response instanceof Error) {
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title="Erro" content="Erro ao buscar comissão" />,
					});
				} else {
					setRowsResume(Array.isArray(response) ? response : [response]);
				}
			})
			.catch((error) => {
				console.error(error);
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title="Erro" content="Erro ao buscar comissão" />,
				});
			});

		// Segunda requisição: Detalhes da Comissão
		ComissionServices.getComissionDetail(id)
			.then((response) => {
				if (response instanceof Error) {
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title="Erro" content="Erro ao buscar detalhes da comissão" />,
					});
				} else {
					setRowsDetail(Array.isArray(response) ? response : [response]);
				}
			})
			.catch((error) => {
				console.error(error);
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title="Erro" content="Erro ao buscar detalhes da comissão" />,
				});
			})
			.finally(() => {
				setIsLoading(false);
				// **ABRE O MODAL SOMENTE DEPOIS QUE OS DADOS SÃO BUSCADOS**
				setOpenDetailModal(true);
			});
	};

	/** Handler das opções do menu */
	const handleActionClick = (action: string) => {
		switch (action) {
			case "Ver Detalhes":
				handleFetchDetail(selectedInvoiceId?.toString() ?? "");
				break;
			case "Anexar Arquivo":
				setOpenFileModal(true);
				break;
			case "Baixar em PDF":
				downloadPDF(selectedInvoiceId);
				break;
			case "Baixar CSV":
				downloadCSV(selectedInvoiceId);
				break;
			case "Lançamento":
				handleAddInstallment();
				break;
			default:
				break;
		}
		handleMenuClose();
	};

	/** Fecha o modal de detalhes */
	const handleCloseDetailModal = () => {
		setOpenDetailModal(false);
	};

	/** Fecha o modal de anexar arquivo */
	const handleCloseFileModal = () => {
		setOpenFileModal(false);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		onChangePage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		onChangeRowsPerPage(parseInt(event.target.value, 10));
	};

	return (
		<>
			<LoadingComp isopen={isLoading} />
			<TableContainer component={Paper} variant="elevation" sx={{ marginTop: 1, width: "96%", padding: 3, ml: 1 }}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>
								<Typography variant="button">Data Criação </Typography>
							</TableCell>
							<TableCell>
								<Typography variant="button">Parceiro</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="button">Ciclo</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="button">Valor Total</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="button">Vencimento</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="button">Data de Pagamento</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="button">Status</Typography>
							</TableCell>
							<TableCell>
								<Typography variant="button">Ações</Typography>
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{rows.map((invoice) => (
							<TableRow key={invoice.id} hover>
								<TableCell>{formatDateFnsRemoveTimezone(invoice.createdAt.substring(0, 10))}</TableCell>
								<TableCell>
									{invoice.client.name}{" "}
									<Typography variant="body2" color="#878b8b">
										{globalMaskDocument(invoice.client.document)}
									</Typography>
								</TableCell>
								<TableCell>{invoice.reference}</TableCell>
								<TableCell>
									<Typography variant="subtitle2">{globalFormatCurrencyBRL(invoice.totValue)}</Typography>
									<Typography variant="body2" color="#878b8b">
										({invoice.totCount})
									</Typography>
								</TableCell>
								<TableCell>{formatDateFnsRemoveTimezone(invoice.dueDate)}</TableCell>
								<TableCell>{invoice.paymentDate ? formatDateFnsRemoveTimezone(invoice.paymentDate) : ""}</TableCell>
								<TableCell>
									<Chip
										size="medium"
										label={globalTranslateComission(invoice.status)}
										sx={{
											color: globalColorText(invoice.status),
											fontWeight: 500,
											background: globalBackgroundComission(invoice.status),
											border: "0.5px solid #f1f1f1",
										}}
									/>
								</TableCell>
								<TableCell>
									<IconButton onClick={(event) => handleMenuOpen(event, invoice.id)}>
										<MoreHorizIcon />
									</IconButton>
									<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
										<MenuItem onClick={() => handleActionClick("Ver Detalhes")}>
											<ListItemIcon>
												<DescriptionIcon fontSize="small" color="primary" />
											</ListItemIcon>
											<ListItemText primary="Ver Detalhes" />
										</MenuItem>
										<MenuItem onClick={() => handleActionClick("Anexar Arquivo")}>
											<ListItemIcon>
												<AttachFileIcon fontSize="small" color="info" />
											</ListItemIcon>
											<ListItemText primary="Anexar Arquivo" />
										</MenuItem>
										<MenuItem onClick={() => handleActionClick("Lançamento")} disabled={isLaunchDisabled}>
											<ListItemIcon>
												<AddTaskOutlined fontSize="small" />
											</ListItemIcon>
											<ListItemText primary="Lançamento" />
										</MenuItem>
										<MenuItem onClick={() => handleActionClick("Baixar em PDF")}>
											<ListItemIcon>
												<PictureAsPdfIcon fontSize="small" color="warning" />
											</ListItemIcon>
											<ListItemText primary="Baixar em PDF" />
										</MenuItem>
										<MenuItem onClick={() => handleActionClick("Baixar CSV")}>
											<ListItemIcon>
												<GetAppIcon fontSize="small" color="success" />
											</ListItemIcon>
											<ListItemText primary="Baixar CSV" />
										</MenuItem>
									</Menu>
								</TableCell>
							</TableRow>
						))}

						{/* Se não há registros e não está em loading, mostra uma mensagem */}
						{rows.length === 0 && !isLoading && (
							<TableRow>
								<TableCell colSpan={8} align="center">
									Nenhum registro
								</TableCell>
							</TableRow>
						)}
					</TableBody>

					<TableFooter>
						<TableRow>
							{/* Pagination: se totalCount > 0, exibimos o TablePagination */}
							{totalCount > 0 && (
								<TablePagination
									rowsPerPageOptions={[5, 10, 25, 50, 100]}
									colSpan={8}
									count={totalCount}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={handleChangePage}
									onRowsPerPageChange={handleChangeRowsPerPage}
									SelectProps={{
										inputProps: {
											"aria-label": "Linhas por página",
										},
										native: false,
									}}
									labelRowsPerPage="Linhas por página:"
									labelDisplayedRows={({ from, to, count }) => `${from}–${to} de ${count !== -1 ? count : `mais de ${to}`}`}
								/>
							)}
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>

			{/* Modal Detalhes */}
			<Modal open={openDetailModal} onClose={handleCloseDetailModal} aria-labelledby="modal-invoice-detail" aria-describedby="modal-invoice-description">
				<Box sx={styleModal}>
					<Typography variant="h4" paddingY={2} fontWeight={300} textAlign="center" color="primary">
						Detalhes da Fatura
					</Typography>
					{selectedInvoice && <TableResume rows={rowsResume} />}
					{selectedInvoice && <TableInvoiceDetail rows={rowsDetail} isLoading={isLoading} />}
				</Box>
			</Modal>

			{/* Modal Anexar Arquivo */}

			<ModalFile isOpen={openFileModal} onClose={handleCloseFileModal} comissionId={selectedInvoice?.id.toString() ?? ""} />

			<ModalReportComission
				isOpenModal={OpenReportModal}
				onClose={() => setOpenReportModal(false)}
				enableBackdropClose={true}
				variant="h4"
				idFind={selectedInvoiceId?.toString() ?? undefined}
			/>

			{selectedInvoice && (
				<ModalRegister
					visible={openRegisterModal}
					onClose={() => setOpenRegisterModal(false)}
					onSuccess={onRefreshTable}
					rowsInstallment={selectedInvoice}
				/>
			)}
		</>
	);
};

export default TableInvoice;
