import { Api } from "../axios-config";

export interface IReports {
	id: string;
	user_client_id: string;
	user_id: string;
	user_name: string;
	report_name: string;
	status: string;
	file_path: string;
	date_start: string;
	date_end: string;
	updated_at: string;
	created_at: string;
}

export interface IReportCreate {
	report_key: string;
	date_start: string;
	date_end: string;
	manager_id?: string;
	comission_id?: string;
}

type TReportsTotalCont = {
	data: IReports[];
	total: number;
};

const getFindReports = async (page = 1, limit = 5): Promise<TReportsTotalCont | Error> => {
	try {
		const { data, headers } = await Api().get(`/report/find?_page=${page}&_limit=${limit}&_order=DESC`);
		if (data.results) {
			return {
				data: data.results,
				total: data.total,
			};
		}
		return new Promise((_, reject) => reject(new Error("Erro ao listar registros.")));
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getFindFilterReports = async (patchFilter= '', page = 1, limit = 5): Promise<TReportsTotalCont | Error> => {
	try {
		const { data, headers } = await Api().get(`/report/findfilter?pathFilter=${patchFilter}&_page=${page}&_limit=${limit}&_order=DESC`);
		if (data.results) {
			return {
				data: data.results,
				total: data.total,
			};
		}
		return new Promise((_, reject) => reject(new Error("Erro ao listar registros.")));
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
};

const getDownloadReport = async (id: string): Promise<any | Error> => {
	try {
		const { data } = await Api().get(`/report/download/${id}`);
		if (data) {
			return data;
		}
		return new Promise((_, reject) => reject(new Error("Erro ao baixar o arquivo.")));
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao baixar o arquivo.");
	}
};

const createReport = async (dados: Omit<IReportCreate, "id">): Promise<string | Error> => {
	try {
		const { data } = await Api().post<IReportCreate>(`/report/create`, dados);
		if (data) {
			return data.report_key;
		}
		return new Promise((_, reject) => reject(new Error("Erro ao criar o relatório.")));
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao criar o relatório.");
	}
};

export const reportsServices = {
	getFindReports,
	getDownloadReport,
	createReport,
	getFindFilterReports
};
