import { Box, Button, Card, Divider, Grid, Icon, IconButton, InputAdornment, LinearProgress, MenuItem, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import 'moment/locale/pt-br';
import { BasicModal, ModalContext, ResponsibleModal, ToastContent } from "../../../shared/components";
import * as yup from "yup";

import { IVFormErros, VForm, VTextField, useVForm } from "../../../shared/forms";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { ToolbarSinister } from "../../../shared/components/toolbarSinister/toolbarSinister";
import { ICanceledSinister, ISinisterDetails, ISinistercheckList, ITotalLmi, SinisterService } from "../../../shared/services/api/sinister/SinisterService";
import { checkByProfileGuard } from '../../../shared/guards/ProfileGuard';
import { PermissionActionsEnum, PermissionRolesEnum } from '../../../shared/enums/profiles';
import { GetDocumentTypesResponseType } from "../../../shared/services/api/documents/types";
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { useDebounce, useToast } from "../../../shared/hooks";
import { SinisterFilesModalContent } from "./SinisterFilesModalContent";
import { IDetalheUsuario, IListagemUsuario, userService } from "../../../shared/services/api/user/userService";
import { useProfile } from "../../../context/ProfileContext";
import { returnMessageError } from "../../../shared/utils/returnMessageError";
import { globalTranslate } from "../../../shared/utils";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { SinisterFollowupModalContent } from "./SinisterFollowupModalContent";
import { ProvisionService } from "../../../shared/services/api/provisoes/ProvisionService";
import { SinisterHeader } from "./SinisterHeader/SinisterHeader";
import { IOpenAttachmentParams, ModalContextDocument } from "./content-modal-documents";

interface IFormDataCancel {
    canceledReason?: string;
    canceledObs: string;
}

const maskCell = (value: string) => {
    return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})\d+?$/, '$0')
};

const maskReal = (value: string) => {
    const sinal = (Number(value) < 0) ? '-' : '';
    const strValue = value
        .replace(/\D/g, '')
        .replace(/(\d{1,2})$/, ',$1')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
    return sinal + strValue;

    // .replace(/^R$(\d{1,3}(\.\d{3})*|\d+)(\,\d{2})?$/,'')   remove todos caracteres da moeda
};

export const SinisterDetailsPresentation: React.FC = () => {
    const { id = 'novo' } = useParams<'id'>();
    const navigate = useNavigate();
    const { formRef, save, saveAndClose, saveAndNew, issaveAndClose } = useVForm();
    const [isLoading, setIsLoading] = useState(false);
    const [nome, setNome] = useState('');
    const [doc, setDoc] = useState('');
    const [openModal, setOpenModal] = useState(false);
    const [rows, setRows] = useState<ISinisterDetails>();
    const [statusSinister, setStatusSinister] = useState('sketch');
    const [statusProperty, setStatusProperty] = useState('busy');
    const [busyProperty, setBusyProperty] = useState('');
    const [checkStatus, setCheckStatus] = useState(false);
    const [rowUser, setRowUser] = useState<IDetalheUsuario>();
    const [sumLmi, setSumLmi] = useState<ITotalLmi>(); //ITotalStatusObj>();
    const [openModalCheckList, setOpenModalCheckList] = useState(false);
    const [checklist, setChecklist] = useState<ISinistercheckList[]>();
    const [openModalResponsible, setOpenModalResponsible] = useState(false);
    const [responsible, setResponsible] = useState<IListagemUsuario[]>([]);
    const [responsibleName, setResponsibleName] = useState('Nenhum responsável designado')
    const [updateRow, setUpdateRow] = useState(false);
    const theme = useTheme();
    const [listSel, setListSel] = useState('0')
    const [showButtonSendSinister, setShowButtonSendSinister] = useState(false);
    const [listDescription, setListDescription] = useState('')
    const smDown = useMediaQuery(theme.breakpoints.down('sm'));
    const [openModalAttachment, setOpenModalAttachment] = useState(false);
    const [openModalCancel, setOpenModalCancel] = useState(false);

    const [showButtonOpenSinister, setShowButtonOpenSinister] = useState(false);
    const [openModalUpdateStatus, setOpenModalUpdateStatus] = useState(false);
    const [showButtonUpdateStatus, setShowButtonUpdateStatus] = useState(false);
    const [openModalUpdatePropert, setOpenModalUpdatePropert] = useState(false);
    const [openModalValue, setOpenModalValue] = useState(false);
    const [totalBordero, setTotalBordero] = useState(0);

    const { debounce } = useDebounce();
    const { isSuperAdmin, permissionLevel } = useProfile();
    const [users, setUsers] = useState<IListagemUsuario[]>([]);
    const [loading, setLoading] = useState(false);
    const [fileTypeId, setFileTypeId] = useState<string>();
    const [file, setFile] = useState<File>();
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [openModalFollowup, setOpenModalFollowup] = useState(false);
    const [documentTypes, setDocumentTypes] = useState<
        GetDocumentTypesResponseType[]
    >([]);
    const [fileUploadedSuccessfully, setFileUploadedSuccessfully] =
        useState<boolean>(false);

    const onFileUploadHandler = (file: File | undefined) => {
        setFile(file);
    };

    const toastHandler = useToast();
    const [refreshList, setRefreshList] = useState(false);

    const handleOpenModal = () => {
        setOpenModal(true)
    };

    const handleModalUpdateProperty = () => {
        setOpenModalUpdatePropert(true)
    }

    const formValidationSchemaUpdateStatus: yup.SchemaOf<{ status: string }> = yup
        .object()
        .shape({
            status: yup.string().required("Campo Obrigatório"),
        });

    const formValidationSchemaUpdateProperty: yup.SchemaOf<{ busyProperty: string }> = yup
        .object()
        .shape({
            busyProperty: yup.string().required("Campo Obrigatório"),
        });

    const handleUpdateStatus = () => {
        setOpenModalUpdateStatus(true);
    };

    const handleOpenModalResponsible = () => {
        setOpenModalResponsible(true)
    };

    const handleCloseModalResponsible = (isAddManager?: boolean) => {
        if (isAddManager) {
            setUpdateRow(!updateRow)
            handleGetSinister();
        }
        setOpenModalResponsible(false);
    };

    const formValidationSchemaCancel: yup.SchemaOf<ICanceledSinister> = yup.object().shape({
        canceledReason: yup.string().required("Campo Obrigatório"),
        canceledObs: yup.string().required("Campo Obrigatório"),
    });

    const handleSaveCancel = (dados: IFormDataCancel) => {
        console.log(dados)
        formValidationSchemaCancel
            .validate(dados, { abortEarly: false })
            .then((dadosValidados) => {
                try {
                    SinisterService.cancelSinister(id, dadosValidados).then(
                        (result) => {
                            toastHandler.present({
                                type: "success",
                                position: "top-right",
                                messageOrContent: (
                                    <ToastContent
                                        title={"Sucesso"}
                                        content={"Cancelado com sucesso"}
                                    />
                                ),
                            });
                            setOpenModalCancel(false);
                            navigate(`/sinistros`)
                        }
                    );
                } catch (e: any) {
                    toastHandler.present({
                        type: "error",
                        messageOrContent: e.message,
                        position: "top-center",
                    });
                } finally {
                    setIsUploading(false);
                }
            })
            .catch((errors: yup.ValidationError) => {
                const validationErros: IVFormErros = {};
                errors.inner.forEach((error) => {
                    if (!error.path) return;
                    validationErros[error.path] = error.message;
                });
                formRef.current?.setErrors(validationErros);
            });

        // handleOpenModal()
    };

    const handleOpenModalAttachment = (params: IOpenAttachmentParams) => {
        // Agora você pode acessar as informações passadas pelo componente filho
        const { id, description } = params;
        setListSel(id),
            setListDescription(description),
            setOpenModalAttachment(true)
    };

    const handleOpenModalCancel = () => {
        // console.log('292 handlemodal cancel....')
        setOpenModalCancel(true)
    };


    const handleOpenModalFollowup = () => {
        setOpenModalFollowup(true);
    };

    const handlechecklist = (id: string, ocuped: string) => {
        setIsLoading(false);
        const check = handleCheckListFiles(id, ocuped);
        // console.log('291.........', check)
        setOpenModalCheckList(true);
    };

    const handleDownloadAll = () => {
        console.log('download alll', id)
        SinisterService.getDownloadAll(id)
            .then((result) => {
            });
    }

    const handleCheckListFiles = (id: string, ocuped: string) => {
        SinisterService.getSinisterCheckList(id, ocuped)
            .then((result) => {
                if (result) {
                    const novo = result;
                    const resultado = result.data;
                    const libera = resultado.filter(element => ((element.quant == "0") && (
                        (ocuped == 'open' && element.open == 'required') ||
                        (ocuped == 'busy' && element.busy == 'required'))));
                    console.log('libera sinistro....329', ocuped, libera)
                    setChecklist(result.data),
                        setShowButtonSendSinister(true)
                    SinisterService.getListOff(id)
                        .then((resultOff) => {
                            if (resultOff) {
                                setCheckStatus(resultOff.data == 0 ? true : false);
                            }
                        });
                }
            });
    }

    useEffect(() => {
        if (refreshList) {
            handleCheckListFiles(id, busyProperty);
        }
    }, [refreshList]);

    const handleUpdateCheckFiles = () => {
        handleCheckListFiles(id, busyProperty)
    }

    const closeAttachment = () => {
        handleCheckListFiles(id, busyProperty);
    }

    const handleCancelSinister = () => {
        setOpenModalCancel(false);
    }
    const options = {
        titleTextStyle: { color: '#E9B801' },
        pieSliceText: "value",
        colors: ['#97D3F0', '#7924c7', '#82DC80'],
        pieHole: 0, // 0.4,
        legend: { position: 'bottom', textStyle: { fontSize: 14 }, },
        pieSliceTextStyle: {
            color: '#fff',
        },
        showColorCode: true,
        backgroundColor: "#FFF",
        width: 200,
        height: 120,
        is3D: true
    };


    const getManagersByClient = () => {
        if ((isSuperAdmin || permissionLevel == 2) && id !== 'novo') {
            setLoading(true);
            debounce(() => {
                userService.getManagersByClient(id)
                    .then((result) => {
                        setLoading(false);
                        if (result instanceof Error) {
                            const titleMessage = "Alerta!!";
                            const contentMessage = "Erro: " + result?.message;
                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            })
                        } else {
                            setUsers(result.data);
                        }
                    }).catch((err: any) => {
                        setLoading(false);
                        const { titleMessage, contentMessage } = returnMessageError(err);
                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    })
            });
        }
    }

    const handleOpenSinister = (id: string) => {
        setIsLoading(true);
        SinisterService.confirmOpenSinister(id).then((result) => {
            console.log("result 400", result);
            //  setUpdateRow(true);
            toastHandler.present({
                type: "success",
                position: "top-right",
                messageOrContent: (
                    <ToastContent
                        title={"Sucesso"}
                        content={"Registro gravado com sucesso"}
                    />
                ),
            });
            setStatusSinister(result.status);
            //  setOpenModalValue(false);
            //  navigate(`/sinistros/valores/${id}`);
            setIsLoading(false);
            setOpenModalValue(false);
        });
    };

    const handleUpdateStatusSave = (dados: { status: string }) => {
        setIsLoading(true);
        formValidationSchemaUpdateStatus
            .validate(dados, { abortEarly: false })
            .then((dadosvalidados) => {
                try {
                    SinisterService.updateStatusSinister(id, dados).then((result) => {
                        console.log("result 400", result);
                        setStatusSinister(dados.status);
                        // setUpdateRow(!updateRowtrue);
                        toastHandler.present({
                            type: "success",
                            position: "top-right",
                            messageOrContent: (
                                <ToastContent
                                    title={"Sucesso"}
                                    content={"Status do sinistro atualizado com sucesso"}
                                />
                            ),
                        });
                        setIsLoading(false);
                        setOpenModalUpdateStatus(false);
                        setUpdateRow(!updateRow);
                    });
                } catch (e: any) {
                    toastHandler.present({
                        type: "error",
                        messageOrContent: e.message,
                        position: "top-center",
                    });
                } finally {
                    setIsUploading(false);
                }
            })
            .catch((errors: yup.ValidationError) => {
                setIsLoading(false);
                const validationErros: IVFormErros = {};
                errors.inner.forEach((error) => {
                    if (!error.path) return;
                    validationErros[error.path] = error.message;
                });
                console.log(validationErros);
                formRef.current?.setErrors(validationErros);
            });
    };

    const handleUpdatePropertySave = (dados: { busyProperty: string }) => {
        setIsLoading(true);
        console.log('dados do handle: ', dados.busyProperty);
        formValidationSchemaUpdateProperty
            .validate(dados, { abortEarly: false })
            .then((dadosvalidados) => {
                try {
                    SinisterService.updatePropertySinister(id, dados).then((result) => {
                        setBusyProperty(dados.busyProperty);
                        toastHandler.present({
                            type: "success",
                            position: "top-right",
                            messageOrContent: (
                                <ToastContent
                                    title={"Sucesso"}
                                    content={"Situação do imóvel atualizado com sucesso"}
                                />
                            ),
                        });
                        setIsLoading(false);
                        setOpenModalUpdatePropert(false);
                        setUpdateRow(!updateRow);
                    });
                } catch (e: any) {
                    toastHandler.present({
                        type: "error",
                        messageOrContent: e.message,
                        position: "top-center",
                    });
                } finally {
                    setIsUploading(false);
                }
            })
            .catch((errors: yup.ValidationError) => {
                setIsLoading(false);
                const validationErros: IVFormErros = {};
                errors.inner.forEach((error) => {
                    if (!error.path) return;
                    validationErros[error.path] = error.message;
                });
                console.log(validationErros);
                formRef.current?.setErrors(validationErros);
            });
    };

    const handleGetSinister = () => {
        SinisterService.getById(`${id}`)
            .then((result) => {
                setIsLoading(false);
                if (result instanceof Error) {
                    alert(result.message);
                    navigate('/sinister');
                } else {
                    if (result.responsibleId) {
                        userService.getByID(result?.responsibleId)
                            .then((resultUser) => {
                                // setLoading(false);
                                if (resultUser instanceof Error) {
                                    const titleMessage = "Alerta!!";
                                    const contentMessage = "Erro: " + resultUser?.message;
                                } else {
                                    console.log('384.... resultUser', resultUser)
                                    setRowUser(resultUser);
                                    setResponsibleName(resultUser.name);
                                }
                            });
                    }
                    if (result.id) {
                        ProvisionService.countBordero(id)
                            .then((resultCount: any) => {
                                console.log('587......', resultCount)
                                // setLoading(false);
                                if (resultCount instanceof Error) {
                                    setTotalBordero(0);
                                } else {
                                    setTotalBordero(Number(resultCount))
                                }
                            });
                    }
                    setNome(result.proposal?.name);
                    setDoc(result.proposal?.document);
                    setBusyProperty(result?.busyProperty ? result.busyProperty : '');
                    setRows(result);
                    setStatusSinister(result.status);
                    setShowButtonUpdateStatus(result.status == "sketch" || statusSinister == "canceled" ? false : true);
                    handleCheckListFiles(result.id, result?.busyProperty ? result.busyProperty : '')
                }
            });
    }

    useEffect(() => {
        if (id !== 'novo') {
            setIsLoading(true);
            handleGetSinister();

            SinisterService.totalLmi(`${id}`)
                .then((result) => {
                    setIsLoading(false);
                    if (result instanceof Error) {
                        alert(result.message);
                        navigate('/sinister');
                    } else {
                        console.log('325... total lmi...', result)
                        setSumLmi(result.data)
                    }
                });

        }
    }, [id, updateRow]);

    useEffect(() => {
        if ((isSuperAdmin || permissionLevel == 2) && id !== 'novo') {
            setLoading(true);
            debounce(() => {
                userService.getManagers("", id)
                    .then((result) => {
                        setLoading(false);
                        if (result instanceof Error) {
                            const titleMessage = "Alerta!!";
                            const contentMessage = "Erro: " + result?.message;

                            toastHandler.present({
                                type: 'error',
                                position: 'top-right',
                                messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                            })
                        } else setResponsible(result.data);

                    }).catch((err: any) => {
                        setLoading(false);
                        const { titleMessage, contentMessage } = returnMessageError(err);

                        toastHandler.present({
                            type: 'error',
                            position: 'top-right',
                            messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />
                        })
                    })
            });
        }
    }, []);

    return (
        <LayoutBaseDePagina
            titulo='Sinistros'
            subtitulo={`Detalhes do Sinistro  [ ${nome} ]`}
            barraDeFerramentas={
                <ToolbarSinister
                    clickButtonBack={() => navigate('/sinistros')}
                    clickButtonValues={() => navigate(`/sinistros/valores/${id}`)}
                    clickButtonDocument={() => { handlechecklist(`${id}`, busyProperty) }}
                    showButtonCancel={ permissionLevel==10 ? false:
                        (statusSinister == 'sketch' || statusSinister == 'open') || isSuperAdmin || (checkByProfileGuard(PermissionRolesEnum.SinisterOptions, PermissionActionsEnum.Update) && permissionLevel == 2)}
                    clickButtonCancel={() => { handleOpenModalCancel() }}
                    showButtonValid={false}
                    showButtonConfirm={false}
                    clickButtonResponsible={() => { handleOpenModalResponsible() }}
                    showButtonFinish={false} // por hora deixar falso {(isSuperAdmin || (permissionLevel? (permissionLevel<5):false ) ) } 
                    showButtonResponsible={(isSuperAdmin || (permissionLevel ? (permissionLevel < 5) : false)) && !(statusSinister == 'sketch')} //permissão super Admin e usuário Garantti. criar permissão especifica
                    showButtonValues={checkByProfileGuard(PermissionRolesEnum.SinisterValuesOptions, PermissionActionsEnum.View) && ((statusSinister != 'sketch' && statusSinister != 'canceled' && statusSinister != 'finalized') || (statusSinister == 'sketch' && checkStatus))}
                    clickButtonFollowUp={handleOpenModalFollowup}

                    showButtonBackLoading={isLoading}
                    showButtonCancelLoading={isLoading}
                    showButtonFinishLoading={isLoading}
                    showButtonDocumentLoading={isLoading}
                    showButtonValuesLoading={isLoading}
                    showButtonValidLoading={isLoading}
                    showButtonConfirmLoading={isLoading}
                    showButtonResponsibleLoading={isLoading}
                    showButtonFollowUpLoading={isLoading}
                />
            }
        >

            {openModalFollowup && (
                <ModalContext
                    isCloseModal={() => setOpenModalFollowup(false)}
                    isOpenModal={openModalFollowup}
                    messageType={"question"}
                    showButtonCancel={false}
                    showButtonConfirm={false}
                    showTitleIcon={false}
                    textButtonCancel="Fechar"
                    enableBackdropClose={true}
                    overrideWidthValue={"45%"}
                    overrideHeightValue={"100%"}
                    ITitle={"Followup do Sinistro"}
                    IMessage={[
                        <SinisterFollowupModalContent sinisterId={id} statusSinister={statusSinister} />,
                    ]}
                />
            )}

            {openModalUpdatePropert && (<ModalContext
                isCloseModal={() => { setOpenModalUpdatePropert(false) }}
                isOpenModal={openModalUpdatePropert}
                messageType={"question"}
                showButtonCancel={false}
                showButtonConfirm={false}
                showTitleIcon={false}
                enableBackdropClose={true}
                overrideWidthValue={"45%"}
                ITitle={"Alteração de situação do imóvel em sinistro"}
                IMessage={[
                    <VForm 
                    id="formIdUpdatePropertySave"
                    placeholder="Your placeholder"
                    onPointerEnterCapture={() => console.log("Pointer entered")}
                    onPointerLeaveCapture={() => console.log("Pointer left")}
                    ref={formRef} onSubmit={handleUpdatePropertySave}>
                        <Grid container direction="column" padding={1} spacing={2}>
                            {isLoading && (
                                <Grid item>
                                    <LinearProgress variant="indeterminate" />
                                </Grid>
                            )}

                            <Typography mb={3} mt={3}>
                                {`A alteração do status poderá ter a obrigação de anexo de novos documentos para continuar o sinistro`}
                            </Typography>

                            <Grid container item direction="row" spacing={2}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <VTextField
                                        fullWidth
                                        label="Situação do imóvel"
                                        name="busyProperty"
                                        disabled={false}
                                        select
                                    >
                                        <MenuItem value="busy"> Ocupado</MenuItem>
                                        <MenuItem value="not_busy"> Desocupado</MenuItem>
                                    </VTextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item direction="row" spacing={2} textAlign={"center"}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Button
                                    color="primary"
                                    disableElevation
                                    variant={"contained"}
                                    startIcon={<Icon> check</Icon>}
                                    onClick={() => {
                                        formRef.current?.submitForm();
                                    }}
                                    disabled={isUploading}
                                    style={{
                                        marginTop: "16px",
                                        marginRight: "10px",
                                        width: "30%",
                                    }}
                                >
                                    {"Confirmar"}
                                </Button>
                                <Button
                                    color="primary"
                                    disableElevation
                                    variant={"outlined"}
                                    style={{
                                        marginTop: "16px",
                                        width: "30%",
                                    }}
                                    startIcon={<Icon> arrow_back_Ico</Icon>}
                                    onClick={() => {
                                        setOpenModalUpdatePropert(false);
                                    }}
                                >
                                    {"Voltar"}
                                </Button>
                            </Grid>
                        </Grid>
                    </VForm>
                ]}
            />
            )}

            {(isSuperAdmin || permissionLevel == 2) && openModalUpdateStatus && (
                <ModalContext
                    isCloseModal={() => setOpenModalValue(false)}
                    isOpenModal={openModalUpdateStatus}
                    messageType={"info"}
                    enableBackdropClose={false}
                    overrideWidthValue="40%"
                    showButtonCancel={false}
                    overrideHeightValue="auto"
                    ITitle="Alterar status do sinistro "
                    IMessage={[
                        <Box style={{ width: "90%" }}>
                            <Typography
                                id="modal-modal-description"
                                sx={{ mt: 2, mb: 2, color: "#7d7d7d" }}
                            >
                                <VForm 
                                 id="formIdUpdateStatusSave"
                                 placeholder="Your placeholder"
                                 onPointerEnterCapture={() => console.log("Pointer entered")}
                                 onPointerLeaveCapture={() => console.log("Pointer left")}
                                ref={formRef} onSubmit={handleUpdateStatusSave}>
                                    <Grid container direction="column" padding={1} spacing={2}>
                                        {isLoading && (
                                            <Grid item>
                                                <LinearProgress variant="indeterminate" />
                                            </Grid>
                                        )}
                                        <Grid container item direction="row" spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <VTextField
                                                    fullWidth
                                                    label="Status do Sinistro"
                                                    name="status"
                                                    disabled={isLoading}
                                                    select
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start"></InputAdornment>
                                                        ),
                                                    }}
                                                >
                                                    {/* open,in_progress,debts_confession,finalized,monthly_compensation,lmi_exhaustion,pending,canceled,sketch */}
                                                    <MenuItem value={""}>Selecionar Status</MenuItem>
                                                    <MenuItem value={"open"}>Aberto</MenuItem>
                                                    <MenuItem value={"in_progress"}>
                                                        Em análise
                                                    </MenuItem>
                                                    <MenuItem value={"debts_confession"}>
                                                        Confissão de dividas{" "}
                                                    </MenuItem>
                                                    <MenuItem value={"monthly_compensation"}>
                                                        Indenização Mensal
                                                    </MenuItem>
                                                    <MenuItem value={"finalized"}>Finalizado</MenuItem>
                                                    <MenuItem value={"according"}>Em Acordo</MenuItem>
                                                    <MenuItem value={"pending"}>Pendenciado</MenuItem>
                                                    {/* <MenuItem value={"canceled"}>Cancelado</MenuItem> */}
                                                </VTextField>
                                            </Grid>
                                        </Grid>
                                        <Grid container item direction="row" spacing={2} >
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <Button
                                                    color="primary"
                                                    disableElevation
                                                    variant={"contained"}
                                                    onClick={() => {
                                                        formRef.current?.submitForm();
                                                    }}
                                                    disabled={isUploading}
                                                    style={{
                                                        marginTop: "16px",
                                                        marginRight: "10px",
                                                        width: "30%",
                                                    }}
                                                >
                                                    {"Confirmar"}
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    disableElevation
                                                    variant={"outlined"}
                                                    style={{
                                                        marginTop: "16px",
                                                        width: "30%",
                                                    }}
                                                    startIcon={<Icon> arrow_back_Ico</Icon>}
                                                    onClick={() => {
                                                        setOpenModalUpdateStatus(false);
                                                    }}
                                                >
                                                    {"Voltar"}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </VForm>
                            </Typography>
                        </Box>,
                    ]}
                />
            )}


            {openModalAttachment && (<ModalContext
                isCloseModal={() => { setOpenModalAttachment(false), closeAttachment() }}
                isOpenModal={openModalAttachment}
                messageType={"question"}
                showButtonCancel={true}
                showButtonConfirm={false}
                showTitleIcon={false}
                textButtonCancel="Fechar"
                onclickConfirm={() => { handleUpdateCheckFiles() }}
                enableBackdropClose={true}
                overrideWidthValue={"45%"}
                ITitle={"Documento do Sinistro"}
                ISubTitle={`Tipo do Documento ${listDescription}`}
                IMessage={[<SinisterFilesModalContent setRefreshList={value => setRefreshList(value)} sinisterId={id} listId={listSel} statusSinister={statusSinister} subTitle={`Anexar Somente ${listDescription}`} />]}
            />
            )}


            {openModalCancel && (<ModalContext

                isCloseModal={() => {
                    setOpenModalCancel(false)
                    //closeAttachment()
                }
                }
                isOpenModal={openModalCancel}
                messageType={"question"}
                showButtonCancel={false}
                showButtonConfirm={false}
                showTitleIcon={false}
                textButtonCancel="Fechar"
                onclickConfirm={() => { handleCancelSinister() }}
                enableBackdropClose={true}
                overrideWidthValue={"45%"}
                ITitle={"Cancelar Sinistro"}
                IMessage={
                    [
                        <Box style={{ width: "100%" }}>
                            <Box
                                margin={1}
                                display="flex"
                                flexDirection="column"
                                component={Box}
                            >
                                <Grid container item direction="row" >
                                    <Grid item sm={12} md={12} lg={9} xl={9} mb={"15px"} >
                                        {/* <Typography variant="subtitle2">
                                        Sinistro será cancelado.
                                    </Typography> */}
                                    </Grid>
                                </Grid>

                                <VForm 
                                 id="formIdSaveCancel"
                                 placeholder="Your placeholder"
                                 onPointerEnterCapture={() => console.log("Pointer entered")}
                                 onPointerLeaveCapture={() => console.log("Pointer left")}
                                ref={formRef} onSubmit={handleSaveCancel}>
                                    <Grid container item direction="row" spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <VTextField
                                                fullWidth
                                                label="Motivo"
                                                name="canceledReason"
                                                disabled={isLoading}
                                                select
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment position="start"></InputAdornment>
                                                    ),
                                                }}
                                            >
                                                <MenuItem value={"inadimplência"}>Inadimplência da  imobiliária</MenuItem>
                                                <MenuItem value={"irregular"}>  Contratação irregular  </MenuItem>
                                                <MenuItem value={"inexistente"}>Garantia inexistente</MenuItem>
                                                <MenuItem value={"irregularidades"}>  Irregularidades identificadas</MenuItem>
                                                <MenuItem value={"discharge_tenant"}>Quitação do locatário direto na imobiliária</MenuItem>
                                                <MenuItem value={"outros"}>Outros</MenuItem>
                                            </VTextField>
                                        </Grid>

                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <VTextField
                                                fullWidth
                                                label="Observação"
                                                name="canceledObs"
                                                maxRows={3}
                                                multiline={true}
                                                disabled={isLoading}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mb: "15px" }} />
                                    <Grid
                                        container
                                        sm={12}
                                        sx={{
                                            border: "none",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                        display={"flex"}
                                        flexDirection={smDown ? "column" : "row"}
                                        padding={2}
                                        gap={2}
                                    >
                                        <Button
                                            color="primary"
                                            disableElevation
                                            variant={"contained"}
                                            onClick={() => {
                                                formRef.current?.submitForm();
                                            }}
                                            disabled={isUploading}
                                            style={{
                                                marginTop: "16px",
                                                marginRight: "10px",
                                                width: "33%",
                                            }}
                                        >
                                            {"Confirmar"}
                                        </Button>
                                        <Button
                                            color="primary"
                                            disableElevation
                                            variant={"outlined"}
                                            style={{
                                                marginTop: "16px",
                                                width: "33%",
                                            }}
                                            startIcon={<Icon> arrow_back_Ico</Icon>}
                                            onClick={() => {
                                                setOpenModalCancel(false);
                                            }}
                                        >
                                            {"Voltar"}
                                        </Button>
                                    </Grid>
                                </VForm>
                            </Box>
                        </Box>
                    ]

                }
            />
            )}

            {(isSuperAdmin || permissionLevel == 2) && openModalResponsible && <ResponsibleModal
                isCloseModal={handleCloseModalResponsible}
                isOpenModal={openModalResponsible}
                messageType={'info'}
                onclickConfirm={() => { handleCloseModalResponsible(true) }}
                responsibles={responsible}
                idSinister={id}
            />}

            {openModalCheckList && (
                <ModalContext
                    isCloseModal={() => setOpenModalCheckList(false)}
                    isOpenModal={openModalCheckList}
                    messageType={"info"}
                    showButtonCancel={false}
                    showButtonConfirm={true}
                    textButtonConfirm={"Continuar"}
                    textButtonCancel={"Continuar "}
                    onclickConfirm={() => {
                        setOpenModalCheckList(false)
                    }}
                    ITitle={
                        'Documentos do Sinistro'
                    }
                    IMessage={[
                        <>
                            <ModalContextDocument
                                checkList={checklist ? checklist : undefined}
                                onClickDown={handleDownloadAll}
                                onClickOpenAttachment={handleOpenModalAttachment}
                            />
                        </>
                    ]}
                />
            )}


            <Grid container spacing={3}>
                <SinisterHeader
                    LMIResult={sumLmi?.saldo ? sumLmi?.saldo : 0}
                    LMIvalue={sumLmi?.lmiTotal ? sumLmi?.lmiTotal : 0}
                    borderoTotally={totalBordero}
                    certificate={rows?.proposal?.certificateNumber}
                    closeSinister={rows?.finalizedAt}
                    createAt={rows?.createdAt}
                    emailClient={rows?.client?.email}
                    name={rows?.proposal?.name}
                    openResponse={rows?.client?.name}
                    openSinister={rows?.createdAt}
                    proportyBusy={rows?.busyProperty}
                    proportyCreditRecovery={rows?.status === "credit_recovery"}
                    showButtonStatus={(isSuperAdmin || permissionLevel == 2) && showButtonUpdateStatus ? true : false}
                    sinisterResponse={responsibleName}
                    status={rows?.status}
                    onclickbuttonStatus={() => { handleUpdateStatus() }}
                    textButtonStatus="Status"
                    showButtonStateProperty={(isSuperAdmin || permissionLevel == 4) ? true : false}
                    onclickStateProperty={() => { handleModalUpdateProperty() }}
                />

                <Grid item xs={12} md={12} >
                    {/* *************Inquilino */}
                    <Grid container sm={12}
                        display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                        ml={smDown ? 0 : 2}
                    >
                        <Grid container sm={12}
                            display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            component={Paper}
                            padding={2}
                            spacing={1}
                        >
                            <Grid container sm={12} md={12}
                                direction="row"
                                spacing={1} >
                                <Grid item xs={11} >
                                    <Typography variant="h6" color={'primary'}>
                                        Dados Inquilino
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Nome
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.proposal?.name}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Documento
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {doc.length > 11 ?
                                            `${rows?.proposal?.document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5")}`
                                            :
                                            `${rows?.proposal?.document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4")}`
                                        }
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Nascimento
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {moment(rows?.proposal?.birthDate).format("L")}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Nome da Mãe
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.proposal?.motherName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Relação de trabalho
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {globalTranslate(`${rows?.proposal?.employmentRelationShip}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Estado Civil
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {globalTranslate(`${rows?.proposal?.maritalStatus}`)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} mb="1rem">
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Celular
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskCell(`${rows?.proposal?.phone}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Telefone
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskCell(`${rows?.proposal?.mobilePhone}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Email
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* *********** Locador */}


                    <Grid container sm={12}
                        display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                        mt={2} ml={smDown ? 0 : 2}
                    >
                        <Grid container sm={12}
                            display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            component={Paper}
                            padding={2}
                            spacing={1}
                        >
                            <Grid container item direction="row" spacing={1}>
                                <Grid item xs={8} >
                                    <Typography variant="h6" color={'primary'}>
                                        Dados da Garantia
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid container item direction="row" spacing={1}>
                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
                                        <Typography variant="caption" color={'text.secondary'}>
                                            Origem da analise
                                        </Typography>
                                        <Typography variant="subtitle2" >
                                            {globalTranslate(`${rows?.proposal?.createdFrom}`)}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Endereço
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {`${rows?.proposal?.addressStreet != null && rows?.proposal?.addressStreet != '' ?
                                            `${rows?.proposal?.addressComplement != null && rows?.proposal?.addressComplement != '' ?
                                                `${rows?.proposal?.addressStreet},${rows?.proposal?.addressNumber} - ${rows?.proposal?.addressComplement} -
                                            ${rows?.proposal?.addressDistrict} - ${rows?.proposal?.addressCity} - ${rows?.proposal?.addressState} - ${rows?.proposal?.addressZipcode}`
                                                : `${rows?.proposal?.addressStreet},${rows?.proposal?.addressNumber} - 
                                            ${rows?.proposal?.addressDistrict} - ${rows?.proposal?.addressCity} - ${rows?.proposal?.addressState} - ${rows?.proposal?.addressZipcode}`
                                            } ` : ''
                                            }`
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Valor Aluguel
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskReal(`${rows?.proposal?.rentAmount}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Valor Condomínio
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskReal(`${rows?.proposal?.condominiumAmount}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Valor Energia Elétrica
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskReal(`${rows?.proposal?.electricEnergyAmount}`)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Valor Gas
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskReal(`${rows?.proposal?.gasAmount}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Valor Água
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskReal(`${rows?.proposal?.waterAmount}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Valor IPTU
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskReal(`${rows?.proposal?.iptuAmount}`)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1}>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Parceiro
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.client?.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Email do Parceiro
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.client?.email}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Plano
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {/* {rows?.proposal?.description} */}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Vigência
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {/* {moment(rows?.termBegin).format("L")} a {moment(rows?.expiresAt).format("L")} */}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Meses de Vigência
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.proposal?.rentMonthsCount}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        LMI
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.proposal?.lmi}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Premio de Seguro
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskReal(`${rows?.proposal?.lmiAmount}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Mensalidade
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ color: "#2758f8" }}>
                                        {maskReal(`${rows?.proposal?.assuranceMonthAmount}`)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1}>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Tipo de cobrança
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {globalTranslate(`${rows?.proposal?.paymentMethod}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Tipo de Locação
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {globalTranslate(`${rows?.proposal?.propertyType}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Taxa Aplicada
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.proposal?.propertyType == 'commercial' ?
                                            `  ${rows?.proposal?.commercialFee} %` : `${rows?.proposal?.residentialFee} %`
                                        }
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container sm={12}
                        display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                        mt={2} ml={smDown ? 0 : 2}
                    >
                        <Grid container sm={12}
                            display={"flex"} flexDirection={smDown ? 'column' : 'row'}
                            component={Paper}
                            padding={2}
                            spacing={1}
                        >
                            <Grid container sm={12} md={12}
                                direction="row"
                                spacing={1} >
                                <Grid item xs={11} >
                                    <Typography variant="h6" color={'primary'}>
                                        Dados Locador
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container item direction="row" spacing={1} >
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Documento
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.proposal?.lessorCpf}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Nome
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.proposal?.lessorName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ backgroundColor: "#f0f1f1" }}>
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Nascimento
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {moment(rows?.proposal?.lessorBirthDate).format("L")}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container item direction="row" spacing={1} mb="1rem">
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Celular
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskCell(`${rows?.proposal?.lessorPhone}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Telefone
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {maskCell(`${rows?.proposal?.lessorMobilePhone}`)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                    <Typography variant="caption" color={'text.secondary'}>
                                        Email
                                    </Typography>
                                    <Typography variant="subtitle2" >
                                        {rows?.proposal?.lessorEmail}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

        </LayoutBaseDePagina >
    );
};