import { Divider, Drawer, Icon, List, ListItemButton, ListItemIcon, ListItemText, colors, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import { useAppThemeContext, useDrawerContext } from "../../../context";
import logo from "../../../assets/logo_garantti.png";
import { Environment } from "../../environment";
import { useState } from "react";

interface IListItemLinkProps {
	to: string;
	icon: string;
	label: string;
	onClick: (() => void) | undefined;
}

const ListItemLink: React.FC<IListItemLinkProps> = ({ to, icon, label, onClick }) => {
	const navigate = useNavigate();
	const resolvedPath = useResolvedPath(to);
	const match = useMatch({ path: resolvedPath.pathname, end: false });

	const handleClick = () => {
		navigate(to, { replace: true });
		onClick?.();
	};

	return (
		<ListItemButton selected={!!match} onClick={handleClick}>
			<ListItemIcon sx={{ color: "#666" }}>
				<Icon> {icon}</Icon>
			</ListItemIcon>
			<ListItemText primary={label} />
		</ListItemButton>
	);
};

interface ISidebarProps {
	children: React.ReactNode;
}

export const Sidebar: React.FC<ISidebarProps> = ({ children }) => {
	const theme = useTheme();
	const smDown = useMediaQuery(theme.breakpoints.down("sm"));
	const version = Environment.VERSION;

	const { isDrawerOpen, toggleDrawerOpen, drawerOptions } = useDrawerContext();
	const { toggleTheme } = useAppThemeContext();
	const [open, setOpen] = useState(true);
	const [open2, setOpen2] = useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	const handleClick2 = () => {
		setOpen2(!open2);
	};

	return (
		<>
			{/* <Drawer open={isDrawerOpen} variant={smDown ? 'temporary' : 'permanent'} onClose={toggleDrawerOpen}> */}
			<Drawer open={isDrawerOpen} variant={isDrawerOpen ? "permanent" : "persistent"}>
				<Box width={isDrawerOpen ? theme.spacing(28) : 0} height="100%" display="flex" flexDirection="column">
					<Box width="100%" height={theme.spacing(16)} display="flex" alignItems="center" justifyContent="center" sx={{ background: "#7924c7" }}>
						<img src={logo} alt="Logo" height={"130%"} />
					</Box>

					<Divider />

					<Box
						flex={1}
						sx={{
							color: "#666",
							overflowY: "auto",

							/* Scrollbar - Chrome, Safari, Edge (webkit) */
							"&::-webkit-scrollbar": {
								width: 8, // Largura
							},
							"&::-webkit-scrollbar-track": {
								background: "#f1f1f1",
								borderRadius: 8, // Arredonda o trilho
							},
							"&::-webkit-scrollbar-thumb": {
								backgroundColor: "#c1c1c1",
								borderRadius: 8, // Arredonda o thumb
								backgroundClip: "padding-box",
								border: "2px solid #f1f1f1", // Cria espaço em torno, ressaltando a borda
							},
							"&::-webkit-scrollbar-thumb:hover": {
								backgroundColor: "#b0b0b0",
							},

							/* Scrollbar - Firefox */
							scrollbarWidth: "thin", // Deixa a barra fina
							scrollbarColor: "#c1c1c1 #f1f1f1", // thumb e track (cor do polegar e do trilho)
						}}
					>
						<List component="nav">
							{drawerOptions.map((drawerOption) => (
								<ListItemLink
									key={drawerOption.path}
									icon={drawerOption.icon}
									to={drawerOption.path}
									label={drawerOption.label}
									onClick={smDown ? toggleDrawerOpen : undefined}
								/>
							))}
						</List>
					</Box>

					<Box>
						<List component="nav">
							<ListItemButton onClick={toggleTheme}>
								<ListItemIcon>
									<Icon> dark_mode</Icon>
								</ListItemIcon>
								<ListItemText primary={version} sx={{ color: "gray" }} />
								{/* 2.01a */}
							</ListItemButton>
						</List>
					</Box>
				</Box>
			</Drawer>

			{/* <Box height="100vh" marginLeft={smDown ? 0 : theme.spacing(28)}> */}
			<Box height="100vh" marginLeft={isDrawerOpen ? theme.spacing(28) : 0}>
				{children}
			</Box>
		</>
	);
};
