import {
    Box,
    Button,
    CircularProgress,
    Icon,
    IconButton,
    LinearProgress,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from "@mui/material";
import "moment/locale/pt-br";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useProfile } from "../../../context/ProfileContext";
import { ToastContent } from "../../../shared/components";
import { ToolbarContract } from "../../../shared/components/toolbarContract/ToolBarContract";
import { PermissionActionsEnum, PermissionRolesEnum } from "../../../shared/enums/profiles";
import { Environment } from "../../../shared/environment";
import { checkByProfileGuard, ProfileGuard } from "../../../shared/guards/ProfileGuard";
import { useDebounce, useToast } from "../../../shared/hooks";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import { contractService, IListagemContract, TContractAllPromise } from "../../../shared/services/api/contract/contractService";
import { IMessage } from "../../../shared/types/message";
import { changeColorIcon, globalColorText, translate } from "../../../shared/utils";
import { backgroundColor } from "../../../shared/utils/changeBackgroundColor";
import { formatDateBRHasMinutes } from "../../../shared/utils/format-date";
import { returnMessageError } from "../../../shared/utils/returnMessageError";

export const ListagemContratosPresentation: React.FC = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { debounce } = useDebounce();
	const navigate = useNavigate();
	const [rows, setRows] = useState<IListagemContract[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isLoadingDocument, setIsLoadingDocument] = useState(false);
	const [isLoadingShoot, setIsLoadingShoot] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const toastHandler = useToast();

	const hasViewPermission = useMemo(() => {
		return checkByProfileGuard(PermissionRolesEnum.ClientsOptions, PermissionActionsEnum.Contracts);
	}, []);

	const { registerClient, permissionLevel, isSuperAdmin } = useProfile();

	const pagina = useMemo(() => {
		return Number(searchParams.get("pagina") || "1");
	}, [searchParams]);

	const status = useMemo(() => {
		return searchParams.get("status") || "";
	}, [searchParams]);

	const dataCreate = useMemo(() => {
		return searchParams.get("dataCreate") || "";
	}, [searchParams]);

	const title = useMemo(() => {
		return searchParams.get("title") || "";
	}, [searchParams]);

	const getAllContracts = () => {
		if (hasViewPermission) {
			setIsLoading(true);

			debounce(() => {
				contractService
					.getAll(pagina, title, status, dataCreate)
					.then((result: TContractAllPromise) => {
						setIsLoading(false);

						if (result instanceof Error) {
							const titleMessage = "Alerta!!";
							const contentMessage = "Erro: " + result?.message;

							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						} else {
							setTotalCount(result.total);
							setRows(result.results);
						}
					})
					.catch((err: any) => {
						setIsLoading(false);
						const { titleMessage, contentMessage } = returnMessageError(err);

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					});
			});
		}
	};

	useEffect(() => {
		getAllContracts();
	}, [pagina]);

	const handleViewDocumentContract = (id: string) => {
		setIsLoadingDocument(true);

		debounce(() => {
			contractService
				.getDocumentContract(id)
				.then((result: void | Error) => {
					setIsLoadingDocument(false);

					if (result instanceof Error) {
						const titleMessage = "Alerta!!";
						const contentMessage = "Erro: " + result?.message;

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					}
				})
				.catch((err: any) => {
					setIsLoadingDocument(false);

					const { titleMessage, contentMessage } = returnMessageError(err);

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				});
		});
	};

	const handleShootQueue = (id: string) => {
		setIsLoadingShoot(true);

		debounce(() => {
			contractService
				.shootQueue(id)
				.then((result: IMessage<null> | Error) => {
					setIsLoadingShoot(false);

					if (result instanceof Error) {
						const titleMessage = "Alerta!!";
						const contentMessage = "Erro: " + result?.message;

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					} else {
						toastHandler.present({
							type: "success",
							position: "top-right",
							messageOrContent: <ToastContent title={result.message!} content={result.description!} />,
						});

						getAllContracts();
					}
				})
				.catch((err: any) => {
					setIsLoadingShoot(false);
					const { titleMessage, contentMessage } = returnMessageError(err);

					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				});
		});
	};

	return (
		<LayoutBaseDePagina
			titulo={"Contratos"}
			subtitulo={"Listagem de contratos"}
			barraDeFerramentas={
				<Box display="flex" margin={0} padding={0} flexDirection={"row"} flexWrap={"nowrap"}>
					<ToolbarContract
						sxPaper={{ width: "100%" }}
						textoButtonNewContract="novo"
						onClickNovoContract={() => navigate("/clientes/contratos/detalhe/novo")}
						showCheckBox={false}
						onChangeStatus={(retorno: string) => setSearchParams({ title, pagina: String(pagina), status: retorno, dataCreate }, { replace: true })}
						onChangeDateRegister={(retorno: string) =>
							setSearchParams(
								{
									title,
									pagina: String(pagina),
									status,
									dataCreate: retorno ? retorno : "",
								},
								{ replace: true }
							)
						}
						showInputStatus={true}
						showButtonFind={true}
						showDateRegister={true}
						showStatusContract={true}
						showTitle={true}
						showButtonNewContract={true}
						isLoaddingButtonFind={isLoading}
						onClickButtonFind={getAllContracts}
						onChangeTitle={(retorno: string) => {
							setSearchParams(
								{
									title: retorno,
									pagina: String(pagina),
									status,
									dataCreate,
								},
								{ replace: true }
							);
						}}
					/>
				</Box>
			}
		>
			<ProfileGuard requiredRole={PermissionRolesEnum.ClientsOptions} requiredAction={PermissionActionsEnum.Contracts}>
				<TableContainer
					component={Paper}
					variant="outlined"
					sx={{ marginLeft: 1, marginTop: 1, marginRight: 2, width: "auto", padding: 4 }}
					aria-label="customized table"
				>
					<Table aria-label="a dense table">
						<TableHead>
							<TableRow sx={{ background: "#f5fbfd" }}>
								<TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Titulo</TableCell>
								<TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Data de criação</TableCell>
								<TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Visualizar contrato</TableCell>
								<TableCell sx={{ color: "#7e7777b5", textAlign: "center" }}> Status</TableCell>
								<TableCell colSpan={2} sx={{ color: "#7e7777b5", textAlign: "center" }}>
									{" "}
									Ações
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{!isLoading &&
								rows?.map((row) => (
									<TableRow key={row.id} hover>
										<TableCell sx={{ fontWeight: "normal", textAlign: "center" }}>
											<Typography variant="subtitle2" color={"#959595"} fontWeight={"normal"}>
												{row.title}
											</Typography>
										</TableCell>

										<TableCell sx={{ fontWeight: "normal", textAlign: "center" }}>
											<Typography variant="subtitle2" fontWeight={"normal"}>
												{formatDateBRHasMinutes(row.createdAt)}
											</Typography>
										</TableCell>

										<TableCell sx={{ textAlign: "center" }}>
											<Tooltip title="Visualizar Contrato" placement="top-start">
												{isLoadingDocument ? (
													<CircularProgress size={24} variant="indeterminate" />
												) : (
													<IconButton
														size="small"
														disabled={isLoading}
														onClick={() => handleViewDocumentContract(row.id)}
														sx={{ borderRadius: "18px" }}
													>
														<Icon sx={{ color: "#7924c7", fontWeight: "normal" }}> file_download</Icon>
													</IconButton>
												)}
											</Tooltip>
										</TableCell>

										<TableCell sx={{ textAlign: "center" }}>
											<IconButton
												size="small"
												sx={{
													background: backgroundColor(row.statusContract),
													borderRadius: "18px",
													paddingX: "15px",
													paddingY: "1px",
													marginBottom: "5px",
													"&.MuiButtonBase-root:hover": {
														backgroundColor: backgroundColor(row.statusContract),
													},
													cursor: "inherit",
												}}
											>
												<Icon sx={{ color: changeColorIcon(row.statusContract), fontWeight: "normal" }}>
													{" "}
													noise_control_off_sharp_icon
												</Icon>
												<Typography
													variant="subtitle2"
													marginLeft={1}
													sx={{ color: globalColorText(row.statusContract, true), fontWeight: "normal" }}
												>
													{translate(row.statusContract)}
												</Typography>
											</IconButton>
										</TableCell>

										<TableCell colSpan={2} sx={{ textAlign: "center" }}>
											<ProfileGuard requiredRole={PermissionRolesEnum.ClientsOptions} requiredAction={PermissionActionsEnum.Contracts}>
												<IconButton
													disabled={isLoading}
													size="small"
													onClick={() => navigate(`/clientes/contratos/detalhe/${row.id}`)}
													sx={{ borderRadius: "18px", marginRight: "10px" }}
												>
													<Icon sx={{ color: "#0170B3", fontWeight: "normal" }}> edit</Icon>
													<Typography variant="subtitle2" marginLeft={1} sx={{ color: "#0170B3", fontWeight: "normal" }}>
														Alterar
													</Typography>
												</IconButton>

												{isLoadingShoot ? (
													<CircularProgress size={24} sx={{ marginLeft: "15px", verticalAlign: "middle" }} variant="indeterminate" />
												) : (
													<Button
														sx={{
															fontWeight: "normal",
															borderRadius: "10px",
															width: "25%",
															marginLeft: "15px",
															textTransform: "none",
														}}
														disabled={isLoading || row.statusContract != "active"}
														size="small"
														color="primary"
														disableElevation
														variant="outlined"
														onClick={() => handleShootQueue(row.id)}
														startIcon={<Icon>check</Icon>}
													>
														Disparar
													</Button>
												)}
											</ProfileGuard>
										</TableCell>
									</TableRow>
								))}
						</TableBody>

						{totalCount === 0 && !isLoading && <caption>{Environment.LISTAGEM_VAZIA}</caption>}

						<TableFooter>
							{isLoading && (
								<TableRow>
									<TableCell colSpan={3}>
										<LinearProgress variant="indeterminate" />
									</TableCell>
								</TableRow>
							)}
							{totalCount > 0 && (
								<TableRow>
									<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
										<Pagination
											page={pagina}
											count={Math.ceil(totalCount / Environment.LIMITE_LINHAS)}
											onChange={(_, newPage) =>
												setSearchParams({ title, pagina: newPage.toString(), status, dataCreate }, { replace: true })
											}
										/>
									</TableCell>
									<TableCell colSpan={5} sx={{ borderBottom: "none" }}>
										<Typography variant="body1" sx={{ fontWeight: "normal" }}>
											Exibindo {pagina * Environment.LIMITE_LINHAS - (Environment.LIMITE_LINHAS - 1)}
											{" - "}
											{Environment.LIMITE_LINHAS * (pagina - 1) + Environment.LIMITE_LINHAS * pagina < totalCount
												? Environment.LIMITE_LINHAS * pagina
												: totalCount}{" "}
											de
											<Typography variant="overline" sx={{ fontWeight: "bold", color: "#0170B3", fontSize: "16px" }}>
												{" "}
												{totalCount}{" "}
											</Typography>
											registro(s)
										</Typography>
									</TableCell>
								</TableRow>
							)}
						</TableFooter>
					</Table>
				</TableContainer>
			</ProfileGuard>
		</LayoutBaseDePagina>
	);
};
