import React, { useRef, useState } from "react";
import { Box, Button, CircularProgress, Grid, MenuItem, Paper, Typography } from "@mui/material";
import { Check, Close as CloseIcon } from "@mui/icons-material";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { IVFormErros, useVForm, VForm, VTextField } from "../../../../../shared/forms";
import { IUpdateProposalSuperAdmin, ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";
import * as yup from "yup";
import { IMessage } from "../../../../../shared/types/message";
import { returnMessageError } from "../../../../../shared/utils/returnMessageError";
import { useToast } from "../../../../../shared/hooks";

interface ModalUpdateStatusProps {
	isClose: () => void;
	isOpenModal: boolean;
	rows: any;
	isUpdate: boolean; // ✅ Alterado de `() => void` para `boolean`
}

const ModalUpdateStatusProposal: React.FC<ModalUpdateStatusProps> = ({ isClose, isOpenModal, rows, isUpdate }) => {
	const { formRef: formRefEdit } = useVForm();
	const [isLoadingEditSuper, setIsLoadingEditSuper] = useState(false);
	const [changeStatus, setChangeStatus] = useState("");
	const toastHandler = useToast();

	const formEditValidationSchema: yup.SchemaOf<any> = yup.object().shape({
		id: yup.string().required("Campo Obrigatório"),
		status: yup.string().required("Campo Obrigatório"),
	});

	const handleSaveEdit = (dados: IUpdateProposalSuperAdmin) => {
		dados.id = rows.id;

		formEditValidationSchema
			.validate(dados, { abortEarly: false })
			.then((dadosValidados) => {
				setIsLoadingEditSuper(true);
				ProposalService.updateProposalSuperAdmin(dadosValidados)
					.then((result: IMessage<null> | Error) => {
						setIsLoadingEditSuper(false);
						if (result instanceof Error) {
							const titleMessage = "Alerta!!";
							const contentMessage = "Erro: " + result?.message;
							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						} else {
							const { message, description } = result;
							isClose();
							
							toastHandler.present({
								type: "success",
								position: "top-right",
								messageOrContent: <ToastContent title={message as string} content={description as string} />,
							});
						}
					})
					.catch((err: any) => {
						setIsLoadingEditSuper(false);
						const { titleMessage, contentMessage } = returnMessageError(err);

						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
						});
					});
			})
			.catch((errors: yup.ValidationError) => {
				setIsLoadingEditSuper(false);
				const validationErros: IVFormErros = {};
				errors?.inner?.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRefEdit?.current?.setErrors(validationErros);
			});
	};

	return (
		<ModalContext
			isCloseModal={isClose}
			isOpenModal={isOpenModal}
			messageType={"info"}
			enableBackdropClose={false}
			overrideWidthValue="40%"
			showButtonCancel={false}
			ITitle="Alteração de status da Garantia"
			overrideHeightValue="auto"
			showTitleIcon={false}
			stylePropert={{ display: "contents" }}
			IMessage={[
				<VForm
					id="formIdSaveEdit"
					placeholder="Your placeholder"
					onPointerEnterCapture={() => console.log("Pointer entered")}
					onPointerLeaveCapture={() => console.log("Pointer left")}
					style={{ padding: "0px 30px" }}
					ref={formRefEdit}
					onSubmit={handleSaveEdit}
				>
					<Box margin={1} component={Paper} sx={{ border: "0" }} variant="outlined">
						{rows?.status == "expired" && changeStatus == "active" ? (
							<Grid
								container
								item
								direction={"row"}
								xs={12}
								sx={{
									borderRadius: "5px",
									alignItems: "center",
									display: "flex",
									height: "50px",
									marginLeft: "10px",
									marginTop: "10px",
									maxWidth: "94%",
									width: "94%",
								}}
							>
								<Typography
									variant="subtitle2"
									sx={{
										color: "#a30d12",
										flex: "auto",
										textAlign: "center",
										marginTop: "-10px",
										backgroundColor: "#ffe0e0",
										padding: 1,
										borderRadius: 3,
									}}
								>
									Ao mudar o status de Expirada para Vigente, ao final do dia corrente o sistema vai expirar a garantia novamente caso o fim
									de vigência seja atingido novamente!!
								</Typography>
							</Grid>
						) : (
							""
						)}
						<Box padding={1}>
							<VTextField
								fullWidth
								label="Status"
								name="status"
								sx={{ width: "100%" }}
								disabled={isLoadingEditSuper}
								select
								onChange={(e) => setChangeStatus(e.target.value)}
							>
								<MenuItem value="active">Vigente</MenuItem>
								<MenuItem style={{ display: rows?.status == "expired" ? "none" : "block" }} value="suspended">
									Suspensa
								</MenuItem>
							</VTextField>
						</Box>

						<Box sx={{ display: "flex", flexDirection: "row", gap: 2, paddingY: 4, justifyContent: "center" }}>
							<Button color="inherit" disableElevation variant={"contained"} startIcon={<CloseIcon />} onClick={isClose}>
								Sair
							</Button>
							{isLoadingEditSuper ? (
								<CircularProgress style={{ height: "20px", marginRight: "40px", marginLeft: "10px" }} variant="indeterminate" />
							) : (
								<Button
									color="primary"
									disableElevation
									variant={"contained"}
									startIcon={<Check />}
									onClick={() => formRefEdit?.current?.submitForm()}
								>
									Confirmar
								</Button>
							)}
						</Box>
					</Box>
				</VForm>,
			]}
		/>
	);
};

export default ModalUpdateStatusProposal;
