import { Box, Button } from "@mui/material";
import { CalendarToday, DoneOutline, EditNote, Insights, PowerSettingsNew, Rule } from "@mui/icons-material";
import { format, isValid } from "date-fns";
import React from "react";
import { useToast } from "../../../../../shared/hooks/useToast";
import { ProposalService } from "../../../../../shared/services/api/proposal/ProposalService";
import { ModalContext, ToastContent } from "../../../../../shared/components";
import { ModalConcludeCancelProposal } from "../Cancel/ModalConcludeCancel";
import ModalWaiverCancel from "../Cancel/ModalWaiverCancel";
import { ModalPendencyCancelProposal } from "../Cancel/ModalPendencyCancel";

interface ProposalCanceledProps {
    rows: any;
    permissionLevel: number;
}

interface ProposalCanceledResult {
    canceledCard: {
        title: string;
        rows: {
            fields: {
                label?: string;
                value: any;
                icon?: JSX.Element;
                colSpan?: number;
                type?: "box" | "button";
                boxProps?: {
                    backgroundColor?: string;
                    textColor?: string;
                    padding?: number;
                    borderRadius?: number;
                    marginTop?: number;
                    marginBottom?: number;
                };
                buttonProps?: {
                    variant?: "contained" | "outlined";
                    color?: "primary" | "inherit";
                    disabled?: boolean;
                    startIcon?: JSX.Element;
                };
                onClick?: () => void;
            }[];
        }[];
        showButtons: boolean;
        isPending: boolean;
        showConcludeButton: boolean;
        substatusReason?: string;
    } | null;
    modals: React.ReactNode;
}

export const useProposalCanceled = ({ rows, permissionLevel }: ProposalCanceledProps): ProposalCanceledResult => {
    const [openModalCancelConclude, setOpenModalCancelConclude] = React.useState(false);
    const [openModalCancelWaiver, setOpenModalCancelWaiver] = React.useState(false);
    const [openModalCancelPendency, setOpenModalCancelPendency] = React.useState(false);
    const [openModalRemovePendency, setOpenModalRemovePendency] = React.useState(false);
    const toastHandler = useToast();

    const formatDate = (date?: Date | string) => {
        if (!date) return "N/A";
        const validDate = new Date(date);
        return isValid(validDate) ? format(validDate, "dd/MM/yyyy") : "Data inválida";
    };

    const handleRemoveProposalPendency = async () => {
        try {
            const result = await ProposalService.updatePendencyCancelId(rows.id, { substatus: "notpending", substatusreason: "" });

            if (result instanceof Error) {
                throw new Error(result.message);
            }

            toastHandler.present({
                type: "success",
                position: "top-right",
                messageOrContent: <ToastContent title="Sucesso" content="Cancelamento efetivado com sucesso" />,
            });
            window.location.reload();
        } catch (error: any) {
            console.error("Erro ao remover pendência:", error);
            toastHandler.present({
                type: "error",
                position: "top-right",
                messageOrContent: <ToastContent title="Erro" content="Falha ao remover a pendência. Tente novamente." />,
            });
        } finally {
            setOpenModalRemovePendency(false);
        }
    };

    const pendencyActions = (
        <Box display="flex" alignItems="center" gap={2}>
            <Button color="inherit" variant="contained" onClick={() => setOpenModalRemovePendency(false)}>
                Cancelar
            </Button>
            <Button color="primary" variant="contained" onClick={handleRemoveProposalPendency}>
                Remover Pendência
            </Button>
        </Box>
    );

    if (!rows || (rows.status !== "suspended" && rows.status !== "canceled")) {
        return {
            canceledCard: null,
            modals: null,
        };
    }

    return {
        canceledCard: {
            title: rows.status === "suspended" && rows.substatus === "pending" ? "Cancelamento de Garantia Pendente" : "Dados do Cancelamento",
            rows: [
                {
                    fields: [
                        ...(rows.substatusReason !== "" && rows.substatusReason !== null
                            ? [
                                  {
                                      type: "box" as const,
                                      value: rows.substatusReason,
                                      colSpan: 12,
                                      boxProps: {
                                          backgroundColor: "rgba(255, 0, 0, 0.1)",
                                          textColor: "red",
                                          padding: 2,
                                          borderRadius: 1,
                                          marginTop: 1,
                                          marginBottom: 2,
                                      },
                                  },
                              ]
                            : []),
                        {
                            label: "Solicitação do Cancelamento",
                            value: rows?.canceledOpen ? formatDate(rows.canceledOpen.substring(0, 10) + " 03:00:00") : "N/A",
                            colSpan: 4,
                            icon: <CalendarToday />,
                        },
                        {
                            label: "Cancelamento do Contrato",
                            value: rows?.canceledAt ? formatDate(rows.canceledAt.substring(0, 10) + " 03:00:00") : "N/A",
                            colSpan: 4,
                            icon: <CalendarToday />,
                        },
                        {
                            label: "Efetivação do Cancelamento",
                            value: rows?.canceledConclude ? formatDate(rows.canceledConclude.substring(0, 10) + " 03:00:00") : "N/A",
                            colSpan: 4,
                            icon: <CalendarToday />,
                        },
                        {
                            label: "Motivo",
                            value: rows?.canceledType || "N/A",
                            colSpan: 6,
                            icon: <Insights />,
                        },
                        {
                            label: "Descrição",
                            value: rows?.canceledReason || "N/A",
                            colSpan: 6,
                            icon: <EditNote />,
                        },
                        ...(rows.status === "suspended"
                            ? [
                                  {
                                      label: "Desistir do Cancelamento",
                                      icon: <PowerSettingsNew />,
                                      type: "button" as const,
                                      value: "Cancelar Pendência",
                                      colSpan: 3,
                                      buttonProps: {
                                          variant: "outlined" as const,
                                      },
                                      onClick: () => setOpenModalCancelWaiver(true),
                                  },
                              ]
                            : []),
                        ...(rows.status === "suspended"
                            ? [
                                  {
                                      label: rows.substatus !== "pending" ? "Pendenciar Cancelamento" : "Remover Pendência",
                                      icon: <Rule />,
                                      type: "button" as const,
                                      value: rows.substatus,
                                      colSpan: 3,
                                      buttonProps: {
                                          variant: "outlined" as const,
                                      },
                                      onClick: rows.substatus !== "pending" ? () => setOpenModalCancelPendency(true) : () => setOpenModalRemovePendency(true),
                                  },
                              ]
                            : []),
                        ...(rows.status === "suspended"
                            ? [
                                  {
                                      label: "Concluir Cancelamento",
                                      icon: <DoneOutline />,
                                      type: "button" as const,
                                      value: "Concluir Cancelamento",
                                      colSpan: 3,
                                      buttonProps: {
                                          variant: "contained" as const,
                                          disabled: (rows.status === "suspended" && rows.substatus === "pending") || permissionLevel !== 1,
                                      },
                                      onClick: () => {
                                          console.log("cliquei no cancela");
                                          setOpenModalCancelConclude(true);
                                      },
                                  },
                              ]
                            : []),
                    ],
                },
            ],
            showButtons: rows?.status === "suspended",
            isPending: rows.substatus === "pending",
            showConcludeButton: permissionLevel === 1 && rows.substatus !== "pending",
            substatusReason: rows.substatusReason,
        },
        modals: (
            <>
                {openModalCancelConclude && (
                    <ModalConcludeCancelProposal isClose={() => setOpenModalCancelConclude(false)} isOpenModal={openModalCancelConclude} rows={rows} />
                )}
                {openModalCancelWaiver && <ModalWaiverCancel isClose={() => setOpenModalCancelWaiver(false)} isOpenModal={openModalCancelWaiver} rows={rows} />}
                {openModalCancelPendency && (
                    <ModalPendencyCancelProposal isClose={() => setOpenModalCancelPendency(false)} isOpenModal={openModalCancelPendency} rows={rows} />
                )}
                {openModalRemovePendency && (
                    <ModalContext
                        isCloseModal={() => setOpenModalRemovePendency(false)}
                        isOpenModal={openModalRemovePendency}
                        messageType="question"
                        showButtonCancel={false}
                        showButtonConfirm={false}
                        showTitleIcon={false}
                        enableBackdropClose
                        overrideWidthValue="30%"
                        overrideHeightValue="20%"
                        ITitle="Pendência"
                        IMessage={[pendencyActions]}
                    />
                )}
            </>
        ),
    };
};
