import type { SxProps, Theme } from "@mui/material/styles";

export const ModalStyle: SxProps<Theme> = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	bgcolor: "background.paper",
	border: "1px solid #605e5e",
	boxShadow: 24,
	p: 4,
	borderRadius: "6px",
};

export const ModalContextStyle: SxProps<Theme> = {
	marginTop: 2,
	width: "100%",
	padding: 1,
	maxHeight: 500,
	overflowY: "auto",
	color: "#f1f1h4",

	/* Scrollbar - Chrome, Safari, Edge (webkit) */
	"&::-webkit-scrollbar": {
		width: 8,
	},
	"&::-webkit-scrollbar-track": {
		background: "#f1f1f1",
		borderRadius: 8,
	},
	"&::-webkit-scrollbar-thumb": {
		backgroundColor: "#c1c1c1",
		borderRadius: 8,
		backgroundClip: "padding-box",
		border: "2px solid #f1f1f1",
	},
	"&::-webkit-scrollbar-thumb:hover": {
		backgroundColor: "#b0b0b0",
	},

	/* Scrollbar - Firefox */
	scrollbarWidth: "thin",
	scrollbarColor: "#c1c1c1 #f1f1f1",

	"& > .MuiGrid-root > .MuiGrid-item:first-of-type": {
		marginBottom: 1, // Reduz o espaço abaixo do título da seção
	},
};
