import {
	Box,
	Button,
	ButtonGroup,
	Chip,
	Icon,
	LinearProgress,
	MenuItem,
	Pagination,
	Select,
	type SelectChangeEvent,
	Skeleton,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
} from "@mui/material";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import type React from "react";
import { useMemo } from "react";
import { RiFileExcel2Line } from "react-icons/ri";
import { ToastContent } from "../../../shared/components";
import { ToolbarReports } from "../../../shared/components/TableReports/ToolbarReports";
import { useToast } from "../../../shared/hooks";
import { type IReportCreate, type IReports, reportsServices } from "../../../shared/services/api/reports/reportsServices";
import { formatColorStatusReports, globalColorTextReports, globalTranslateStatusReports } from "../../../shared/utils";
import { LoadingComp } from "../../../shared/components/loading/Loading";

interface ITableReportsProps {
	rows: IReports[];
	totalRows: number;
	page: number;
	limit: number;
	isLoading: boolean;
	showDownload: boolean;
	showView: boolean;
	actionView: () => void;
	actionDownload: (id: string) => void;
	onPageChange: (newPage: number) => void;
	onLimitChange: (newLimit: number) => void;
	onRefresh: () => void;
	idFind?: string;
}

export const TableReportsComission: React.FC<ITableReportsProps> = ({
	rows,
	totalRows,
	page,
	limit,
	isLoading,
	showDownload,
	showView,
	actionView,
	actionDownload,
	onPageChange,
	onLimitChange,
	onRefresh,
	idFind,
}) => {
	const toastHandler = useToast();

	const filteredRows = useMemo(() => {
		if (idFind) {
			return rows.filter((row) => row.file_path.includes(idFind));
		}
		return rows;
	}, [idFind, rows]);

	const handleNewReport = async () => {
		const invoiceId = idFind || ""; // Use idFind como invoiceId, ou uma string vazia se não estiver definido
		const dados: IReportCreate = {
			report_key: "report.comission",
			date_start: new Date().toISOString(),
			date_end: new Date().toISOString(),
			comission_id: invoiceId,
		};

		try {
			isLoading = true;
			const response = await reportsServices.createReport(dados);

			if (response instanceof Error) {
				console.error(response);
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title={"Erro"} content={"Erro ao solicitar relatório"} />,
				});
			} else {
				toastHandler.present({
					type: "success",
					position: "top-right",
					messageOrContent: <ToastContent title={"Confirmação"} content={"Relatório solicitado com sucesso!"} />,
				});

				// Aguarda 1 segundo antes de chamar onRefresh
				setTimeout(() => {
					isLoading = false;
					onRefresh();
				}, 1000);
			}
		} catch (error) {
			console.error(error);
			toastHandler.present({
				type: "error",
				position: "top-right",
				messageOrContent: <ToastContent title={"Erro"} content={"Erro ao solicitar relatório"} />,
			});
		}
		isLoading = false;
	};

	return (
		<>
			<TableContainer sx={{ width: "100%", overflow: "auto" }}>
				<ToolbarReports onclickButtonRefresh={onRefresh} onclickNew={handleNewReport} />

				{isLoading && (
					<Box>
						<Box sx={{ width: "100%" }}>
							<LinearProgress sx={{ height: 6, borderRadius: 10 }} />
						</Box>
						<Stack spacing={1}>
							<Skeleton variant="text" sx={{ fontSize: "2.5rem" }} />
							<Skeleton variant="text" sx={{ fontSize: "2.5rem" }} />
							<Skeleton variant="text" sx={{ fontSize: "2.5rem" }} />
							<Skeleton variant="text" sx={{ fontSize: "2.5rem" }} />
						</Stack>
					</Box>
				)}

				<Table aria-label="a dense table" size="small" sx={{ border: "1px solid #ebebeb" }}>
					<TableHead>
						{!isLoading && (
							<TableRow sx={{ background: "#f5fbfd" }}>
								<TableCell sx={{ color: "#464545" }}> Relatorio</TableCell>
								<TableCell sx={{ color: "#464545" }}> Criação</TableCell>
								<TableCell sx={{ color: "#464545" }}> Usuario</TableCell>
								<TableCell sx={{ color: "#464545" }}> Status </TableCell>
								<TableCell sx={{ color: "#464545" }}> </TableCell>
							</TableRow>
						)}
					</TableHead>
					<TableBody>
						{!isLoading &&
							filteredRows.map((row) => (
								<TableRow key={row.id} hover>
									<TableCell sx={{ fontWeight: "normal" }}>{row.report_name}</TableCell>
									<TableCell>
										<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
											{format(new Date(row.created_at), "dd/MM/yyyy")}
										</Typography>
										<Typography variant="caption" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
											{format(new Date(row.created_at), "HH:mm:ss", { locale: ptBR })}
										</Typography>
									</TableCell>
									<TableCell>
										<Typography variant="subtitle2" color={"#5c5858"} sx={{ fontWeight: "normal" }}>
											{row.user_name}
										</Typography>
									</TableCell>
									<TableCell>
										<Chip
											label={
												new Date(row.created_at) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
													? globalTranslateStatusReports(row.status)
													: globalTranslateStatusReports("expired")
											}
											variant="filled"
											sx={{
												backgroundColor:
													new Date(row.created_at) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
														? globalColorTextReports(row.status)
														: globalColorTextReports("expired"),
												color:
													new Date(row.created_at) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
														? formatColorStatusReports(row.status)
														: formatColorStatusReports("expired"),
											}}
										/>
									</TableCell>
									<TableCell>
										<ButtonGroup>
											{showView && (
												<Tooltip title="Visualizar">
													<Button onClick={actionView} variant="text" color="primary">
														<Icon>center_focus_strong_outlined_icon</Icon>
													</Button>
												</Tooltip>
											)}
											{showDownload && row.status === "available" && (
												<>
													{new Date(row.created_at) > new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) && (
														<Tooltip title="Baixar Excel">
															<Button
																onClick={() => actionDownload(row.id)}
																variant="contained"
																color="secondary"
																sx={{ paddingX: 0, borderRadius: "50%" }}
															>
																<RiFileExcel2Line fontSize={22} color="whrite" />
															</Button>
														</Tooltip>
													)}

													{new Date(row.created_at) < new Date(Date.now() - 7 * 24 * 60 * 60 * 1000) && (
														<Tooltip title="Link Expirado">
															<span>
																<Button
																	onClick={() => {}}
																	variant="outlined"
																	disabled={true}
																	sx={{ paddingX: 0, borderRadius: "50%" }}
																>
																	<Icon>event_busy_outlined_icon</Icon>
																</Button>
															</span>
														</Tooltip>
													)}
												</>
											)}
											{row.status === "in_process" && (
												<Tooltip title="Aguardando">
													<span>
														<Button onClick={() => {}} variant="outlined" disabled={true} sx={{ paddingX: 0, borderRadius: "50%" }}>
															<Icon>downloading_outlined_icon</Icon>
														</Button>
													</span>
												</Tooltip>
											)}
										</ButtonGroup>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
					{totalRows === 0 && !isLoading && <caption>Nenhum registro </caption>}
				</Table>

				{totalRows > 0 && !isLoading && (
					<Box
						sx={{
							border: "1px solid #e2e1e1",
							padding: 1,
							display: "flex",
							justifyContent: "space-between",
							borderTop: "none",
							alignItems: "center",
						}}
					>
						<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
							<Typography variant="body2" sx={{ fontWeight: "normal" }}>
								Registros por página:
							</Typography>
							<Select
								fullWidth
								value={limit.toString()}
								size="small"
								onChange={(event: SelectChangeEvent) => {
									onLimitChange(Number(event.target.value));
								}}
							>
								<MenuItem value={5}>05</MenuItem>
								<MenuItem value={10}>10</MenuItem>
								<MenuItem value={25}>25</MenuItem>
								<MenuItem value={50}>50</MenuItem>
							</Select>
						</Box>
						<Pagination
							page={page}
							count={Math.ceil(totalRows / limit)}
							onChange={(_, newPage) => {
								onPageChange(newPage);
							}}
						/>

						<Typography variant="body2" sx={{ fontWeight: "normal" }}>
							Exibindo {page * limit - (limit - 1)}
							{" - "}
							{limit * (page - 1) + limit * page < totalRows ? limit * page : totalRows} de
							<Typography variant="overline" color={"primary"} sx={{ fontWeight: "bold", fontSize: "16px" }}>
								{" "}
								{totalRows}{" "}
							</Typography>
							registro(s)
						</Typography>
					</Box>
				)}
			</TableContainer>
			<LoadingComp isopen={isLoading} />
		</>
	);
};
