import React from "react";
import { Box, Button, Grid, Icon, Typography } from "@mui/material";
import * as yup from "yup";
import { IVFormErros, useVForm, VForm, VTextField } from "../../../shared/forms";
import { analysisService } from "../../../shared/services/api/analysis/analysisService";
import { ModalContext, ToastContent } from "../../../shared/components";
import { useToast } from "../../../shared/hooks";

interface RevertModalProps {
	isOpen: boolean;
	onClose: () => void;
	row: any;
}

const validationSchema = yup.object().shape({
	obs: yup.string().required("Campo obrigatório"),
});

export const RevertModal: React.FC<RevertModalProps> = ({ isOpen, onClose, row }) => {
	const { formRef } = useVForm();
	const toastHandler = useToast();

	const handleSubmit = (dados: any) => {
		validationSchema
			.validate(dados, { abortEarly: false })
			.then((dadosValidados) => {
				const updateData = {
					id: row.id,
					status: "awaiting_biometria", // Adicione o status necessário aqui
					assuranceMonthAmount: row.assuranceMonthAmount,
					rentAmount: row.rentAmount,
					residentialFee: row.residentialFee,
					lmi: row.lmi,
					commercialFee: row.commercialFee,
					fee: row.fee,
					createdFrom: row.createdFrom,
					obs: "Aprovado por : " + dadosValidados.obs + " - " + new Date().toLocaleString(),
					planlist: row.planlist,
				};
				console.log("updateData", updateData);

				analysisService
					.updateAnalysisSuperAdmin(updateData)
					.then(() => {
						handleUpdateLink(row);
						onClose();
					})
					.catch((error) => {
						console.error("Erro ao reverter a análise:", error);
					});
			})
			.catch((errors: yup.ValidationError) => {
				const validationErros: IVFormErros = {};
				errors.inner.forEach((error) => {
					if (!error.path) return;
					validationErros[error.path] = error.message;
				});
				formRef.current?.setErrors(validationErros);
			});
	};

	const handleUpdateLink = (dados: any) => {
		const updateLink = {
			phone: row.phone,
			email: row.email,
		};
		analysisService
			.updateSendBiometria(row.id, { ...updateLink })
			.then(() => {
				toastHandler.present({
					type: "success",
					position: "top-right",
					messageOrContent: <ToastContent title='Sucesso !' content='Reversão realizada com sucesso !' />,
				});
			})
			.catch((error) => {
				toastHandler.present({
					type: "error",
					position: "top-right",
					messageOrContent: <ToastContent title='Erro !' content='Erro ao processar reversão  !' />,
				});
			});
	};

	return (
		<ModalContext
			isCloseModal={onClose}
			isOpenModal={isOpen}
			messageType={"question"}
			onclickConfirm={() => {}}
			IMessage={[
				<Box display={"flex"} flexDirection={"column"} gap={2} sx={{ width: "100%", textAlign: "center" }}>
					<Typography variant="h5">Tem certeza que deseja reverter a análise?</Typography>
					<Typography variant="subtitle1" sx={{ color: "#7d7d7d" }}>
						Após a reversão, a análise ficará disponível para realizar a Biometria, esta operação não poderá ser desfeita.
					</Typography>
					<VForm
						id="formRevert"
						placeholder="Your placeholder"
						ref={formRef}
						onSubmit={handleSubmit}
						onPointerEnterCapture={""}
						onPointerLeaveCapture={""}
					>
						<Grid container item direction="column" padding={1} spacing={2}>
							<Grid container item direction="row" spacing={2}>
								<Grid item xs={12}>
									<VTextField fullWidth label="Responsável pela autorização" name="obs" required />
								</Grid>
							</Grid>
						</Grid>

						<Grid
							container
							item
							sm={12}
							sx={{
								border: "none",
								display: "flex",
								justifyContent: "center",
							}}
							display={"flex"}
							flexDirection={"row"}
							padding={2}
							gap={2}
						>
							<Button
								color="primary"
								disableElevation
								variant={"contained"}
								startIcon={<Icon>check</Icon>}
								onClick={() => {
									formRef.current?.submitForm();
								}}
							>
								{"Reverter"}
							</Button>
							<Button color="primary" disableElevation variant={"outlined"} startIcon={<Icon>arrow_back</Icon>} onClick={onClose}>
								{"Desistir"}
							</Button>
						</Grid>
					</VForm>
				</Box>,
			]}
		/>
	);
};
