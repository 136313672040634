import { useEffect, useState } from "react";
import { LayoutBaseDePagina } from "../../../shared/layouts";
import TableInvoice from "./TableInvoice";
import { ToolbarComission } from "./ToolbarCommission";
import { useDebounce, useToast } from "../../../shared/hooks";
import { ComissionServices } from "../../../shared/services/api/comission/ComissionServices";
import { ToastContent } from "../../../shared/components";
import { LoadingComp } from "../../../shared/components/loading/Loading";
import { useSearchParams } from "react-router-dom";

export const CommissionListPresentation = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { debounce } = useDebounce();
	const toastHandler = useToast();

	// Estados dos filtros
	const [textSearch, setTextSearch] = useState("");
	const [dataIni, setDataIni] = useState("");
	const [dataFim, setDataFim] = useState("");
	const [status, setStatus] = useState("todos");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [totalCount, setTotalCount] = useState(0);

	// Dados da listagem e controle de loading
	const [comissionData, setComissionData] = useState<any[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [refreshTable, setRefreshTable] = useState(false);

	const handleRefreshTable = () => {
		setRefreshTable((prev) => !prev);
	};

	const handleFetchData = (currentPage: number, limit: number, search: string, ini: string, fim: string, stat: string) => {
		setIsLoading(true);
		debounce(() => {
			ComissionServices.getComission(currentPage + 1, limit, search, ini, fim, stat)
				.then((response) => {
					if (response instanceof Error) {
						toastHandler.present({
							type: "error",
							position: "top-right",
							messageOrContent: <ToastContent title="Erro" content="Erro ao buscar comissões" />,
						});
					} else {
						// Aqui vem data e totalCount
						setComissionData(response.data);
						setTotalCount(response.totalCount ?? 0);
					}
				})
				.finally(() => setIsLoading(false));
		});
	};

	useEffect(() => {
		const paramText = searchParams.get("textSearch") ?? "";
		const paramDataIni = searchParams.get("dataIni") ?? new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString().split("T")[0];
		const paramDataFim = searchParams.get("dataFim") ?? new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().split("T")[0];
		const paramStatus = searchParams.get("status") ?? "todos";

		// Atualiza estados locais
		setTextSearch(paramText);
		setDataIni(paramDataIni);
		setDataFim(paramDataFim);
		setStatus(paramStatus);

		// Chama a busca passando page e rowsPerPage
		handleFetchData(page, rowsPerPage, paramText, paramDataIni, paramDataFim, paramStatus);
	}, [searchParams, page, rowsPerPage, refreshTable]);

	const handleChangePage = (newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (newRowsPerPage: number) => {
		setRowsPerPage(newRowsPerPage);
		setPage(0); // Se a primeira página for "1". Se for zero-based, use setPage(0)
	};

	const handleSearch = () => {
		setPage(0);
		const params: any = {};
		// Monta só se não estiver vazio (pra não poluir a URL)
		if (textSearch) params.textSearch = textSearch;
		if (dataIni) params.dataIni = dataIni;
		if (dataFim) params.dataFim = dataFim;
		if (status && status !== "todos") params.status = status;
		setSearchParams(params);
	};

	return (
		<LayoutBaseDePagina
			titulo="Premiações /Bonificações"
			subtitulo="Controle de Premiações / Bonificações"
			barraDeFerramentas={
				<ToolbarComission
					textSearch={textSearch}
					onChangeTextSearch={(valor) => setTextSearch(valor)}
					onClearTextSearch={() => setTextSearch("")}
					showInputSearch
					showPeriodo
					onChangeDataIni={setDataIni}
					onChangeDataFim={setDataFim}
					showSelectStatus
					onChangeSelectStatus={setStatus}
					showButtonRefresh
					onClickButtonRefresh={handleSearch}
				/>
			}
		>
			<LoadingComp isopen={isLoading} />

			<TableInvoice
				rows={comissionData}
				page={page}
				rowsPerPage={rowsPerPage}
				totalCount={totalCount}
				onChangePage={handleChangePage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
				onRefreshTable={handleRefreshTable} // Função para atualizar a tabela
			/>
		</LayoutBaseDePagina>
	);
};
