import { Autocomplete, Box, Button, Card, CardActions, CardContent, Grid, Icon, MenuItem, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useToast } from "../../../shared/hooks";
import { useDebounce } from "../../hooks";
import { contractService } from "../../services/api/contract/contractService";
import { IReportCreate, reportsServices } from "../../services/api/reports/reportsServices";
import { ModalContext } from "../VModalContext/VModalContext";
import { ToastContent } from "../ToastContent";

interface IRequestReport {
	isCloseModal: () => void;
	onclickCloseModal: () => void;
	isOpenModal: boolean;
	isRefresh?: () => void;
}

type TAutoCompletContract = {
	id: string;
	label: string;
};

export const ModalRequestReport: React.FC<IRequestReport> = ({ isCloseModal, onclickCloseModal, isOpenModal, isRefresh }) => {
	const [reportType, setReportType] = useState("report.analytics");
	const getFirstDayOfMonth = (): string => {
		const currentDate = new Date();
		const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
		return firstDay.toISOString().split("T")[0];
	};

	const [reportBegin, setReportBegin] = useState<string>(getFirstDayOfMonth());
	const [reportEnd, setReportEnd] = useState(new Date().toISOString().split("T")[0]);
	const toastHandler = useToast();

	const [options, setOptions] = useState<TAutoCompletContract[]>([]);
	const { debounce } = useDebounce();
	const [isLoading, setIsLoading] = useState(true);
	const [searchText, setSearchText] = useState("");
	const [selectedId, setSelectedId] = useState<string | undefined>(undefined);

	useEffect(() => {
		debounce(() => {
			contractService.getAll(1, searchText, "", "").then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
				} else {
					setOptions(result.results.map((contract) => ({ id: contract.id, label: contract.title })));
				}
			});
		});
	}, []);

	const autoCompletSelectedOption = useMemo(() => {
		if (!selectedId) return null;
		const selectedOption = options.find((opcao) => opcao.id === selectedId);
		if (!selectedOption) return null;
		return selectedOption;
	}, [selectedId, options]);

	const reports = [
		{ value: "report.analytics", label: "Análises" },
		{ value: "report.proposal", label: "Garantias" },
		{ value: "report.setup", label: "Taxa Setup" },
		{ value: "report.serasa", label: "Análises Serasa" },
		{ value: "report.contract", label: "Contratos" },
		{ value: "report.financial_billet", label: "Financeiro Boletos" },
		{ value: "report.sinister", label: "Relatorio de sinistros" },
		{ value: "report.nfse", label: "Notas Fiscais" },
		{ value: "report.adjustment", label: "Relatorio de reajustes" },
		{ value: "report.comissionList", label: "Relatorio de Premiação/Bonificação" },
	];

	const handleReportCreate = async (dados: IReportCreate) => {
		//console.log(dados);
		reportsServices.createReport(dados).then((response) => {
			if (response instanceof Error) {
				console.error(response);
				if (toastHandler) {
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={"Erro"} content={"Erro ao solicitar relatório"} />,
					});
				}
			} else {
				toastHandler.present({
					type: "success",
					position: "top-right",
					messageOrContent: <ToastContent title={"Confirmação"} content={"Relatorio Solicitado com sucesso !"} />,
				});
			}
		});
	};

	return (
		<ModalContext
			messageType="confirm"
			isOpenModal={isOpenModal}
			ITitle="Solicitar Relatório"
			isCloseModal={isCloseModal}
			showTitleIcon={false}
			overrideHeightValue="350px"
			IMessage={[
				<Card sx={{ width: "100%", justifyContent: "center", padding: 2 }} elevation={0}>
					<CardContent sx={{ display: "flex", gap: 1, justifyContent: "center", width: "100%" }}>
						<TextField
							fullWidth
							label="Tipo de Relatório"
							name="typeReport"
							disabled={false}
							size="small"
							select
							InputLabelProps={{ shrink: true, required: true }}
							defaultValue={reportType}
							onChange={(e) => setReportType(e.target.value)}
						>
							{reports.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</TextField>

						<TextField
							fullWidth
							label="Data Inicial"
							name="reportBegin"
							disabled={false}
							size="small"
							type="date"
							InputLabelProps={{ shrink: true, required: true }}
							defaultValue={reportBegin}
							onChange={(e) => {
								setReportBegin(e.target.value);
							}}
						></TextField>

						<TextField
							fullWidth
							label="Data final"
							name="repostEnd"
							disabled={false}
							size="small"
							type="date"
							InputLabelProps={{ shrink: true, required: true }}
							defaultValue={reportEnd}
							onChange={(e) => {
								setReportEnd(e.target.value);
							}}
						></TextField>

						{reportType === "report.contract" && (
							<Box width={"100%"} minWidth={"200px"}>
								<Autocomplete
									options={options}
									openText="Abrir"
									closeText="Fechar"
									noOptionsText="Nenhuma opção"
									loadingText="Loading ..."
									clearText="Limpar"
									disablePortal
									loading={isLoading}
									value={autoCompletSelectedOption}
									onInputChange={(_, newValue) => setSearchText(newValue)}
									onChange={(_, newValue) => {
										setSelectedId(newValue?.id);
										setSearchText("");
									}}
									renderInput={(params) => <TextField {...params} size="small" label="Contrato" />}
								/>
							</Box>
						)}
					</CardContent>
					<CardActions>
						<Grid container item direction="row" gap={2} justifyContent={"center"}>
							<Button
								color="inherit"
								disableElevation
								variant={"contained"}
								startIcon={<Icon> close</Icon>}
								onClick={() => {
									onclickCloseModal();
								}}
							>
								{"Fechar"}
							</Button>
							<Button
								color="primary"
								disableElevation
								variant={"contained"}
								startIcon={<Icon> check</Icon>}
								onClick={() => {
									{
										handleReportCreate({
											report_key: reportType,
											date_start: reportBegin,
											date_end: reportEnd,
											manager_id: selectedId,
										});
										//onclickCloseModal();
									}
								}}
							>
								{"Solicitar"}
							</Button>
						</Grid>
					</CardActions>
				</Card>,
			]}
		/>
	);
};
