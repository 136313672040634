import type React from "react";
import { useState, useEffect } from "react";
import { Box, DialogContent, DialogTitle, Icon, IconButton, Modal, Typography, type TypographyPropsVariantOverrides } from "@mui/material";
import type { OverridableStringUnion } from "@mui/types";
import type { Variant } from "@mui/material/styles/createTypography";
import { TableReportsComission } from "./TableReportComission";
import { reportsServices, type IReports } from "../../../shared/services/api/reports/reportsServices";
import { useToast } from "../../../shared/hooks";
import { ToastContent } from "../../../shared/components";

interface IModalReportComissionProps {
	isOpenModal: boolean;
	onClose: () => void;
	variant?: OverridableStringUnion<Variant | "inherit", TypographyPropsVariantOverrides>;
	enableBackdropClose?: boolean;
	overrideWidthValue?: string;
	overrideHeightValue?: string;
	stylePropert?: React.CSSProperties;
	maxWidth?: string;
	maxHeight?: string;
	width?: string;
	onclickConfirm?: () => void;
	idFind?: string;
}

export const ModalReportComission: React.FC<IModalReportComissionProps> = ({
	isOpenModal,
	onClose,
	enableBackdropClose,
	maxWidth,
	maxHeight,
	width,
	overrideWidthValue,
	overrideHeightValue,
	variant = "h4",
	idFind,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const [rows, setRows] = useState<IReports[]>([]);
	const [totalRows, setTotalRows] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(5);
	const toastHandler = useToast();

	const style = {
		position: "relative",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: width ? width : "70%",
		height: "auto",
		maxWidth: maxWidth ? maxWidth : "60%",
		maxHeight: maxHeight ? maxHeight : "80%",
		bgcolor: "background.paper",
		border: "1px solid #605e5e",
		boxShadow: 24,
		p: 3,
		borderRadius: "6px",
		display: "flex",
		flexDirection: "column",
		backgroundColor: "#FFF",
	};

	const fetchData = () => {
		setIsLoading(true);
		reportsServices
			.getFindFilterReports("commission_report", page, limit)
			.then((result) => {
				setIsLoading(false);
				if (result instanceof Error) {
					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				} else {
					setRows(result.data);
					setTotalRows(result.total);
				}
			})
			.catch((error) => {
				console.error(error);
				setIsLoading(false);
			});
	};

	useEffect(() => {
		if (isOpenModal) {
			fetchData();
		}
	}, [isOpenModal, page, limit, idFind]); // Added idFind to dependencies

	const handlePageChange = (newPage: number) => {
		setPage(newPage);
	};

	const handleLimitChange = (newLimit: number) => {
		setLimit(newLimit);
		setPage(1);
	};

	const handleDownload = (id: string) => {
		reportsServices
			.getDownloadReport(id)
			.then((result) => {
				if (result instanceof Error) {
					const titleMessage = "Alerta!!";
					const contentMessage = "Erro: " + result?.message;
					toastHandler.present({
						type: "error",
						position: "top-right",
						messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
					});
				} else {
					window.open(result.url, "_blank");
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<Modal
			open={isOpenModal}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			onClose={enableBackdropClose ? onClose : () => {}}
		>
			<Box
				sx={{
					...style,
					width: overrideWidthValue ? overrideWidthValue : style.width,
					height: overrideHeightValue ? overrideHeightValue : style.height,
				}}
			>
				<DialogTitle style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
					<Box style={{ display: "flex", flexDirection: "row", justifyContent: "center", gap: 10 }}>
						<Typography id="modal-modal-title" variant={variant} sx={{ fontWeight: 300 }} color={"primary"}>
							Relatórios de Premiação
						</Typography>
						<IconButton color="primary" onClick={onClose} sx={{ top: 20, right: 20, position: "absolute" }}>
							<Icon sx={{ fontSize: 30 }}> close_Outlined_Icon</Icon>
						</IconButton>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Typography> Faturamento: {idFind} </Typography>
					<TableReportsComission
						rows={rows}
						totalRows={totalRows}
						page={page}
						limit={limit}
						isLoading={isLoading}
						showDownload={true}
						showView={false}
						actionView={() => {}}
						actionDownload={handleDownload}
						onPageChange={handlePageChange}
						onLimitChange={handleLimitChange}
						onRefresh={fetchData}
						idFind={idFind}
					/>
				</DialogContent>
			</Box>
		</Modal>
	);
};
