import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { differenceInDays } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../../../../context";
import { useProfile } from "../../../../context/ProfileContext";
import { ModalContext, ToastContent, ToobarProposalDetails } from "../../../../shared/components";
import { useVForm } from "../../../../shared/forms";
import { useToast } from "../../../../shared/hooks";
import { LayoutBaseDePagina } from "../../../../shared/layouts";
import { clientService } from "../../../../shared/services/api/client/clientService";
import { IProposalList, ProposalService } from "../../../../shared/services/api/proposal/ProposalService";
import { IDetalheUsuario, userService } from "../../../../shared/services/api/user/userService";
import { globalMaskDocument } from "../../../../shared/utils";
import { returnMessageError } from "../../../../shared/utils/returnMessageError";
import { ModalCancelProposal } from "./Cancel/ModalCancel";
import { ProposalFilesModalContent } from "./components";
import { ProposalCancellingFollowupModalContent } from "./components/ProposalCancellingFollowupModalContent";
import ProposalLogs from "./components/ProposalLogs";
import { ModalFinancialLaunch } from "./Modals/ModalFinancialLaunch";
import ModalInstallments from "./Modals/ModalInstallments";
import ProposalCardData from "./ProposalCardData";
import ModalTypeSinister from "./Sinister/ModalTypeSinister";
import { canSinisterRule } from "../../helps/proposalHelpers";

export const ProposalDetailPresentation: React.FC = () => {
    const { id = "novo" } = useParams<"id">();
    const navigate = useNavigate();
    const { formRef: formRefEdit } = useVForm();
    const [isLoading, setIsLoading] = useState(false);
    const [rows, setRows] = useState<IProposalList>();
    const [user, setUser] = useState<IDetalheUsuario | undefined>();
    const [modalStates, setModalStates] = useState({
        openTypeProperty: false,
        openInstallment: false,
        openFiles: false,
        openCancel: false,
        openFollowup: false,
        openRenovate: false,
        openFinancial: false,
    });

    const [isSinisterFinalizedByProposal, setIsSinisterFinalizedByProposal] = useState(false);
    const { permissionLevel, isSuperAdmin } = useProfile();
    const { loggedUser } = useAuthContext();
    const theme = useTheme();
    const smDown = useMediaQuery(theme.breakpoints.down("sm"));
    const toastHandler = useToast();

    const handleToggleModal = useCallback((modal: keyof typeof modalStates, isOpen: boolean) => {
        setModalStates((prev) => ({ ...prev, [modal]: isOpen }));
    }, []);

    const handleOpenModalEndorsement = () => {
        ProposalService.getEndorsement(id);
    };

    const canRenewRule = useCallback(() => {
        const hasSinister = rows?.sinister && rows?.sinister?.length > 0 ? true : false;

        // Regra: Não poderá renovar se estiver em renovação
        //if (rows?.renovatedId) return false;

        // Regra: Se houver sinistro, não é possível renovação.
        if (hasSinister && !isSinisterFinalizedByProposal) return false;

        //se ja foi renovada nao pode renovar novamente
        if (rows && rows.renovateAt) return false;

        const isRenewActive = rows?.status === "active" && !rows?.renovateAt;
        const isRenewExpired = rows?.status === "expired";

        //console.log('672...', rows?.expiresAt, differenceInDays(new Date(rows?.expiresAt as string), new Date()), isRenewActive, isRenewExpired)
        // Regra: Só pode renovar quando o vencimento for menor que 30 dias da data atual.
        const dif = differenceInDays(new Date(rows?.expiresAt as string), new Date());

        if ((isRenewActive && dif >= 0 && dif < 30) || (isRenewExpired && dif >= -30 && dif <= 0)) return true;

        return false; //isRenewExpired;
    }, [rows, isSinisterFinalizedByProposal]);

    const handleContract = useCallback(async () => {
        setIsLoading(true);
        try {
            const result = await ProposalService.getContract(id);
            if (result instanceof Error) throw result;
        } catch (error) {
            toastHandler.present({
                type: "error",
                position: "top-right",
                messageOrContent: (
                    <ToastContent
                        title={returnMessageError(error).titleMessage}
                        content={returnMessageError(error).contentMessage}
                    />
                ),
            });
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    const fetchProposalDetails = useCallback(async () => {
        setIsLoading(true);
        try {
            const result = await ProposalService.getById(id);
            if (result instanceof Error) throw result;
            setRows(result);
            formRefEdit?.current?.setData({ status: result.status });

            let fetchedUser: IDetalheUsuario = {
                id: "N/D",
                name: "N/D",
                email: "N/D",
                phone: "N/D",
            };
            if (result.createdFrom === "web_app" && result.userId) {
                const userResponse = await userService.getByID(result.userId);
                if (!(userResponse instanceof Error)) fetchedUser = userResponse;
            } else if (result.createdFrom === "web_service" && result.clientId) {
                const clientResponse = await clientService.getPartners(result.clientId);
                if (
                    !(clientResponse instanceof Error) &&
                    Array.isArray(clientResponse.data) &&
                    clientResponse.data.length > 0
                ) {
                    const clientData = clientResponse.data[0];
                    fetchedUser = {
                        id: clientData.id ?? "N/D",
                        name: clientData.name,
                        email: clientData.email ?? "N/D",
                        phone: clientData.phone ?? "N/D",
                    };
                }
            }
            setUser(fetchedUser);
        } catch (error) {
            toastHandler.present({
                type: "error",
                position: "top-right",
                messageOrContent: (
                    <ToastContent
                        title={returnMessageError(error).titleMessage}
                        content={returnMessageError(error).contentMessage}
                    />
                ),
            });
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        if (id !== "novo") fetchProposalDetails();
    }, [id]);

    useEffect(() => {
        if (!rows || permissionLevel == null || isSuperAdmin == null) return;

        const canShowSinisterButton = canSinisterRule(rows, permissionLevel, isSuperAdmin);
        setIsSinisterFinalizedByProposal(canShowSinisterButton);
    }, [rows, permissionLevel, isSuperAdmin]);

    return (
        <LayoutBaseDePagina
            titulo="Garantias"
            subtitulo={`Detalhes da Garantia [ ${rows?.name ?? "N/D"} - ${globalMaskDocument(rows?.document ?? "")} ]`}
            barraDeFerramentas={
                <ToobarProposalDetails
                    isExpired={rows?.status === "expired"}
                    clickBack={() => navigate("/garantias")}
                    clickSinister={() => handleToggleModal("openTypeProperty", true)}
                    clickInstallment={() => handleToggleModal("openInstallment", true)}
                    clickEndorsement={handleOpenModalEndorsement}
                    clickCertificate={() => ProposalService.getCertificate(id)}
                    clickFinancial={() => handleToggleModal("openFinancial", true)}
                    clickFiles={() => handleToggleModal("openFiles", true)}
                    clickCancel={() => handleToggleModal("openCancel", true)}
                    clickRenovate={() => handleToggleModal("openRenovate", true)}
                    clickEdit={() => navigate(`/garantias/alterar/${id}`)}
                    clickFollowup={() => handleToggleModal("openFollowup", true)}
                    clickContract={handleContract}
                    showButtonFinancial={permissionLevel === 1}
                    showButtonEndorsement={rows?.status == "canceled" ? true : false}
                    showButtonSinister={isSinisterFinalizedByProposal}
                    showButtonRenovate={canRenewRule()}
                    showButtonContract={rows?.createdFrom !== "web_service"}
                    showButtonCancellingFollowup={["suspended", "canceled"].includes(rows?.status ?? "")}
                    showButtonCancel={["active", "awaiting_signature", "debts_confession"].includes(rows?.status ?? "")}
                    showButtonEdit={permissionLevel === 1}
                />
            }
        >
            {modalStates.openInstallment && (
                <ModalInstallments
                    isSuperAdmin={isSuperAdmin}
                    rows={rows}
                    isClose={() => handleToggleModal("openInstallment", false)}
                    isOpenModal={modalStates.openInstallment}
                />
            )}
            {modalStates.openFinancial && (
                <ModalFinancialLaunch
                    isClose={() => handleToggleModal("openFinancial", false)}
                    isOpenModal={modalStates.openFinancial}
                    isSuperAdmin={isSuperAdmin}
                    rows={rows}
                    isUpdate={() => handleToggleModal("openInstallment", true)}
                />
            )}
            {modalStates.openTypeProperty && (
                <ModalTypeSinister
                    isClose={() => handleToggleModal("openTypeProperty", false)}
                    isOpenModal={modalStates.openTypeProperty}
                    rows={rows}
                />
            )}
            {modalStates.openCancel && (
                <ModalCancelProposal
                    isClose={() => handleToggleModal("openCancel", false)}
                    isOpenModal={modalStates.openCancel}
                    rows={rows}
                />
            )}
            {modalStates.openFiles && (
                <ModalContext
                    isCloseModal={() => handleToggleModal("openFiles", false)}
                    isOpenModal={modalStates.openFiles}
                    messageType="question"
                    showButtonCancel={false}
                    showButtonConfirm={false}
                    showTitleIcon={false}
                    enableBackdropClose={true}
                    overrideWidthValue="45%"
                    ITitle="Arquivos"
                    IMessage={[<ProposalFilesModalContent proposalId={id} />]}
                />
            )}
            {modalStates.openFollowup && (
                <ModalContext
                    isCloseModal={() => handleToggleModal("openFollowup", false)}
                    isOpenModal={modalStates.openFollowup}
                    messageType="question"
                    showButtonCancel={false}
                    showButtonConfirm={false}
                    showTitleIcon={false}
                    enableBackdropClose={true}
                    overrideWidthValue="45%"
                    ITitle="Followup de cancelamento"
                    IMessage={[<ProposalCancellingFollowupModalContent proposalId={id} />]}
                />
            )}

            {modalStates.openRenovate && (
                <ModalContext
                    isCloseModal={() => handleToggleModal("openRenovate", false)}
                    isOpenModal={modalStates.openRenovate}
                    messageType="question"
                    showButtonCancel={true}
                    showButtonConfirm={true}
                    textButtonCancel="Cancelar"
                    textButtonConfirm="Renovar"
                    onclickConfirm={() => navigate(`/garantias/renovar/${id}`)}
                    showTitleIcon={false}
                    enableBackdropClose={true}
                    overrideWidthValue="45%"
                    ITitle="Confirma a renovação da garantia"
                    IMessage={[""]}
                />
            )}
            <Grid container gap={1} sx={{ width: "100%", maxWidth: "1300px" }}>
                {rows && <ProposalCardData row={rows} />}

                <ProposalLogs
                    isSuperAdmin={isSuperAdmin}
                    loggedUser={loggedUser ?? undefined}
                    smDown={smDown}
                    rows={rows}
                />
            </Grid>
        </LayoutBaseDePagina>
    );
};
