import {
    ArticleOutlined,
    AttachMoneyOutlined,
    Business,
    CalendarToday,
    CorporateFareOutlined,
    CreditCard,
    DateRange,
    ElectricBoltOutlined,
    Email,
    Face4,
    Home,
    ImageSearch,
    LocationOn,
    Money,
    Person,
    Phone,
    Portrait,
    PropaneTankOutlined,
    PublishedWithChangesOutlined,
    ReceiptOutlined,
    Security,
    WaterDropOutlined,
    Work,
} from "@mui/icons-material";
import {
    Box,
    Button,
    ButtonProps,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import { differenceInDays, differenceInYears } from "date-fns";
import { FC } from "react";
import InfoItemWithCopy from "../../../../shared/components/InfoItemWithCopy/InfoItemWithCopy";
import {
    globalbackgroundProposal,
    globalColorTextProposal,
    globalFormatCurrencyBRL,
    globalFormatDates,
    globalMaskDocument,
    globalTranslate,
    globalTranslateFinally,
    globalTranslateMethodPayment,
    globalTranslateProfession,
    globalTranslateStatus,
    maskPhone,
} from "../../../../shared/utils";
import ProposalActions from "./components/ProposalActions";
import { useProposalCanceled } from "./components/ProposalCanceledCard";
import { useProposalRenovated } from "./components/ProposalRenovatedCard";
import { useProposalSinister } from "./components/ProposalSinisterCard";
import { useProposalUserData } from "./ProposalUserData";
import { useProposalContext } from "../../../../context/ProposalContext";

interface FieldConfig {
    label?: string;
    value: any;
    icon?: JSX.Element;
    image?: string;
    valueFormatter?: (value: string) => string;
    additionalInfo?: string;
    colSpan?: number;
    hidden?: boolean;
    type?: "button" | "box";
    onClick?: () => void;
    buttonProps?: Partial<ButtonProps>;
    boxProps?: {
        backgroundColor?: string;
        textColor?: string;
        padding?: number;
        borderRadius?: number;
        marginTop?: number;
        marginBottom?: number;
    };
}

interface RowConfig {
    fields: FieldConfig[];
}

interface SectionConfig {
    title: string;
    rows: RowConfig[];
}

interface ProposalCardDataProps {
    row: Record<string, any>;
}

const ProposalCardData: FC<ProposalCardDataProps> = ({
    row,
}) => {
    const { permissionLevel } = useProposalContext(); // ✅ Pegando diretamente do contexto
    const { user } = useProposalUserData({ rows: row });
    const { renovateProposal, handleNavigateToRenovated } =
        useProposalRenovated({ rows: row });
    const { sinisterCard } = useProposalSinister({ rows: row });
    const { canceledCard, modals } = useProposalCanceled({
        rows: row,
        permissionLevel: permissionLevel ?? 1000,
    });
    const sections: SectionConfig[] = [
        ...(sinisterCard
            ? [{ title: sinisterCard.title, rows: sinisterCard.rows }]
            : []),

        ...(canceledCard
            ? [{ title: canceledCard.title, rows: canceledCard.rows }]
            : []),

        {
            title: "Dados do Locatário",
            rows: [
                {
                    fields: [
                        { label: "Nome", value: row.name, icon: <Person /> },
                        {
                            label: "CPF",
                            value: row.document,
                            icon: <Portrait />,
                            valueFormatter: globalMaskDocument,
                        },
                    ],
                },
                {
                    fields: [
                        {
                            label: "Nascimento",
                            value: row.birthDate
                                ? row.birthDate?.substring(0, 10) + " 03:00:00"
                                : undefined,
                            icon: <CalendarToday />,
                            additionalInfo: row.birthDate
                                ? !isNaN(
                                      differenceInYears(
                                          new Date(),
                                          new Date(
                                              row.birthDate?.substring(0, 10) +
                                                  " 03:00:00"
                                          )
                                      )
                                  )
                                    ? `(${differenceInYears(
                                          new Date(),
                                          new Date(
                                              row.birthDate?.substring(0, 10) +
                                                  " 03:00:00"
                                          )
                                      )} anos)`
                                    : "Não Informado"
                                : "Não Informado",
                            valueFormatter: globalFormatDates,
                        },
                        {
                            label: "Nome da Mãe",
                            value: row.motherName,
                            icon: <Face4 />,
                        },
                    ],
                },
                {
                    fields: [
                        {
                            label: "Vínculo Empregatício",
                            value: row.employmentRelationShip,
                            icon: <Work />,
                            valueFormatter: globalTranslateProfession,
                        },
                    ],
                },
            ],
        },
        {
            title: "Contato",
            rows: [
                {
                    fields: [
                        { label: "Email", value: row.email, icon: <Email /> },
                        {
                            label: "Telefone",
                            value: row.phone,
                            icon: <Phone />,
                            valueFormatter: maskPhone,
                        },
                    ],
                },
            ],
        },
        {
            title: "Informações da Garantia",
            rows: [
                {
                    fields: [
                        {
                            label: "Tipo de Imóvel",
                            value: row.propertyType,
                            icon: <Home />,
                        },
                        {
                            label: "Finalidade",
                            value: row.propertyFinally,
                            icon: <Home />,
                            valueFormatter: (value: string) =>
                                globalTranslateFinally(value).toString(),
                        },
                    ],
                },
                {
                    fields: [
                        {
                            label: "Método de Pagamento",
                            value: row.paymentMethod,
                            icon: <CreditCard />,
                            valueFormatter: (value: string) =>
                                globalTranslateMethodPayment(value).toString(),
                        },
                        {
                            label: "Período da Garantia",
                            value: `${globalFormatDates(
                                row.termBegin && !row.termBegin?.includes("NaN")
                                    ? new Date(
                                          row.termBegin?.substring(0, 10) +
                                              " 03:00:00"
                                      ).toISOString()
                                    : ""
                            )}  
							a ${globalFormatDates(
                                row.expiresAt && !row.expiresAt?.includes("NaN")
                                    ? new Date(
                                          row.expiresAt?.substring(0, 10) +
                                              " 03:00:00"
                                      ).toISOString()
                                    : ""
                            )}`,
                            icon: <DateRange />,
                            additionalInfo: row.rentMonthsCount
                                ? `(${row.rentMonthsCount} meses)`
                                : "",
                        },
                    ],
                },
                {
                    fields: [
                        {
                            label: "Endereço",
                            value: `${row.addressStreet}, ${row.addressNumber} - ${row.addressComplement} - ${row.addressDistrict} - ${row.addressCity} - ${row.addressState} - ${row.addressZipcode}`,
                            icon: <LocationOn />,
                            colSpan: 12,
                        },
                        {
                            label: "Parceiro / Imobiliária",
                            value: row.client?.name,
                            icon: <Business />,
                            colSpan: 6,
                        },
                        {
                            label: "Cadastro via",
                            value: globalTranslate(row?.createdFrom),
                            image:
                                row?.createdFrom === "web_service"
                                    ? "/4seg.png"
                                    : "/logogar.png",
                            colSpan: 4,
                        },
                        {
                            label: "Certificado",
                            value: row.certificateNumber,
                            icon: <Security />,
                        },
                        {
                            label: "Plano",
                            value: row.plan.description,
                            icon: <ArticleOutlined />,
                            additionalInfo: `(${row.fee} %)`,
                        },
                        {
                            label: "Mensalidade",
                            value: row.assuranceMonthAmount,
                            icon: <AttachMoneyOutlined />,
                            valueFormatter: (value: string) =>
                                globalFormatCurrencyBRL(value).toString(),
                        },
                        {
                            label: "Premio total",
                            value: row.assuranceMonthAmount * row.rentMonthsCount,
                            icon: <AttachMoneyOutlined />,
                            valueFormatter: (value: string) =>
                                globalFormatCurrencyBRL(value).toString(),
                        },
                        {
                            label: "LMI",
                            value: row.lmiAmount,
                            icon: <Money />,
                            valueFormatter: (value: string) =>
                                globalFormatCurrencyBRL(value).toString(),
                            additionalInfo: row.lmi ? `(${row.lmi}x)` : "",
                        },
                        {
                            label: "Contrato de alguel",
                            value: `${globalFormatDates(
                                row.contractBegin &&
                                    !row.contractBegin?.includes("NaN")
                                    ? new Date(
                                          row.contractBegin?.substring(0, 10) +
                                              " 03:00:00"
                                      ).toISOString()
                                    : ""
                            )} 
							a ${globalFormatDates(
                                row.contractEnd &&
                                    !row.contractEnd?.includes("NaN")
                                    ? new Date(
                                          row.contractEnd?.substring(0, 10) +
                                              " 03:00:00"
                                      ).toISOString()
                                    : ""
                            )}`,
                            icon: <DateRange />,
                            additionalInfo:
                                row.contractBegin &&
                                row.contractEnd &&
                                !isNaN(
                                    differenceInDays(
                                        new Date(
                                            row.contractEnd?.substring(0, 10) +
                                                " 03:00:00"
                                        ),
                                        new Date(
                                            row.contractBegin?.substring(
                                                0,
                                                10
                                            ) + " 03:00:00"
                                        )
                                    )
                                )
                                    ? `(${Math.round(
                                          differenceInDays(
                                              new Date(
                                                  row.contractEnd?.substring(
                                                      0,
                                                      10
                                                  ) + " 03:00:00"
                                              ),
                                              new Date(
                                                  row.contractBegin?.substring(
                                                      0,
                                                      10
                                                  ) + " 03:00:00"
                                              )
                                          ) / 30
                                      )} meses)`
                                    : "",
                        },

                        {
                            label: "Tombamento",
                            value: row.isTipping === "1" ? "Sim" : "Não",
                            icon: <PublishedWithChangesOutlined />,
                        },
                    ],
                },
            ],
        },
        {
            title: "Valores",
            rows: [
                {
                    fields: [
                        {
                            label: "Valor do aluguel",
                            value: row.rentAmount,
                            icon: <AttachMoneyOutlined />,
                            valueFormatter: (value: string) =>
                                globalFormatCurrencyBRL(value).toString(),
                            colSpan: 4,
                        },
                        {
                            label: "Valor do Condomínio ",
                            value: row.condominiumAmount,
                            icon: <CorporateFareOutlined />,
                            valueFormatter: (value: string) =>
                                globalFormatCurrencyBRL(value).toString(),
                            colSpan: 4,
                        },
                        {
                            label: "Valor do IPTU",
                            value: row.iptuAmount,
                            icon: <ReceiptOutlined />,
                            valueFormatter: (value: string) =>
                                globalFormatCurrencyBRL(value).toString(),
                            colSpan: 4,
                        },
                        {
                            label: "Valor da Energia",
                            value: row.energyAmount,
                            icon: <ElectricBoltOutlined />,
                            valueFormatter: (value: string) =>
                                globalFormatCurrencyBRL(value).toString(),
                            colSpan: 4,
                        },
                        {
                            label: "Valor da Água",
                            value: row.waterAmount,
                            icon: <WaterDropOutlined />,
                            valueFormatter: (value: string) =>
                                globalFormatCurrencyBRL(value).toString(),
                            colSpan: 4,
                        },
                        {
                            label: "Valor do Gás",
                            value: row.gasAmount,
                            icon: <PropaneTankOutlined />,
                            valueFormatter: (value: string) =>
                                globalFormatCurrencyBRL(value).toString(),
                            colSpan: 4,
                        },
                    ],
                },
            ],
        },
        {
            title: "Bonificações/Premiações",
            rows: [
                {
                    fields: [
                        {
                            label: "Valor da Bonificação",
                            value: row.bonusAmount,
                            icon: <AttachMoneyOutlined />,
                            valueFormatter: (value: string) =>
                                globalFormatCurrencyBRL(value).toString(),
                            colSpan: 4,
                        },
                        {
                            label: "Valor da Premiação",
                            value: row.rewardAmount,
                            icon: <AttachMoneyOutlined />,
                            valueFormatter: (value: string) =>
                                globalFormatCurrencyBRL(value).toString(),
                            colSpan: 4,
                        },
                        {
                            label: "Taxa Setup",
                            value: row.transaction?.rawAmount,
                            valueFormatter: (value: string) =>
                                globalFormatCurrencyBRL(value).toString(),
                            icon: <AttachMoneyOutlined />,
                            colSpan: 4,
                        },
                    ],
                },
            ],
        },

        {
            title: "Dados do Locador",
            rows: [
                {
                    fields: [
                        {
                            label: "Nome",
                            value: row.lessorName,
                            icon: <Person />,
                        },
                        {
                            label: "CPF",
                            value: row.lessorCpf,
                            icon: <Portrait />,
                            valueFormatter: globalMaskDocument,
                        },
                    ],
                },
                {
                    fields: [
                        {
                            label: "Nascimento",
                            value: row.lessorBirthDate
                                ? row.lessorBirthDate?.substring(0, 10) +
                                  " 03:00:00"
                                : undefined,
                            icon: <CalendarToday />,
                            additionalInfo: row.lessorBirthDate
                                ? !isNaN(
                                      differenceInYears(
                                          new Date(),
                                          new Date(
                                              row.lessorBirthDate?.substring(
                                                  0,
                                                  10
                                              ) + " 03:00:00"
                                          )
                                      )
                                  )
                                    ? `(${differenceInYears(
                                          new Date(),
                                          new Date(
                                              row.lessorBirthDate?.substring(
                                                  0,
                                                  10
                                              ) + " 03:00:00"
                                          )
                                      )} anos)`
                                    : "Não Informado"
                                : "Não Informado",
                            valueFormatter: globalFormatDates,
                        },
                        {
                            label: "Telefone",
                            value: row.lessorPhone,
                            icon: <Phone />,
                            valueFormatter: maskPhone,
                        },
                    ],
                },
                {
                    fields: [
                        {
                            label: "Email",
                            value: row.lessorEmail,
                            icon: <Email />,
                        },
                    ],
                },
            ],
        },
        ...(renovateProposal?.renovatedId
            ? [
                  {
                      title: "Dados da Renovação",
                      rows: [
                          {
                              fields: [
                                  {
                                      label: "Data da Renovação",
                                      value: renovateProposal.createdAt,
                                      icon: <CalendarToday />,
                                      colSpan: 4,
                                  },
                                  {
                                      label: "Início da Vigência",
                                      value: renovateProposal.termBegin,
                                      icon: <CalendarToday />,
                                      colSpan: 4,
                                  },
                                  {
                                      label: "Fim da Vigência",
                                      value: renovateProposal.expiresAt,
                                      icon: <CalendarToday />,
                                      colSpan: 4,
                                  },
                                  {
                                      label: "Visualizar Garantia Renovada",
                                      icon: <ImageSearch />,
                                      type: "button" as const,
                                      onClick: () => {
                                          handleNavigateToRenovated();
                                      },
                                      value: "teste",
                                      colSpan: 4,
                                  },
                              ],
                          },
                      ],
                  },
              ]
            : []),
        {
            title: "Dados do usuário",
            rows: [
                {
                    fields: [
                        {
                            label: "Nome",
                            value: user.name,
                            icon: <Person />,
                            colSpan: 4,
                        },
                        {
                            label: "Email",
                            value: user.email,
                            icon: <Email />,
                            colSpan: 4,
                        },
                        {
                            label: "Última atualização",
                            value: user.updatedAt,
                            icon: <CalendarToday />,
                            valueFormatter: globalFormatDates,
                            colSpan: 4,
                        },
                    ],
                },
            ],
        },
    ];

    return (
        <Card sx={{ margin: "auto", px: 4, borderRadius: 4, ml: 1, mt: 0.5 }}>
            <CardContent>
                {
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: 1,
                        }}
                    >
                        <Chip
                            size="medium"
                            label={` ${globalTranslateStatus(row.status)}`}
                            sx={{
                                color: globalColorTextProposal(
                                    row.status == "active" &&
                                        row.renovatedId &&
                                        differenceInDays(
                                            new Date(
                                                row.termBegin
                                                    ? row.termBegin.substring(
                                                          0,
                                                          10
                                                      ) + " 03:00:00"
                                                    : "2021-01-01 03:00:01"
                                            ),
                                            new Date()
                                                .toISOString()
                                                .substring(0, 10) + " 03:00:00"
                                        ) > 0
                                        ? "renovated"
                                        : row.status
                                ),
                                fontWeight: "500",
                                fontSize: "20px",
                                background: globalbackgroundProposal(
                                    row.status == "active" &&
                                        row.renovatedId &&
                                        differenceInDays(
                                            new Date(
                                                row.termBegin
                                                    ? row.termBegin.substring(
                                                          0,
                                                          10
                                                      ) + " 03:00:00"
                                                    : "2021-01-01 03:00:01"
                                            ),
                                            new Date()
                                                .toISOString()
                                                .substring(0, 10) + " 03:00:00"
                                        ) > 0
                                        ? "renovated"
                                        : row.status
                                ),
                                border: "0.5px solid #f1f1f1",
                            }}
                        />
                        <ProposalActions
                            rows={row}
                            key={row.id}
                            onUpdate={() => {}}
/>
                    </Box>
                }

                {sections.map((section, sectionIndex) => (
                    <div key={sectionIndex} style={{ marginBottom: "16px" }}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: "#4c4c4d",
                                fontWeight: 500,
                                marginBottom: "12px",
                            }}
                        >
                            {section.title}
                        </Typography>
                        {section.rows.map((row, rowIndex) => (
                            <Grid
                                container
                                spacing={2}
                                sx={{ mb: 2 }}
                                key={rowIndex}
                            >
                                {row.fields
                                    .filter((field) => !field.hidden)
                                    .map((field, fieldIndex) => (
                                        <Grid
                                            item
                                            xs={field.colSpan || 12}
                                            sm={field.colSpan || 6}
                                            key={fieldIndex}
                                        >
                                            {field.type === "button" ? (
                                                <Button
                                                    startIcon={field.icon}
                                                    fullWidth
                                                    onClick={field.onClick}
                                                    {...field.buttonProps}
                                                >
                                                    {field.label}
                                                </Button>
                                            ) : field.type === "box" ? (
                                                <Box
                                                    sx={{
                                                        width: "98%",
                                                        backgroundColor:
                                                            field.boxProps
                                                                ?.backgroundColor ||
                                                            "rgba(255, 0, 0, 0.05)",
                                                        padding:
                                                            field.boxProps
                                                                ?.padding ?? 2,
                                                        borderRadius:
                                                            field.boxProps
                                                                ?.borderRadius ??
                                                            1,
                                                        marginTop:
                                                            field.boxProps
                                                                ?.marginTop ??
                                                            1,
                                                        marginBottom:
                                                            field.boxProps
                                                                ?.marginBottom ??
                                                            2,
                                                    }}
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        color={
                                                            field.boxProps
                                                                ?.textColor ||
                                                            "red"
                                                        }
                                                        sx={{ width: "100%" }}
                                                    >
                                                        {field.value}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <InfoItemWithCopy
                                                    label={field.label || ""}
                                                    value={
                                                        field.valueFormatter
                                                            ? field.valueFormatter(
                                                                  field.value ||
                                                                      ""
                                                              )
                                                            : field.value || ""
                                                    }
                                                    icon={field.icon}
                                                    image={field.image}
                                                    additionalInfo={
                                                        field.additionalInfo
                                                    }
                                                />
                                            )}
                                        </Grid>
                                    ))}
                            </Grid>
                        ))}
                        {sectionIndex < sections.length - 1 && (
                            <Divider sx={{ my: 3 }} />
                        )}
                    </div>
                ))}
                {modals}
            </CardContent>
        </Card>
    );
};

export default ProposalCardData;
