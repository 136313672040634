import React, { createContext, useContext, useState } from "react";
import { useProfile } from "./ProfileContext"; // ✅ Importando `useProfile`

interface ProposalContextProps {
    refreshData: boolean;
    setRefreshData: (value: boolean) => void;
    isSuperAdmin: boolean;
    permissionLevel: number; // ✅ Agora garantimos que sempre será um número
}

// Criamos o contexto
const ProposalContext = createContext<ProposalContextProps | undefined>(
    undefined
);

// Criamos o Provider
export const ProposalProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [refreshData, setRefreshData] = useState(false);
    const { isSuperAdmin, permissionLevel } = useProfile(); // ✅ Pegamos do `ProfileContext`

    return (
        <ProposalContext.Provider
            value={{
                refreshData,
                setRefreshData,
                isSuperAdmin,
                permissionLevel: permissionLevel ?? 0, // ✅ Garante que nunca será `null`
            }}
        >
            {children}
        </ProposalContext.Provider>
    );
};

// Hook para facilitar o uso do contexto
export const useProposalContext = () => {
    const context = useContext(ProposalContext);
    if (!context) {
        throw new Error(
            "useProposalContext deve ser usado dentro de um ProposalProvider"
        );
    }
    return context;
};
