
export const style = {
	position: "relative",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "40%", // Reduzido de 50% para 40%
	height: "auto",
	maxWidth: "75%",
	maxHeight: "85%",
	bgcolor: "background.paper",
	border: "1px solid #605e5e",
	boxShadow: 24,
	borderRadius: "6px",
	display: "flex",
	flexDirection: "column",
	backgroundColor: "#FFF",
	padding: 3,
};

// Ajuste da largura do modal de visualização
export const previewStyle = {
	position: "relative",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "70%", // Reduzido de 80% para 70%
	height: "80%",
	bgcolor: "background.paper",
	border: "1px solid #605e5e",
	boxShadow: 24,
	borderRadius: "6px",
	padding: 3,
};