import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingDialog from "../../../../../shared/components/LoadingModalDialog/LoadingModalDialog";
import { PlanServices } from "../../../../../shared/services/api/plan/PlanServices";
import { IProposalList } from "../../../../../shared/services/api/proposal/ProposalService";
import PlanCard, { Plan } from "./PlanCard";

interface ModalPlanRenovateProps {
	isOpen: boolean;
	onClose: () => void;
	onSelectPlan: (plan: Plan) => void;
	row: IProposalList;
	updatedRentAmount: string;
	updateIPTU: string;
	updateCondominium: string;
}

/**
 * O hook `useFetchPlans` é responsável por buscar planos atualizados com base nos valores fornecidos de aluguel, IPTU e condomínio.
 * Quando o modal está aberto (`isOpen` é true) e a linha (`row`) contém um id válido e um valor de aluguel atualizado,
 * ele faz uma chamada assíncrona ao serviço `PlanServices.planUpdateAmountClient` para obter os planos.
 * 
 * A resposta é filtrada para incluir apenas planos com uma nova taxa (`newFee`) maior ou igual à taxa residencial (`residentialFee`),
 * dependendo da diferença em meses desde a última atualização (`adjustmentAt`).
 */
const useFetchPlans = (isOpen: boolean, row: any, updatedRentAmount: number, updateIPTU: number, updateCondominium: number) => {
	const [plans, setPlans] = useState<Plan[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	useEffect(() => {
		if (isOpen && row?.id && updatedRentAmount !== undefined) {
			(async () => {
				setLoading(true);
				setError(null);
				try {
					const rentAmount = updatedRentAmount !== undefined && updatedRentAmount !== 0 ? updatedRentAmount : parseFloat(row.rentAmount || "0");
					const iptu = updateIPTU !== undefined && updateIPTU !== 0 ? updateIPTU : parseFloat(row.iptuAmount || "0");
					const condominium =
						updateCondominium !== undefined && updateCondominium !== 0 ? updateCondominium : parseFloat(row.condominiumAmount || "0");
					const response = await PlanServices.planUpdateAmountClient(row.id, rentAmount.toString(), iptu.toString(), condominium.toString());

					if (response instanceof Error) {
						throw response;
					}

					if (Array.isArray(response)) {
						const diffInMonths = Math.floor((new Date().getTime() - new Date(row.adjustmentAt).getTime()) / (1000 * 60 * 60 * 24 * 30));
						const filteredPlans = response.filter((item) => {
							const newFee = parseFloat(item.newFee || "0");
							const residentialFee = parseFloat(row.residentialFee || "0");
							if (diffInMonths > 2) { // 60 dias é aproximadamente 2 meses								
								return newFee >= residentialFee;
							} else {
								return newFee > residentialFee;
							}
						});
						// console.log("row", row.adjustmentAt);
						// console.log("diff em meses", diffInMonths);
						// console.log("filteredPlans", filteredPlans);
						// console.log("response", response);
						const mappedPlans = filteredPlans.map((item) => mapPlanData(item, rentAmount, condominium, iptu));
						setPlans(mappedPlans);
					} else {
						setError("Ocorreu um erro ao carregar os planos. Tente novamente.");
					}
				} catch (err) {
					setError("Erro ao carregar os planos. Verifique sua conexão ou tente novamente.");
				} finally {
					setLoading(false);
				}
			})();
		}
	}, [isOpen, row, updatedRentAmount, updateIPTU, updateCondominium]);

	return { plans, loading, error };
};

const mapPlanData = (item: any, rentAmount: number, condominium: number, iptu: number): Plan => {
	const newFee = parseFloat(item.newFee).toFixed(2) || item.newFee;
	const calculatedPrice = item.flagRateCharge === "1" ? (newFee * (rentAmount + condominium + iptu)) / 100 : (newFee * rentAmount) / 100;
	const planLmi = item.lmi ? parseInt(item.lmi, 10) : 0;
	const lmiValue =
		item.flagRateCharge === "1" ? parseFloat((planLmi * (rentAmount + condominium + iptu)).toFixed(2)) : parseFloat((planLmi * rentAmount).toFixed(2));

	return {
		id: item.plan?.id || item.id || "sem-id",
		name: item.plan?.description || "Plano Desconhecido",
		price: calculatedPrice,
		description: `Plano ${item.plan?.description || "Desconhecido"}`,
		lmiValue,
		lmi: planLmi,
		commission: item.plan?.bonusAmount ? parseFloat(item.plan.bonusAmount) : undefined,
		taxa: item.newFee ? parseFloat(item.newFee) : undefined,
		flagRateCharge: item.flagRateCharge,
	};
};

const ModalPlanRenovate: React.FC<ModalPlanRenovateProps> = ({ isOpen, onClose, onSelectPlan, row, updatedRentAmount, updateIPTU, updateCondominium }) => {
	const { plans, loading, error } = useFetchPlans(isOpen, row, parseFloat(updatedRentAmount), parseFloat(updateIPTU), parseFloat(updateCondominium));
	const [selectedPlan, setSelectedPlan] = useState<number | null>(null);

	const handleSelectPlan = (plan: Plan) => {
		onSelectPlan(plan);
		onClose();
	};

	return (
		<Modal open={isOpen} onClose={onClose}>
			<Box
				sx={{
					position: "absolute",
					top: "50%",
					left: "50%",
					transform: "translate(-50%, -50%)",
					width: "95%",
					maxWidth: 1000,
					maxHeight: "90vh",
					bgcolor: "background.paper",
					boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
					p: 4,
					borderRadius: 2,
					background: "#fff",
				}}
			>
				<Typography variant={"h4"} sx={{ fontWeight: 300, textAlign: "center", mb: 1 }} color={"primary"}>
					Escolha seu Plano para Renovação
				</Typography>
				<Box sx={{ position: "absolute", top: 8, right: 8 }}>
					<IconButton onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Box>

				{loading ? (
					<Box sx={{ textAlign: "center", mt: 4 }}>
						{loading && <LoadingDialog isLoading={loading} loadingText={`Carregando planos disponíveis...`} />}
					</Box>
				) : error ? (
					<Typography
						variant="body1"
						color="error"
						sx={{
							mt: 4,
							textAlign: "center",
							fontFamily: "'Inter', sans-serif",
						}}
					>
						{error}
					</Typography>
				) : plans.length === 0 ? (
					<Typography
						variant="body1"
						sx={{
							mt: 8,
							textAlign: "center",
							color: "#666",
							fontFamily: "'Inter', sans-serif",
						}}
					>
						Nenhum plano disponível para renovação no momento, contate o setor comercial.
					</Typography>
				) : (
					<Box
						sx={{
							maxHeight: "70vh",
							overflowY: "auto",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							flexWrap: "wrap",
							gap: 3,
							padding: 2,
						}}
					>
						{plans.map((plan) => (
							<Box key={plan.id} sx={{ flex: "0 1 calc(33.333% - 16px)", maxWidth: "calc(33.333% - 16px)", py: 1 }}>
								<PlanCard
									plan={plan}
									isSelected={selectedPlan === plan.id}
									onSelect={handleSelectPlan}
									onClick={() => setSelectedPlan(plan.id as number)}
									currentPlanId={row.planId || ""}
								/>
							</Box>
						))}
					</Box>
				)}
			</Box>
		</Modal>
	);
};

export default ModalPlanRenovate;
