import { Api } from "../axios-config";

export interface IComission {
	id: string;
	invoice: string;
	partner: string;
	cycle: string;
	totalAmount: string;
	type: string;
	clientId: string;
	status: string;
	createdAt: string;
	updatedAt: string;
	totAmount: string;
	totValue: string;
	reference: string;
	dueDate: string;
	paymentDate: string;
	cancelDate: string;
	cancelReason: string;
	cancelUser: string;
}

export type TComissionTotalCount = {
	data: IComission[];
	totalCount: number;
};

export type ComissionDocumentType = {
	type: string;
	name: string;
	id: string;
	mimetype: string;
	filename: string;
	originalFilename: string;
	url: string;
	uploadedDate: string;
	size: number;
	docType: string;
};

const getComission = async (page = 1, limit = 10, search = "", dataini = "", datafim = "", status = ""): Promise<TComissionTotalCount | Error> => {
	try {
		const { data } = await Api().get(`/comission?_page=${page}&_limit=${limit}&nome_like=${search}&dataini=${dataini}&datafim=${datafim}&status=${status}`);
		if (data.results) {
			return { data: data.results, totalCount: data.total };
		}
		return new Error("Erro ao buscar comissões");
	} catch (error) {
		console.error(error);
		return new Error("Erro ao buscar comissões");
	}
};

const getComissionByID = async (id: string): Promise<IComission | Error> => {
	try {
		const { data } = await Api().get(`/comission/values/${id}`);
		if (data) {
			return data;
		}
		return new Error("Erro ao buscar valores");
	} catch (error) {
		console.error(error);
		return new Error("Erro ao buscar valores");
	}
};

const getComissionDetail = async (id: string): Promise<IComission | Error> => {
	try {
		const { data } = await Api().get(`/comission/details/${id}`);
		if (data) {
			return data;
		}
		return new Error("Erro ao buscar valores");
	} catch (error) {
		console.error(error);
		return new Error("Erro ao buscar valores");
	}
};

const addValues = async (values: any): Promise<IComission | Error> => {
	try {
		const { data } = await Api().post(`/comission/addvalues`, values);
		if (data) {
			return data;
		}
		return new Error("Erro ao adicionar valor");
	} catch (error) {
		console.error(error);
		return new Error("Erro ao adicionar valore");
	}
};

const getComissionValues = async (id: string): Promise<IComission | Error> => {
	try {
		const { data } = await Api().get(`/comission/values/${id}`);
		if (data) {
			return data;
		}
		return new Error("Erro ao buscar valores ");
	} catch (error) {
		console.error(error);
		return new Error("Erro ao buscar valores");
	}
};

const uploadComissionDocument = async (comissionId: string, file: File): Promise<any> => {
	const formData = new FormData();
	formData.append("comissionId", comissionId);
	formData.append("documentTypeId", "comission_prize");
	formData.append("file", file);
	const { data } = await Api().post("/comission-documents", formData, {
		headers: { "Content-Type": "multipart/form-data" },
	});

	return data as any;
};

const getComissionDocuments = async (comissionId: string): Promise<ComissionDocumentType[]> => {
	const { data } = await Api().get(`/comission-documents/comissions/${comissionId}`);
	return data as ComissionDocumentType[];
};

const removeDocumentByComission = async (comissionId: string): Promise<void | Error> => {
	try {
		const { data } = await Api().patch(`/proposal-documents/comissions/${comissionId}`);

		if (!data) return new Promise((_, reject) => reject(new Error("Erro ao remover documento.")));
	} catch (error: any) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

const getComissionPdf = async (comissionId: string): Promise<any> => {
	try {
		const { data } = await Api().get(`/comission/pdf/${comissionId}`, {
			responseType: "blob",
		});
		if (data) {
			const file = new Blob([data], { type: "application/pdf" });
			const fileURL = URL.createObjectURL(file);
			window.open(fileURL);
		}
		return new Promise((_, reject) => true);
	} catch (error) {
		console.error(error);
		return new Error((error as { message: string }).message || "Erro ao consultar o registro.");
	}
}


const cancelValuesComission = async (id: string): Promise<void | Error> => {
	try {
		const { data } = await Api().delete(`/comission/cancel-values/${id}`);
		console.log(data);

		if (!data) return new Promise((_, reject) => reject(new Error("Erro ao cancelar Valor.")));
		return data;
	} catch (error: any) {
		console.error(error);
		return new Promise((_, reject) => reject(error));
	}
};

export const ComissionServices = {
	getComission,
	getComissionByID,
	getComissionValues,
	getComissionDetail,
	addValues,
	uploadComissionDocument,
	cancelValuesComission,
	getComissionDocuments,
	removeDocumentByComission,
	getComissionPdf,
};
