import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Skeleton } from "@mui/material";
import React from "react";
import { globalFormatCurrencyBRL, globalMaskDocument, globalTranslatePayment } from "../../../shared/utils";

interface TableInvoiceDetailProps {
	rows: any[];
	isLoading: boolean;
}

const TableInvoiceDetail: React.FC<TableInvoiceDetailProps> = ({ rows, isLoading }) => {
	return (
		<>
			<Typography variant="h6" sx={{ padding: 0, textAlign: "center", color: "#666", mt: 2 }}>
				Extrato da Premiação / Bonificação
			</Typography>

			<TableContainer
				component={Paper}
				variant="outlined"
				sx={{
					marginTop: 0,
					width: "100%",
					padding: 0,
					maxHeight: 300,
					overflowY: "auto",
					color: "#666",

					/* Scrollbar - Chrome, Safari, Edge (webkit) */
					"&::-webkit-scrollbar": {
						width: 8,
					},
					"&::-webkit-scrollbar-track": {
						background: "#f1f1f1",
						borderRadius: 8,
					},
					"&::-webkit-scrollbar-thumb": {
						backgroundColor: "#c1c1c1",
						borderRadius: 8,
						backgroundClip: "padding-box",
						border: "2px solid #f1f1f1",
					},
					"&::-webkit-scrollbar-thumb:hover": {
						backgroundColor: "#b0b0b0",
					},

					/* Scrollbar - Firefox */
					scrollbarWidth: "thin",
					scrollbarColor: "#c1c1c1 #f1f1f1",
				}}
			>
				<Table stickyHeader size="small">
					<TableHead>
						<TableRow>
							<TableCell>Produto</TableCell>
							<TableCell>Certificado</TableCell>
							<TableCell>Nome</TableCell>
							<TableCell>Tipo</TableCell>
							<TableCell>Parcela</TableCell>
							<TableCell>Valor Parcela</TableCell>
							<TableCell>Percentual</TableCell>
							<TableCell>Valor Bonificação</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{isLoading
							? // Skeleton de 5 linhas simulando carregamento
							  Array.from({ length: 5 }).map((_, index) => (
									<TableRow key={index}>
										{Array.from({ length: 8 }).map((_, cellIndex) => (
											<TableCell key={cellIndex}>
												<Skeleton variant="text" width="100%" height={20} />
											</TableCell>
										))}
									</TableRow>
							  ))
							: rows.map((invoice) => (
									<TableRow key={invoice.id}>
										<TableCell>{invoice.proposal.planId === "custom" ? "Personal" : invoice.proposal.planId}</TableCell>
										<TableCell>{invoice.proposal.certificateNumber}</TableCell>
										<TableCell>
											{invoice.proposal.name}
											<Typography variant="subtitle2" fontWeight={500}>
												{globalMaskDocument(invoice.proposal.document)}
											</Typography>
										</TableCell>
										<TableCell>{globalTranslatePayment(invoice.typeAmount)} </TableCell>
										<TableCell>
											{invoice.number}/{invoice.proposal.installmentsNumber}
										</TableCell>
										<TableCell>{globalFormatCurrencyBRL(invoice.amount)}</TableCell>
										<TableCell>{invoice.comissionValue}%</TableCell>
										<TableCell>{globalFormatCurrencyBRL(((invoice.comissionValue / 100) * invoice.amount).toString())}</TableCell>
									</TableRow>
							  ))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

export default TableInvoiceDetail;
