import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
	Alert,
	Box,
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	InputAdornment,
	LinearProgress,
	MenuItem,
	Modal,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useProfile } from "../../../../context/ProfileContext";
import { HtmlTooltip } from "../../../../infra/components/tooltipsCustom/tooltipsCuston";
import { useVForm, VForm, VTextField } from "../../../../shared/forms";
import { VMaskTextField } from "../../../../shared/forms/VMaskTextField";
import { globalRemoveMaskReal2, globalRemoveMaskRealDecimais } from "../../../../shared/utils";
import { ICommercialGrid } from "../RelationComponents/Commercial";
import { AutoCompletePlan } from "./AutoCompletePlan";
import { fieldExplanations } from "./fieldExplanations";
import { ModalContextStyle, ModalStyle } from "./ModalPlanStyles";
import { formValidationSchemaCreate } from "./validation";
import { clientService, type IPlanRelation } from "../../../../shared/services/api/client/clientService";
import { ToastContent } from "../../../../shared/components";
import { returnMessageError } from "../../../../shared/utils/returnMessageError";
import { useToast } from "../../../../shared/hooks";

interface IVFormErros {
	[key: string]: string;
}

interface IPlanoModalProps {
	textButtonConfirm?: string;
	showButtonCancel?: boolean;
	isOpenModal: boolean;
	messageType: string;
	data: ICommercialGrid;
	onclickConfirm?: () => void;
	onclickCancel?: () => void;
	isCloseModal?: () => void;
	clientPlan?: string;
}

const InfoIconStyled = () => (
	<Box
		sx={{
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: 24,
			height: 24,
			borderRadius: "50%",
			backgroundColor: "rgba(0, 0, 0, 0.04)",
		}}
	>
		<InfoOutlinedIcon fontSize="small" />
	</Box>
);

const arrayLMI = Array.from({ length: 72 }, (_, index) => index + 1).filter((value) => value >= 12);

export const ModalPlan: React.FC<IPlanoModalProps> = ({
	textButtonConfirm = "Adicionar",
	showButtonCancel = true,
	isOpenModal,
	clientPlan,
	data,
	isCloseModal,
	onclickConfirm,
}) => {
	const { formRef } = useVForm();
	const [isLoading, setIsLoading] = useState(false);
	const [showHelp, setShowHelp] = useState(false);
	const [selectedPlan, setSelectedPlan] = useState<ICommercialGrid>({} as ICommercialGrid);
	const { permissionLevel, isSuperAdmin } = useProfile();
	const [comissionType, setComissionType] = useState<string>("percent");
	const [personalized, setPersonalized] = useState(true);
	const [messagemAlert, setMessagemAlert] = useState("");
	const toastHandler = useToast();

	const handleSave = async (dados: IPlanRelation) => {
		setIsLoading(true);
		try {
			const formData = formRef.current?.getData();
			if (!formData) return;

			dados.flagRenovation = formData.usedFor === "renovacao" || formData.usedFor === "analise_renovacao" ? "1" : "0";
			dados.flagAnalysis = formData.usedFor === "analise" || formData.usedFor === "analise_renovacao" ? "1" : "0";
			dados.flagRateCharge = formData.chargeType === "aluguel_encargos" ? "1" : "0";
			dados.flagRateRent = "1";
			dados.residentialFee = globalRemoveMaskReal2(dados.residentialFee);
			dados.commercialFee = globalRemoveMaskReal2(dados.commercialFee);
			dados.coverageAcessories = globalRemoveMaskReal2(dados.coverageAcessories);
			dados.bonusAmount = globalRemoveMaskReal2(dados.bonusAmount);
			dados.comissionValue = globalRemoveMaskReal2(dados.comissionValue);
			dados.setupTransfer = globalRemoveMaskReal2(dados.setupTransfer);
			delete dados.usedFor;
			delete dados.chargeType;

			const dadosProcessados = {
				...dados,
				clientId: clientPlan,
			};

			formValidationSchemaCreate
				.validate(dadosProcessados, { abortEarly: false })
				.then((dadosvalidados) => {
					setIsLoading(true);
					const chamadaAPI = data.id
						? clientService.updatePlanRelation(data.id, dadosProcessados)
						: clientService.createPlanRelation(dadosProcessados);
					chamadaAPI
						.then((resultado) => {
							setIsLoading(false);
							if (resultado instanceof Error) {
								throw resultado;
							}
							toastHandler.present({
								type: "success",
								position: "top-right",
								messageOrContent: <ToastContent title="Sucesso" content="Plano adicionado com sucesso." />,
							});
							if (onclickConfirm) onclickConfirm();
						})
						.catch((erro: any) => {
							setIsLoading(false);
							const { titleMessage, contentMessage } = returnMessageError(erro);
							toastHandler.present({
								type: "error",
								position: "top-right",
								messageOrContent: <ToastContent title={titleMessage} content={contentMessage} />,
							});
						});
				})
				.catch((errors: yup.ValidationError) => {
					const validationErros: IVFormErros = {};
					errors.inner.forEach((error) => {
						if (!error.path) return;
						validationErros[error.path] = error.message;
					});
					console.log("Erros de validação:", validationErros);
					formRef.current?.setErrors(validationErros);
					setIsLoading(false);
				});
		} catch (error) {
			console.error("Erro ao salvar o plano:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const getUsedForValue = (flagRenovation: string, flagAnalysis: string): string => {
		if (flagRenovation === "1" && flagAnalysis === "1") return "analise_renovacao";
		if (flagRenovation === "1") return "renovacao";
		if (flagAnalysis === "1") return "analise";
		return "";
	};

	const getChargeType = (flagRateCharge: string): string => {
		if (flagRateCharge === "1") return "aluguel_encargos";
		return "aluguel";
	};
	
	const handleComissionValue = (value: string) => {
		if (formRef.current) {
			if (comissionType === "percent") {
				const valorNumerico = Number.parseFloat(globalRemoveMaskReal2(value));
				if (isNaN(valorNumerico) || valorNumerico < 0) {
					formRef.current.setFieldValue("comissionValue", "0,00");
					setMessagemAlert("O valor mínimo para percentual é 0%. O valor foi ajustado automaticamente.");
				} else if (valorNumerico > 100) {
					formRef.current.setFieldValue("comissionValue", "100,00");
					setMessagemAlert("O valor máximo para percentual é 100%. O valor foi ajustado automaticamente.");
				} else {
					setMessagemAlert("");
				}
			} else {
				setMessagemAlert("");
			}
		}
	};

	useEffect(() => {
		if (!data?.id) return;
		setSelectedPlan(data);
	}, [data]);

	useEffect(() => {
		if (selectedPlan) {
			Object.entries(selectedPlan).forEach(([chave, valor]) => {
				formRef.current?.setFieldValue(chave, valor);
			});

			const usedFor = getUsedForValue(data.flagRenovation, data.flagAnalysis);
			if (usedFor) {
				formRef.current?.setFieldValue("usedFor", usedFor);
			} else {
				formRef.current?.setFieldValue("usedFor", "analise_renovacao");
			}

			const chargeType = getChargeType(data.flagRateCharge);
			if (chargeType) {
				formRef.current?.setFieldValue("chargeType", chargeType);
			} else {
				formRef.current?.setFieldValue("chargeType", "aluguel");
			}
		}
	}, [selectedPlan]);

	const renderFieldWithTooltip = (fieldName: string, component: React.ReactNode) => (
		<Box display="flex" alignItems="center">
			{component}
			{showHelp && fieldExplanations[fieldName] && (
				<HtmlTooltip
					title={
						<React.Fragment>
							<Typography color="inherit" variant="body1" fontWeight={"500"}>
								{fieldExplanations[fieldName]?.title}
							</Typography>
							<Typography color="inherit" variant="caption">
								{fieldExplanations[fieldName]?.description}
							</Typography>
						</React.Fragment>
					}
				>
					<IconButton size="small">
						<InfoIconStyled />
					</IconButton>
				</HtmlTooltip>
			)}
		</Box>
	);

	const renderPlano = () => (
		<>
			<Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
				<Typography variant="body1" sx={{ mb: 1.2, mt: 2, fontWeight: 500 }}>
					Plano
				</Typography>
				<Button startIcon={<InfoIconStyled />} onClick={() => setShowHelp(!showHelp)} sx={{ textTransform: "none" }}>
					Ajuda
				</Button>
			</Grid>
			<Grid container item direction="row" spacing={2}>
				<Grid item xs={12}>
					{!data.id && (
						<AutoCompletePlan
							isExternalLoading={isLoading}
							labelSelect="Plano"
							namecampo="planId"
							clientId={clientPlan}
							defaultValue={data.planId} // Passa o ID do plano selecionado ao abrir para edição
							onClick={(value) => {
								setSelectedPlan(value);
								setPersonalized(value?.personalized === "false" ? true : false);
							}}
						/>
					)}
					{data.id && (
						<VTextField fullWidth label="Plano  Escolhido" name="planId" disabled={true} select>
							<MenuItem value={data.planId}>{data.plan.description}</MenuItem>
						</VTextField>
					)}
				</Grid>

				{isSuperAdmin && (
					<>
						<Grid item xs={12} md={4}>
							{renderFieldWithTooltip(
								"usedFor",
								<VTextField fullWidth label="Utilizado para" name="usedFor" select disabled={isLoading}>
									<MenuItem value={"analise"}>SA - Somente para Análises</MenuItem>
									<MenuItem value={"renovacao"}>SR - Somente para Renovação</MenuItem>
									<MenuItem value={"analise_renovacao"}>AR - Análise e Renovação</MenuItem>
								</VTextField>
							)}
						</Grid>
						<Grid item xs={12} md={4}>
							{renderFieldWithTooltip(
								"chargeType",
								<VTextField fullWidth label="Modelo de Cobrança" name="chargeType" select>
									<MenuItem value="aluguel">Aluguel</MenuItem>
									<MenuItem value="aluguel_encargos">Aluguel + Encargos</MenuItem>
								</VTextField>
							)}
						</Grid>

						<Grid item xs={12} md={4}>
							{renderFieldWithTooltip(
								"coverage",
								<VTextField fullWidth label="Cobertura" name="coverage" select disabled={isLoading}>
									{[5, 7, 10].map((value) => (
										<MenuItem key={value} value={value}>
											{value}x valor do Aluguel para multa
										</MenuItem>
									))}
								</VTextField>
							)}
						</Grid>
						<Grid item xs={12} md={4}>
								{renderFieldWithTooltip(
						"coverageAcessories",
						<VMaskTextField fullWidth label="Cobertura Acessoórios" name="coverageAcessories" mask="real" disabled={personalized} />
					)}
				</Grid>
					</>
				)}
				<Grid item xs={12} md={4}>
					{renderFieldWithTooltip(
						"lmi",
						<VTextField fullWidth label="LMI" name="lmi" disabled={personalized} select>
							{arrayLMI.map((index) => (
								<MenuItem key={index} value={index.toString()}>
									{index.toString()}x
								</MenuItem>
							))}
						</VTextField>
					)}
				</Grid>
				<Grid item xs={12} md={4}>
					{renderFieldWithTooltip(
						"residentialFee",
						<VMaskTextField fullWidth label="Tx Residencial %" name="residentialFee" mask="real" disabled={personalized} />
					)}
				</Grid>
				<Grid item xs={12} md={4}>
					{renderFieldWithTooltip(
						"commercialFee",
						<VMaskTextField fullWidth label="Tx Comercial %" name="commercialFee" mask="real" disabled={personalized} />
					)}
				</Grid>
			</Grid>
		</>
	);

	const renderPremium = () => (
		<>
			<Grid item xs={12}>
				<Typography variant="body1" sx={{ mb: 1.2, mt: 2, fontWeight: 500 }}>
					Premiação
				</Typography>
			</Grid>
			<Grid container item direction="row" spacing={2}>
				<Grid item xs={12} md={4}>
					{renderFieldWithTooltip(
						"bonusAmount",
						<VMaskTextField fullWidth label="Valor da premiação" name="bonusAmount" disabled={isLoading} mask="real" />
					)}
				</Grid>
				<Grid item xs={12} md={4}>
					{renderFieldWithTooltip(
						"comissionProposalMin",
						<VMaskTextField
							fullWidth
							label="Qt. mínimo de Garantias"
							name="comissionProposalMin"
							defaultValue={data.comissionProposalMin && Number(data.comissionProposalMin) > 0 ? data.comissionProposalMin : "1"}
							disabled={isLoading}
							mask="inteiro"
							onBlur={(e: any) => {
								const value = e.target.value;
								if (!value || Number(value) < 1) {
									formRef.current?.setFieldValue("comissionProposalMin", "1");
								}
							}}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);

	const renderBonusFields = () => (
		<>
			<Grid item xs={12}>
				<Typography variant="body1" sx={{ mb: 1.2, mt: 2, fontWeight: 500 }}>
					Bonificação
				</Typography>
			</Grid>
			<Grid container item direction="row" spacing={2}>
				<Grid item xs={12} md={4}>
					{renderFieldWithTooltip(
						"comissionType",
						<VTextField fullWidth label="Tipo de bonificação" name="comissionType" select disabled={true}>
							<MenuItem value={"percent"}>Percentual %</MenuItem>
							<MenuItem value={"value"}> Valor Fixo R$</MenuItem>
						</VTextField>
					)}
				</Grid>

				<Grid item xs={12} md={4}>
					{renderFieldWithTooltip(
						"comissionValue",
						<VMaskTextField
							fullWidth
							label="Valor/índice da bonificação"
							name="comissionValue"
							mask="real"
							disabled={isLoading}
							onBlur={(e: any) => {
								const value = globalRemoveMaskRealDecimais(e.target.value);
								handleComissionValue(value);
							}}
							slotProps={{
								input: {
									startAdornment: <InputAdornment position="start">{comissionType === "percent" ? "%" : "R$"}</InputAdornment>,
								},
							}}
						/>
					)}
				</Grid>
				{messagemAlert && (
					<Grid item xs={12} md={12}>
						<Alert severity="info" onClose={() => setMessagemAlert("")}>
							{messagemAlert}
						</Alert>
					</Grid>
				)}
			</Grid>
		</>
	);

	const renderSetup = () => (
		<>
			<Grid item xs={12}>
				<Typography variant="body1" sx={{ mb: 1.2, mt: 2, fontWeight: 500 }}>
					Taxa Setup
				</Typography>
			</Grid>
			<Grid container item direction="row" spacing={2}>
				<Grid item xs={4}>
					{renderFieldWithTooltip(
						"setupTransfer",
						<VMaskTextField
							fullWidth
							label="% Repasse da taxa de setup"
							name="setupTransfer"
							disabled={isLoading}
							mask="real"
							onBlur={(e: any) => {
								const value = globalRemoveMaskRealDecimais(e.target.value);
								//handleSetupTransfer(value);
							}}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);

	return (
		<Modal open={isOpenModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
			<Box sx={ModalStyle}>
				<DialogTitle style={{ display: "flex", padding: 2 }}>
					<Typography id="modal-modal-title" variant="h4" fontWeight={300} color="primary.main">
						{data.id ? "Alterar " : "Adicionar "} um plano comercial
					</Typography>
				</DialogTitle>
				<DialogContent sx={ModalContextStyle}>
					<VForm id="formIdSave" ref={formRef} onSubmit={handleSave} placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
						{isLoading && (
							<Grid item>
								<LinearProgress variant="indeterminate" />
							</Grid>
						)}
						{renderPlano()}
						{renderBonusFields()}
						{renderPremium()}
						{renderSetup()}
					</VForm>
				</DialogContent>
				<DialogActions style={{ display: "flex", justifyContent: "right" }}>
					{showButtonCancel && (
						<Button onClick={isCloseModal} disableElevation variant="text" color="inherit" startIcon={<CloseIcon />}>
							Cancelar
						</Button>
					)}

					<Button disableElevation variant="contained" startIcon={<CheckIcon />} onClick={() => formRef.current?.submitForm()}>
						{textButtonConfirm}
					</Button>
				</DialogActions>
			</Box>
		</Modal>
	);
};
