import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

interface ProposalRenovatedProps {
    rows: any;
}

interface ProposalRenovatedResult {
    renovateProposal: {
        createdAt?: string;
        termBegin?: string;
        expiresAt?: string;
        renovatedId?: string;
    };
    handleNavigateToRenovated: () => void;
}

export const useProposalRenovated = ({ rows }: ProposalRenovatedProps): ProposalRenovatedResult => {
    const renovateProposal = {
        createdAt: rows?.renovatedProposalCreatedAt ? format(new Date(rows.renovatedProposalCreatedAt), "dd/MM/yyyy") : undefined,
        termBegin: rows?.renovatedProposalTermBegin
            ? format(new Date(rows.renovatedProposalTermBegin.substring(0, 10) + " 03:00:00"), "dd/MM/yyyy")
            : undefined,
        expiresAt: rows?.renovatedProposalExpiresAt ? format(new Date(rows.renovatedProposalExpiresAt), "dd/MM/yyyy") : undefined,
        renovatedId: rows?.renovatedId,
    };

    const handleNavigateToRenovated = () => {
        if (rows?.renovatedId) {
            window.open(`/garantias/visualizar/${rows.renovatedId}`, "_blank");
            return;
        }
    };

    return {
        renovateProposal,
        handleNavigateToRenovated,
    };
};
