import { AuthGuard } from "../../shared/guards/AuthGuard";
import { ProposalDetailPresentation } from "./components";
import { Sidebar } from "../../shared/components";
import { ProposalProvider } from "../../context/ProposalContext"; // ✅ Importando o contexto

export const ProposalDetailPage = (): JSX.Element => {
    return (
        <AuthGuard>
            <Sidebar>
                <ProposalProvider> {/* ✅ Contexto aplicado apenas nesta página */}
                    <ProposalDetailPresentation />
                </ProposalProvider>
            </Sidebar>
        </AuthGuard>
    );
};
