import { Skeleton } from "@mui/material";

interface ProposalSinisterProps {
    rows: any;
}

interface ProposalSinisterResult {
    sinisterCard: {
        title: string;
        rows: {
            fields: {
                type: "box";
                value: any;
                colSpan: number;
                boxProps: {
                    backgroundColor: string;
                    textColor?: string;
                    padding: number;
                    borderRadius: number;
                };
            }[];
        }[];
    } | null;
}

export const useProposalSinister = ({ rows }: ProposalSinisterProps): ProposalSinisterResult => {
    const hasSinister = rows?.sinister?.some((s: any) => !(s.status === "finalized" || s.status === "canceled" || s.status === "credit_recovery"));
    const isSinisterFinalized = rows?.sinister?.every((s: any) => s.status === "finalized");

    if (!hasSinister) {
        return {
            sinisterCard: null
        };
    }

    return {
        sinisterCard: {
            title: "Garantia Sinistrada",
            rows: [
                {
                    fields: [
                        {
                            type: "box" as const,
                            value: isSinisterFinalized
                                ? "Esta garantia teve um sinistro finalizado."
                                : "Esta garantia possui um sinistro em andamento.",
                            colSpan: 12,
                            boxProps: {
                                backgroundColor: "rgba(255, 0, 0, 0.05)",
                                textColor: "error",
                                padding: 2,
                                borderRadius: 1
                            }
                        }
                    ]
                }
            ]
        }
    };
};
