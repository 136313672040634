import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	IconButton,
	Modal,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";
import type React from "react";
import { useEffect, useState } from "react";
import { LoadingComp } from "../../../shared/components/loading/Loading";
import { useToast } from "../../../shared/hooks";
import { ComissionServices } from "../../../shared/services/api/comission/ComissionServices";
import { previewStyle, style } from "./styles";


interface ModalFileProps {
	isOpen: boolean;
	onClose: () => void;
	comissionId: string;
}

interface FileItem {
	name: string;
	type: string;
	date: string;
	size?: number;
	originalDocument: ComissionDocumentType;
}

export type ComissionDocumentType = {
	id: string;
	docType: string;
	mimetype: string;
	filename: string;
	originalFilename: string;
	url: string;
	uploadedDate: string;
	size: number;
};

const supportedFormats = ["png", "jpg", "jpeg", "pdf"];

const ModalFile: React.FC<ModalFileProps> = ({ isOpen, onClose, comissionId }) => {
	const [previewUrl, setPreviewUrl] = useState<string | null>(null);
	const [previewFileType, setPreviewFileType] = useState<string | null>(null);
	const [showPreview, setShowPreview] = useState<boolean>(false);
	const [comissionDocuments, setComissionDocuments] = useState<FileItem[]>([]);
	const [listaAttachment, setListaAttachment] = useState<File[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false); // Novo estado para controlar o loading
	const toastHandler = useToast();

	useEffect(() => {
		if (isOpen) {
			setListaAttachment([]);
			getComissionDocuments();
		}
	}, [isOpen]);

	const getComissionDocuments = async () => {
		setIsLoading(true); // Inicia o loading
		try {
			const documents = await ComissionServices.getComissionDocuments(comissionId);
			const formattedDocuments: FileItem[] = documents.map((doc: ComissionDocumentType) => ({
				name: doc.originalFilename || doc.filename,
				type: doc.mimetype || "Desconhecido",
				date: doc.uploadedDate
					? new Date(doc.uploadedDate).toLocaleString("pt-BR", {
							day: "2-digit",
							month: "2-digit",
							year: "numeric",
							hour: "2-digit",
							minute: "2-digit",
					  })
					: "Data desconhecida",
				size: doc.size,
				originalDocument: doc,
			}));
			setComissionDocuments(formattedDocuments);
		} catch (error) {
			toastHandler.present({
				type: "error",
				messageOrContent: "Erro ao buscar documentos da comissão",
				position: "top-center",
			});
		} finally {
			setIsLoading(false); // Finaliza o loading independentemente do resultado
		}
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			const fileExtension = file.name.split(".").pop()?.toLowerCase();

			if (!fileExtension || !supportedFormats.includes(fileExtension)) {
				toastHandler.present({
					type: "error",
					messageOrContent: "Formato inválido. Permitidos: PNG, JPG, JPEG, PDF.",
					position: "top-center",
				});
				return;
			}

			setListaAttachment((prevList) => [...prevList, file]);
		}
	};

	const handleRemoveAttachment = (index: number) => {
		setListaAttachment((prevList) => prevList.filter((_, i) => i !== index));
	};

	const handleRemoveDocument = async (docId: string) => {
		setIsLoading(true); // Inicia o loading
		try {
			await ComissionServices.removeDocumentByComission(comissionId);
			toastHandler.present({
				type: "success",
				messageOrContent: "Arquivo removido com sucesso!",
				position: "top-center",
			});
			getComissionDocuments();
		} catch (error) {
			toastHandler.present({
				type: "error",
				messageOrContent: "Erro ao remover arquivo.",
				position: "top-center",
			});
		} finally {
			setIsLoading(false); // Finaliza o loading independentemente do resultado
		}
	};

	const handleUploadFiles = async () => {
		setIsLoading(true); // Inicia o loading
		try {
			for (const file of listaAttachment) {
				await ComissionServices.uploadComissionDocument(comissionId, file);
			}
			setListaAttachment([]);
			getComissionDocuments();
			toastHandler.present({
				type: "success",
				messageOrContent: "Arquivos enviados com sucesso!",
				position: "top-center",
			});
		} catch (error) {
			toastHandler.present({
				type: "error",
				messageOrContent: "Erro ao enviar arquivos.",
				position: "top-center",
			});
		} finally {
			setIsLoading(false); // Finaliza o loading independentemente do resultado
		}
	};

	const handlePreviewLocalFile = (file: File) => {
		const fileURL = URL.createObjectURL(file);
		setPreviewUrl(fileURL);
		setPreviewFileType(file.type);
		setShowPreview(true);
	};

	const handleClosePreview = () => {
		setShowPreview(false);
		setPreviewUrl(null);
		setPreviewFileType(null);
	};

	const handleDownload = (file: FileItem) => {
		window.open(file.originalDocument.url, "_blank");
	};

	return (
		<>
			<Modal open={isOpen} onClose={onClose}>
				<Box sx={style}>
					<Typography variant="h6" gutterBottom>
						Arquivos Anexos
					</Typography>

					{/* Área de Upload */}
					<Box
						sx={{
							border: "2px dashed #ccc",
							p: 3,
							borderRadius: 2,
							textAlign: "center",
							cursor: "pointer",
							mb: 3,
						}}
						onClick={() => document.getElementById("fileInput")?.click()}
					>
						<CloudUploadIcon sx={{ fontSize: 48, color: "action.active", mb: 1 }} />
						<Typography> Clique aqui para anexar um arquivo </Typography>
						<Typography variant="body2" color="text.secondary">
							Permitidos: {supportedFormats.join(", ").toUpperCase()}
						</Typography>
						<input
							id="fileInput"
							type="file"
							accept={supportedFormats.map((format) => `.${format}`).join(",")}
							onChange={handleFileChange}
							style={{ display: "none" }}
						/>
					</Box>

					{/* Tabela de arquivos anexados antes do envio */}
					{listaAttachment.length > 0 && (
						<TableContainer component={Paper} sx={{ mb: 3, borderRadius: 2 }}>
							<Table>
								<TableHead>
									<TableRow sx={{ backgroundColor: "#f5f5f5" }}>
										<TableCell>Nome</TableCell>
										<TableCell>Ações</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{listaAttachment.map((file, index) => (
										<TableRow key={index}>
											<TableCell>{file.name}</TableCell>
											<TableCell>
												<IconButton onClick={() => handlePreviewLocalFile(file)}>
													<VisibilityIcon />
												</IconButton>
												<IconButton onClick={() => handleRemoveAttachment(index)}>
													<DeleteIcon color="error" />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}

					{/* Botão Enviar */}
					<Button variant="contained" fullWidth onClick={handleUploadFiles} disabled={listaAttachment.length === 0 || isLoading}>
						Enviar Arquivos
					</Button>

					{/* Tabela de arquivos já anexados */}
					{comissionDocuments.length > 0 && (
						<TableContainer component={Paper} sx={{ mt: 3, borderRadius: 2 }}>
							<Table>
								<TableHead>
									<TableRow sx={{ backgroundColor: "#f5f5f5" }}>
										<TableCell>Nome</TableCell>
										<TableCell>Ações</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{comissionDocuments.map((doc) => (
										<TableRow key={doc.originalDocument.id}>
											<TableCell>{doc.name}</TableCell>
											<TableCell>
												<IconButton onClick={() => handleDownload(doc)}>
													<DownloadIcon />
												</IconButton>
												<IconButton onClick={() => handleRemoveDocument(doc.originalDocument.id)} disabled={isLoading}>
													<DeleteIcon color="error" />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					)}
				</Box>
			</Modal>
			<Dialog
				open={showPreview}
				onClose={handleClosePreview}
				maxWidth="md"
				fullWidth
				PaperProps={{
					sx: {
						...previewStyle,
						width: "90%",
						height: "90%",
						maxWidth: "none",
						m: 0,
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
					},
				}}
			>
				<DialogContent sx={{ p: 0, height: "100%", display: "flex", flexDirection: "column" }}>
					<Box sx={{ display: "flex", justifyContent: "flex-end", p: 1 }}>
						<IconButton onClick={handleClosePreview} size="small">
							<CloseIcon />
						</IconButton>
					</Box>
					<Box sx={{ flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center", overflow: "auto" }}>
						{previewFileType && previewFileType.startsWith("image/") ? (
							<img src={previewUrl || ""} alt="Preview" style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "contain" }} />
						) : previewFileType === "application/pdf" ? (
							<iframe src={previewUrl || ""} width="100%" height="100%" style={{ border: "none" }} />
						) : (
							<Typography>Não é possível visualizar este tipo de arquivo.</Typography>
						)}
					</Box>
				</DialogContent>
			</Dialog>
			<LoadingComp  isopen={isLoading} />
		</>
	);
};

export default ModalFile;
