export const globalBackground = (colorBackGround: string, personalKey?: string): string => {
	if (colorBackGround === "pending") {
		return "#fdf8b1";
	}
	if (colorBackGround === "issued") {
		return "#ddffe9";
	}
	if (colorBackGround === "refused") {
		return "#ffdbdb";
	}
	if (colorBackGround === "canceled") {
		return "#ffdbdb";
	}
	if (colorBackGround === "expired") {
		return "#ffecdb";
	}
	if (colorBackGround === "provisioned") {
		return "#AED6F1";
	}
	if (colorBackGround === "open") {
		return "#EBEDEF";
	}
	if (colorBackGround === "paid") {
		return "#ddffe9";
	}
	if (colorBackGround === "not_busy") {
		return "#b6f5dd";
	}
	if (colorBackGround === "busy") {
		return "#ffdbdb";
	}
	if (colorBackGround === "pre_approved") {
		return "#def4ff";
	}
	if (colorBackGround === "awaiting_signature") {
		return "#766E6C";
	}
	if (colorBackGround === "awaiting_biometria") {
		return "#defaff";
	}
	return colorBackGround;
};

export const globalBackgroundPayment = (colorBackGround: string, personalKey?: string): string => {
	if (colorBackGround === "pending") {
		return "#edf8fe";
	}
	if (colorBackGround === "refused") {
		return "#ffb0b0";
	}
	if (colorBackGround === "canceled") {
		return "#ffecdb";
	}
	if (colorBackGround === "open") {
		return "#EBEDEF";
	}
	if (colorBackGround === "paid") {
		return "#ddffe9";
	}
	// if (colorBackGround === 'overdue') { return '#ffecdb' }
	if (colorBackGround === "overdue") {
		return "#ea080812";
	}

	return colorBackGround;
};

export const globalBackgroundComission = (colorBackGround: string, personalKey?: string): string => {
	if (colorBackGround === "pending") {
		return "#edf8fe";
	}
	if (colorBackGround === "refused") {
		return "#ffb0b0";
	}
	if (colorBackGround === "canceled") {
		return "#ffecdb";
	}
	if (colorBackGround === "open") {
		return "#EBEDEF";
	}
	if (colorBackGround === "paid") {
		return "#ddffe9";
	}
	if (colorBackGround === "overdue") {
		return "#ea080812";
	}

	return colorBackGround;
};

export const globalbackgroundProposal = (colorText: string = ""): string => {
	if (colorText === "active") {
		return "#e9f8f5";
	}
	if (colorText === "suspended") {
		return "#fcedea";
	}
    if (colorText === "renovated") {
		return "#e0faff";
	}
	if (colorText === "expired") {
		return "#fff4e5";
	}
	if (colorText === "canceled") {
		return "#fcedea";
	}
	return colorText;
};


export const globalColorTextProposal = (colorText: string = ""): string => {
	if (colorText === "active") {
		return "#43b897";
	}
	if (colorText === "suspended") {
		return "#e36b5b";
	}
    if (colorText === "expired") {
		return "#ed6c02";
	}	
	if (colorText === "canceled") {
		return "#e36b5b";
	}
    if (colorText === "renovated") {
		return "#0f92d4";
	}
	return colorText;
};

export const globalColorText = (colorText: string = "", isOther: boolean = false, personalKey?: string): string => {
	if (colorText === "pending") {
		return "#1F618D";
	}
	if (colorText === "issued") {
		return "#0f8a0d";
	}
	if (colorText === "refused") {
		return "#e40909";
	}
	if (colorText === "canceled") {
		return "#e40909";
	}
	if (colorText === "expired") {
		return "#e48509 ";
	}
	//if (colorText === 'overdue') { return '#e48509 ' }
	if (colorText === "overdue") {
		return "#e40909 ";
	}
	if (colorText === "busy") {
		return "#7924c7 ";
	}
	if (colorText === "provisioned") {
		return "#1F618D";
	}
	if (colorText === "open") {
		return "#5D6D7E";
	}
	if (colorText === "paid") {
		return "#0f8a0d";
	}
	if (colorText === "not_busy") {
		return "#0f8a0d";
	}
	if (!isOther && colorText === "active") {
		return "#38df61";
	}
	if (!isOther && colorText === "inactive") {
		return "#d3d3d3";
	}
	if (colorText === "blocked") {
		return "#878787";
	}
	if (colorText === "sent") {
		return "#f3f0f0";
	}
	if (colorText === "awaiting_signature") {
		return "#f3f0f0";
	}
	if (colorText === "awaiting_biometria") {
		return "#1F618D";
	}

	if (isOther && colorText === "active") {
		return "#f1f1f1";
	}
	if (isOther && colorText === "inactive") {
		return "#e40909";
	}

	return colorText;
};

export const globalColorTextReports = (colorText: string = ""): string => {
	if (colorText === "in_process") {
		return "#defaff";
	}
	if (colorText === "available") {
		return "#ddffe9";
	}

	if (colorText === "expired") {
		return "#ffdbdb";
	}

	return colorText;
};

export const globalColorTextSetup = (colorText: string = ""): string => {
	if (colorText === "paid") {
		return "#60DF96";
	}
	if (colorText === "pending") {
		return "#44BAF5";
	}

	if (colorText === "not_paid") {
		return "#28A7C6";
	}

	if (colorText === "canceled") {
		return "#E84933";
	}

	if (colorText === "billed") {
		return "#DBCC51";
	}

	return colorText;
};

export function globalColorLine(line: number) {
	if (line % 2 == 0) {
		return "#f3f3f3";
	}
	return "#ffffff";
}

export function colorStatusProposal(status: string) {
	if (status === "expired") {
		return "#f0e555";
	}
	if (status === "active") {
		return "#38df61";
	}
	if (status === "canceled") {
		return "#eb3232";
	}
	if (status === "suspended") {
		return "#eb3232";
	}
	if (status === "renovated") {
		return "#3761eb";
	}
	if (status === "paid") {
		return "#1ec448";
	}
	if (status === "billed") {
		return "#3761eb";
	}

	return "#202020";
}

export const globalColorTytle = "#1c1b1b";
export const globalColorInputBackGround = "#efe1ff";
