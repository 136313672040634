import { format, differenceInDays } from "date-fns";

export const renderRenovationStatus = (row: any) => {
	if (!row || typeof row !== "object") return "";
	const { expiresAt, status, renovateAt } = row;
	if (!expiresAt || typeof expiresAt !== "string" || expiresAt.trim() === "") {
		return "";
	}

	let expiresAtDate: Date;
	try {
		expiresAtDate = new Date(expiresAt.substring(0, 10) + " 03:00:01");
		if (isNaN(expiresAtDate.getTime())) throw new Error();
	} catch {
		return "";
	}

	const currentDate = new Date();
	if (status !== "canceled") {
		if (renovateAt) {
			try {
				const formattedDate = format(new Date(renovateAt), "dd/MM/yyyy");
				return `Renovada em: ${formattedDate}`;
			} catch {
				return "";
			}
		}

		const daysToExpiration = differenceInDays(expiresAtDate, currentDate);
		if (daysToExpiration >= 0 && daysToExpiration <= 60) {
			return daysToExpiration === 0 ? "Atenção: expira Hoje" : `Restam ${daysToExpiration} dia(s)`;
		}
	}
	return "";
};

export const renderCancellationStatus = (row: any) => {
	if (!row || typeof row !== "object") return "";
	const { status, canceledOpen, canceledAt } = row;
	try {
		if (status !== "canceled" && canceledOpen) {
			const canceledOpenDate = new Date(canceledOpen);
			if (!isNaN(canceledOpenDate.getTime())) {
				return ` ${format(canceledOpenDate, "dd/MM/yyyy")}`;
			}
		}

		if (status === "canceled" && canceledAt) {
			const canceledAtDate = new Date(canceledAt.substring(0, 10) + " 03:00:01");
			if (!isNaN(canceledAtDate.getTime())) {
				return format(canceledAtDate, "dd/MM/yyyy");
			}
		}
	} catch {
		return "";
	}

	return "";
};
